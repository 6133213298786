import { Button, Card, Grid, TextField } from '@material-ui/core';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Select from '@material-ui/core/Select';
import { makeStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import React, { useEffect, useRef, useState } from 'react';
import { SketchPicker } from 'react-color';
import { Helmet } from 'react-helmet';
import reactCSS from 'reactcss';
import CustomHeader from '../../components/Reusable/CustomHeader';
import DebouncedTextField from '../../components/Reusable/DebouncedTextField';
import Notification from '../../components/Reusable/Notification';
import {
  EMBED_HOST,
  get,
  post,
  TOUR_HOST,
  useRequest,
} from '../../utils/request';
import { generateRoutes } from '../manage-magnets/ManageMagnet';
import './Embed.module.css';
import ChipInput from 'material-ui-chip-input';
import { supabase } from '../../services/supabase';
import { ToastContainer, toast } from 'react-toastify';
const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

class WidgetColorPicker extends React.Component {
  state = {
    displayColorPicker: false,
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = (color) => {
    this.props.setWidgetColorPrimary(color.hex);
  };

  render() {
    const styles = reactCSS({
      default: {
        color: {
          width: '36px',
          height: '14px',
          borderRadius: '2px',
          background: `${this.props.widgetColorPrimary}`,
        },
        swatch: {
          padding: '5px',
          background: '#fff',
          borderRadius: '1px',
          boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
          display: 'inline-block',
          cursor: 'pointer',
        },
        popover: {
          position: 'absolute',
          zIndex: '2',
        },
        cover: {
          position: 'fixed',
          top: '0px',
          right: '0px',
          bottom: '0px',
          left: '0px',
        },
      },
    });

    return (
      <div>
        <div style={styles.swatch} onClick={this.handleClick}>
          <div style={{ display: 'flex' }}>
            <div style={styles.color} />
            <div style={{ marginTop: '-3px' }}>&nbsp;Primary Color</div>
          </div>
        </div>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <SketchPicker
              color={this.state.color}
              onChange={this.handleChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

// alwaysShow = false, ...config,
const PreviewWidgetEmbed = ({ uuid }) => {
  const [renderDemo, setRenderDemo] = useState(false);
  // useEffect(() => {
  //   if (renderDemo) {
  //     const s = document.createElement('script');
  //     s.setAttribute('src', `${EMBED_HOST}/leasemagnets.js`);
  //     s.setAttribute('async', 'async');
  //     s.setAttribute('defer', 'defer');

  //     s.addEventListener(
  //       'load',
  //       () => window.LeaseMagnets && window.LeaseMagnets(config)
  //     );
  //     s.addEventListener('error', (e) => console.error(e));

  //     document.body.appendChild(s);

  //     return () => {
  //       window.destroyLeaseMagnets && window.destroyLeaseMagnets();
  //       document.body.removeChild(s); // Clean up
  //     };
  //   }
  // }, [renderDemo, config]);

  // if (alwaysShow) {
  //   // Don't show the "Show Preview" button, the preview will be opened via window.toggleLeaseMagnetsOpen
  //   return null;
  // }
  return (
    <div className="EmbedSavePreview">
      <br />
      <Button
        color="inherit"
        variant="contained"
        // onClick={() => setRenderDemo((render) => !render)}
        onClick={() => window.open(`${EMBED_HOST}?uuid=${uuid}`, '_blank')}
      >
        {renderDemo ? 'Clear Preview' : 'Show Preview'}
      </Button>
    </div>
  );
};

const EMBED = Object.freeze({
  Widget: 'Widget',
  Inline: 'Inline',
  Popup: 'Popup',
  VideoLibrary: 'Video Library',
  Button: 'Button',
});

export default function Embed({ name, url, community_id, title }) {
  const [embedType, setEmbedType] = useState(EMBED.Widget);
  const [magnetObj, setMagnetObj] = useState('');
  const [magnetUuid, setMagnetUuid] = useState('');
  const [magnetAlias, setMagnetAlias] = useState('');
  const [routeOpts, setRouteOpts] = useState([]);

  const [loading, error, data, makeRequest] = useRequest({ loading: true });

  const [widgetLayout, setWidgetLayout] = useState('left');
  const toggleChecked = () =>
    setWidgetLayout(widgetLayout === 'left' ? 'right' : 'left');
  const [ctaText, setCtaText] = useState('Take a virtual tour');

  const [layoutPreview, setLayoutPreview] = useState('square');
  const [widgetColorPrimary, setWidgetColorPrimary] = useState('#22194D');

  const EmbedCodeRef = useRef();
  const LinkUrlRef = useRef();

  // Inline Iframe Felid
  const [inlineIframeField, setInlineIframeField] = useState({
    inlineCta: 'hey',
    roundedCorners: '20',
    scale: '',
    inlineIframeCopied: false,
    inlineSrcUrlCopied: false,
  });
  const inlineIframeRef = useRef();
  const inlineSrcUrlRef = useRef();

  // Pop-up Iframe Field
  const [popUpIframeField, setPopUpIframeField] = useState({
    selectedButton: 'video-button',
    selectedLayout: 'left',
    layouts: [
      { label: 'left' },
      { label: 'center' },
      { label: 'center-bottom' },
      { label: 'right' },
    ],
    selectedTextButton: 'How can I setup account',
    textButtons: [
      { label: 'How can I setup account' },
      { label: 'How to add dns' },
      { label: 'How to add dns' },
      { label: 'Learn how to setup your business account' },
    ],
    backgroundOpacity: 0.7,
    desktopWidth: 70,
    selectedWidgetType: 'circle',
    widgetTypes: [
      { label: 'circle' },
      { label: 'circle-w-touch' },
      { label: 'vertical' },
      { label: 'vertical-w-touch' },
    ],
  });

  function copy(e, ref) {
    ref.current.select();
    document.execCommand('copy');
    e.target.focus();
    onCopyText();
  }

  const onCopyText = () => {
    setTimeout(() => {
      setInlineIframeField({
        ...inlineIframeField,
        inlineIframeCopied: false,
        inlineSrcUrlCopied: false,
      });
    }, 1000);
  };
  const [chatbot, setchatbot] = useState([
    {
      type: 'Basic',
      text: 'Hi Welcome to the Edge on Blank! How can I help you?',
      buttons: [{ label: '', route: '' }],
    },
  ]);
  const [defaultEmbedFields, setDefaultEmbedFields] = useState({
    hiddenUrls: [],
  });
  const [hardcodeEmbedFields, setHardcodeEmbedFields] = useState({});
  const [defaultEmebedLoaded, setdefaultEmebedLoaded] = useState(false);
  console.log('defaultEmbedFields', defaultEmbedFields);
  const getDefaultEmbedFields = async (magnet_Uuid) => {
    get(
      `/magnets/template/updateMagnet/dynamic/defaultEmbed?magnet_uuid=${magnet_Uuid}`,
      {
        auth: false,
        //, host: "http://localhost:8080"
      }
    )
      .then((response) => {
        // Ensure service worker exists, and that we really are getting a JS file.
        console.log('theresposne', response);
        setDefaultEmbedFields({
          ...defaultEmbedFields,
          ...response?.default_config,
        });
        if (response?.default_config) {
          if (response?.default_config?.chatbot) {
            setchatbot(response?.default_config?.chatbot);
          }
          if (response.default_config.startOpen == '') {
            response.default_config.startOpen = false;
          }

          setScreenChoice(response.default_config.screenChoice);
          setVideoStartChoice(response.default_config.screenChoice ? response.default_config.screenChoice?.split("|")[1] : '');
          setRouteStartChoice(response.default_config.startScreen || '');
          
        }
        setdefaultEmebedLoaded(true);
      })
      .catch(() => {
        console.log(
          'No internet connection found. App is running in offline mode.'
        );
      });
  };
  const getMagnet = async () => {
    console.log('comunity id', community_id);
    const res = await makeRequest(
      `/magnets?queryByCommunity=${community_id}`,
      'GET',
      null,
      { auth: false }
    );
    console.log('result', res.magnets);

    setMagnetObj(res?.magnets[0]?.magnet_details?.template);
    setMagnetUuid(res?.magnets[0]?.uuid);
    setMagnetAlias(res?.magnets[0]?.alias);
    getDefaultEmbedFields(res?.magnets[0]?.uuid);
    console.log(magnetUuid);
    console.log(magnetObj);
  };

  //Menu drop down select
  const [screenChoice, setScreenChoice] = useState('');
  const [videoStartChoice, setVideoStartChoice] = useState('');
  const [routeStartChoice, setRouteStartChoice] = useState('');

  const handleChangeMenu = (event,videourl) => {
    console.log("event.target.value",event.target.value);
    let screen = routeOpts?.find((item) => item?.route == event.target.value)
    setScreenChoice(`${event.target.value} | ${screen.video}`);
    console.log("screen",screen)
    setVideoStartChoice(screen.video);
    setRouteStartChoice(event.target.value);
    setDefaultEmbedFields({
      ...defaultEmbedFields,
      startScreen: event.target.value
    });
  };

  const handleLayout = (event) => {
    setPopUpIframeField({
      ...popUpIframeField,
      selectedLayout: event.target.value,
    });
  };

  const handleWidgetType = (event) => {
    setPopUpIframeField({
      ...popUpIframeField,
      selectedWidgetType: event.target.value,
    });
  };
  const classes = useStyles();

  const handleTextButton = (event) => {
    setPopUpIframeField({
      ...popUpIframeField,
      selectedTextButton: event.target.value,
    });
  };

  useEffect(() => {
    getMagnet();
  }, []);

  useEffect(() => {
    if (magnetObj) {
      const tempRoutes = generateRoutes(magnetObj);
      setRouteOpts(tempRoutes);
      // setScreenChoice(
      //   tempRoutes.length > 0
      //     ? tempRoutes[0].route + '|' + tempRoutes[0].video
      //     : ''
      // );
      // setVideoStartChoice(tempRoutes.length > 0 ? tempRoutes[0].video : '');
      // setRouteStartChoice(tempRoutes.length > 0 ? tempRoutes[0].route : '');
    }
  }, [magnetObj]);



  // REMOVE SHADOW DOM https://stackoverflow.com/questions/20090059/how-to-remove-a-shadow-root-from-an-html-element-adorned-with-a-shadow-dom-from

  const inlineIframeUrl = `${EMBED_HOST}?uuid=${magnetUuid}&inline=true&screen=${routeStartChoice}&inlineCTA=${encodeURI(
    inlineIframeField.inlineCta
  )}`;

  // --------------------------------------------
  const [NotificationBar, setNotification] = Notification();

  const defaultEmbed = {
    videoObjectfitOptions: [
      { label: 'contain' },
      { label: 'cover' },
      { label: 'fill' },
      { label: 'scale-down' },
      { label: 'none' },
    ],
    captureLeadAfter : 0,
    yPosition : '0',
    xPosition : '0',
    buttonLabel: 'Take a virtual tour & $250 off',
    showButton: false,
    showButtonOptions: [{ label: true }, { label: false }],
    autoFullScreenDisabled : [{ label: true }, { label: false }],
    showCloseButtonOptions: [{ label: true }, { label: false }],
    startOpen: false,
    startOpenOptions: [{ label: true }, { label: false }],
    hideCaptionButtonOptions : [{ label: true }, { label: false }],
    isTwoColumnOptions : [{ label: true }, { label: false }],
    showChatbotButtonOptions : [{ label: true }, { label: false }],
    authCodeLeadForm: [{ label: true }, { label: false }],
    primaryColor: '#872322',
    closeButtonColor: '#a1a1a1',
    selectedLayout: 'left',
    layouts: [
      { label: 'left' },
      { label: 'center' },
      { label: 'center-bottom' },
      { label: 'right' },
    ],
    selectedTextButton: 'How can I setup account',
    backgroundOpacity: 70,
    desktopWidth: 70,
    selectedWidgetType: 'circle',
    widgetTypes: [
      { label: 'circle' },
      { label: 'circle-w-touch' },
      { label: 'vertical' },
      { label: 'vertical-w-touch' },
    ],
  };
  const setTheDefaultEmbedFields = (default_config) => {
    console.log('_default_config', default_config);

    post(
      '/magnets/template/updateMagnet/dynamic/defaultEmbedupdated',
      {
        magnet_uuid: magnetUuid,
        default_config: {
          ...defaultEmbedFields,
          ...default_config,
          startOpen: default_config?.startOpen || false,
        },
      },
      {
        auth: false,
        //, host: "http://localhost:8080"
      }
    )
      .then((response) => {
        // Ensure service worker exists, and that we really are getting a JS file.
        if (response.default_config) {
          setDefaultEmbedFields({
            ...defaultEmbedFields,
            ...response.default_config,
          });
        }
        setNotification(`Settings updated`, 'success');
      })
      .catch(() => {
        console.log(
          'No internet connection found. App is running in offline mode.'
        );
      });
  };
  const [magSettings, setMagSettings] = useState({
    location: '',
    features: [],
    communityName: '',
    analyticsApiKey: '',
    website: '',
    phone: '',
    email: '',
    customBranding: {
      img: '',
      url: '',
    },
    backgroundImg: '',
    agents: [], // each agent object in array contains name, email, image
    preferredSettings: '',
    specialOffer: '',
  });

  function updateSettings(data) {
    let _defaultEmbed = {
      showButton: defaultEmbedFields.showButton,
      startOpen: defaultEmbedFields.startOpen,
      layout: defaultEmbedFields.layout,
      primaryColor: defaultEmbedFields.primaryColor,
      startScreen: defaultEmbedFields.startScreen,
      screenChoice: screenChoice,
      buttonLabel: defaultEmbedFields.buttonLabel,
      backgroundOpacity: defaultEmbedFields.backgroundOpacity,
      desktopWidth: defaultEmbedFields.desktopWidth,
      widgetType: defaultEmbedFields.widgetType,
      chatbot: defaultEmbedFields.chatbot,
      showCloseButton: defaultEmbedFields.showCloseButton,
      hideCaptionButton : defaultEmbedFields.hideCaptionButton,
      isTwoColumn : defaultEmbedFields.isTwoColumn,
      closeButtonColor: defaultEmbedFields.closeButtonColor,
      showChatbotButton: defaultEmbedFields.showChatbotButton,
      uuid: magnetUuid,
      hiddenUrls: defaultEmbedFields?.hiddenUrls,
      blockEvents: 'keydown',
      ...data
    };
    console.log('defaultembed', _defaultEmbed);


    // Update Default Embed Setting
    setTheDefaultEmbedFields(_defaultEmbed, chatbot);

    // update Dynamic Variables

    // Update Default Embed Setting
    // post(`/updateMagnet/dynamic/defaultEmbedupdated?magnetUuid=${magnetUuid}`, {
    //   magnet_uuid: magnetUuid,
    //   magnetSettings: magSettings,
    //   start_route: startRoute,
    //   gaPropertyId: gaPropertyId,
    //   fbPixelId: fbPixelId,
    //   magnet_type: magnetType,
    // })
    //   .then((data) => {
    //     console.log('successfully update default embed form');
    //   })
    //   .catch((error) => {
    //     console.log('Error:', error.message);
    //   });

    // post('/magnets/template/settings', {
    //   magnet_uuid: magnetUuid,
    // }).then((data) => {
    //   setNotification(`Settings updated`, 'success');
    //   //window?.location?.reload();
    // });

    // console.log('just posted the magnet', {
    //   magnet_uuid: magnetUuid,
    //   template: {
    //     ...magnetTemplate,
    //     magnetSettings: magSettings,
    //     start_route: startRoute,
    //     gaPropertyId: gaPropertyId,
    //     fbPixelId: fbPixelId,
    //   },
    //   magnet_type: magnetType,
    // });
  }

  const [activeTab, setActiveTab] = useState(0);

  const checkshow = (cond) => {
    const asArray = Object.entries(defaultEmbedFields);

    const filtered = asArray.filter(([key]) => key !== 'chatbot');

    const justStrings = Object.fromEntries(filtered);

    if (cond) {
      setDefaultEmbedFields(justStrings);
    } else {
      setDefaultEmbedFields({ ...defaultEmbedFields, chatbot });
    }
  };
  const clearNow = () => {
    setDefaultEmbedFields({ uuid: magnetUuid });
  };
  const handleAddChip = (val) => {
    console.log('handleAddChip', val);
    setDefaultEmbedFields({
      ...defaultEmbedFields,
      hiddenUrls: [...defaultEmbedFields.hiddenUrls, val],
    });
  };

  const handleDeleteChip = (chip, selectedIndex) => {
    let hiddenUrls = defaultEmbedFields?.hiddenUrls;
    hiddenUrls.splice(selectedIndex, 1);
    setDefaultEmbedFields({
      ...defaultEmbedFields,
      hiddenUrls,
    });
  };

  const fileInputRef = useRef(null);
  const [imgloading,setimgloading] = useState(false)

  const handleUploadClick = () => {
      fileInputRef.current.click();
  };

  const handleFileChange = async (event) => {
    const selectedFile = event.target.files[0];
    if(!selectedFile) return
    setimgloading(true)
    const uuid = crypto.randomUUID();
    const { data, error } = await supabase.storage
      .from('PropertyPhotos')
      .upload(`${community_id}/${uuid}`, selectedFile, {
        // TODO: for all photos?
        cacheControl: '3600',
        upsert: false,
      });
    console.log("handleFileChange_data",data)
    if (error) {
      console.error('Error in uploading photo to supabase:', error);
      toast.error('Error in uploading photo to supabase!');
    }
    const link = supabase.storage
      .from('PropertyPhotos')
      .getPublicUrl(`${community_id}/${uuid}`).data.publicUrl;
    console.log("handleFileChange_link",link)
    updateSettings({previewBackgroundImageUrl : link})
    setDefaultEmbedFields({...defaultEmbedFields,previewBackgroundImageUrl : link})
    window.open(`${EMBED_HOST}?uuid=${magnetUuid}&previewBackgroundImageUrl=${link}`, '_blank')
    setimgloading(false)
    // Handle the selected file here
  };

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {/* <CustomHeader
        url={url}
        name={name}
        community_id={community_id}
        title="Embed"
      /> */}

      <div className="embed-tab-page">
        <div className="mb-4">
          <h1 className=" text-3xl font-semibold text-center">
            {' '}
            Embed Options
          </h1>
          <h4 className=" text-lg  text-center"> Direct link to your Tour</h4>
        </div>
        <div className=" flex items-center justify-center  w-full">
          <TextField
            inputRef={LinkUrlRef}
            variant="outlined"
            value={`${EMBED_HOST}?uuid=${magnetAlias ? `@${magnetAlias}` : magnetUuid}`}
            style={{ width: '80%' }}
            multiline={true}
          />
        </div>
        <div className=" flex items-center justify-center gap-3 w-full mt-4">
          <Button
            variant="contained"
            color="primary"
            onClick={(e) => copy(e, LinkUrlRef)}
          >
            Copy
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              window.open(`${EMBED_HOST}?uuid=${magnetAlias ? `@${magnetAlias}` : magnetUuid}`, '_blank') 
             }
            }
          >
            Preview
          </Button>
          <input
                type="file"
                accept="image/*"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                ref={fileInputRef}
            />
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              console.log("defaultEmbedFields?.previewBackgroundImageUrl",defaultEmbedFields?.previewBackgroundImageUrl)
                if(!defaultEmbedFields?.previewBackgroundImageUrl) {
                  toast.error('No Background image found!');
                  handleUploadClick()
                } else {
                  window.open(`${EMBED_HOST}?uuid=${magnetUuid}&previewBackgroundImageUrl=${defaultEmbedFields?.previewBackgroundImageUrl}`, '_blank')
                }
              } 
            }
          >
            {imgloading ? 'Please Wait...' : 'Preview with Background'}
          </Button>
          <form>   
            <label for="search" className="mb-2 text-sm font-medium text-gray-900 sr-only dark:text-white">Search</label>
            <div className="relative">
                <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none mr-2">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" className="lucide lucide-at-sign"><circle cx="12" cy="12" r="4"/><path d="M16 8v5a3 3 0 0 0 6 0v-1a10 10 0 1 0-4 8"/></svg>
                </div>
                <input 
                 value={magnetAlias}
                 onChange={(e)=> {
                    setMagnetAlias(e.target.value)
                 }}
                 type="search" id="search" className="ml-1 block w-full px-4 py-2 ps-10 text-sm text-gray-900 border border-gray-300 rounded-lg bg-gray-50 focus:ring-blue-500 focus:border-blue-500 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" placeholder="Short URL" required />
            </div>
        </form>
        <Button
            variant="contained"
            color="primary"
            onClick={() => {
                supabase.from('Magnet').update({ alias: magnetAlias }).eq('uuid', magnetUuid).then(({ data, error }) => {
                    if (error) {
                      console.error('Error in updating your Tour alias:', error);
                      toast.error('Error in updating Tour alias!');
                    }

                    toast.success('Alias updated successfully! TYG!');
                  });
                supabase.from('Community').update({ alias: magnetAlias }).eq('id', community_id).then(({ data, error }) => {
                    if (error) {
                      console.error('Error in updating your Tour alias:', error);
                      toast.error('Error in updating Community Tour alias!');
                    }

                    toast.success('Community Alias updated successfully! TYG!');
                  });
              }
            }
          >
           Save URL
          </Button>
        </div>
        <div className="embed-steps flex aic justify-center flex-wrap gap-5">
          <button
            className={`cleanbtn step-block anim ${
              embedType === EMBED.Widget && 'on'
            }`}
            onClick={() => setEmbedType(EMBED.Widget)}
          >
            <div className="meta flex flex-col aic">
              <img
                className="icon"
                src={require('../../assets/embed/widget.svg').default}
              />
              <div className="lbl font s16 b6 c000">Widget</div>
              <div className="txt font s12 c777">
                Smart window to access the tour
              </div>
            </div>
          </button>

          <button
            className={`cleanbtn step-block anim ${
              embedType === EMBED.Inline && 'on'
            }`}
            onClick={() => setEmbedType(EMBED.Inline)}
          >
            <div className="meta flex flex-col aic">
              <img
                className="icon"
                src={require('../../assets/embed/inline.svg').default}
              />
              <div className="lbl font s16 b6 c000">Inline</div>
              <div className="txt font s12 c777">
                Full size tour that embed in your web
              </div>
            </div>
          </button>

          <button
            className={`cleanbtn step-block anim ${
              embedType === EMBED.VideoLibrary && 'on'
            }`}
            onClick={() => setEmbedType(EMBED.VideoLibrary)}
          >
            <div className="meta flex flex-col aic">
              <img
                className="icon"
                src={require('../../assets/embed/tour-library.svg').default}
              />
              <div className="lbl font s16 b6 c000">Tour Library</div>
              <div className="txt font s12 c777">
                Customize how you’d like to share videos
              </div>
            </div>
          </button>

          <button
            className={`cleanbtn step-block anim ${
              embedType === EMBED.Button && 'on'
            }`}
            onClick={() => setEmbedType(EMBED.Button)}
          >
            <div className="meta flex flex-col aic">
              <img
                className="icon"
                src={require('../../assets/embed/pop-up.svg').default}
              />
              <div className="lbl font s16 b6 c000">Pop-up</div>
              <div className="txt font s12 c777">
                Integrate the tour with the visual image
              </div>
            </div>
          </button>
        </div>
        {embedType === EMBED.Widget && (
          <>
            <h3 className="w-full text-2xl pb-4 font-semibold text-center">
              {' '}
              Embed your Tour.Video Widget
            </h3>
            <Card>
              <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-6 pt-4 items-center">
                <div>
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                    }}
                  >
                    {/* <div
                      style={{
                        position: 'relative',
                        height: 120,
                        width: 120,
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        border: `8px solid ${widgetColorPrimary}`,
                        borderRadius: 100,
                      }}
                    >
                      <video
                        src={videoStartChoice}
                        muted
                        autoPlay
                        playsInline
                        loop
                        style={{
                          height: '100%',
                          width: '100%',
                          borderRadius: 100,
                          overflow: 'hidden',
                          objectFit: 'cover',
                        }}
                      />
                      <span
                        style={{
                          position: 'absolute',
                          textAlign: 'center',
                          color: 'white',
                          fontWeight: 'bold',
                          fontSize: '16px',
                          fontFamily: 'inherit',
                          textShadow: '0px 0px 4px black',
                        }}
                      >
                        {ctaText}
                      </span>
                    </div> */}

                    <iframe
                      src={
                        'https://embed.tour.video/?' +
                        new URLSearchParams({
                          ...defaultEmbedFields,
                          uuid: magnetUuid,
                        }).toString()
                      }
                      width="100%"
                      height="500px"
                      style={{
                        border: ' 1px solid grey',
                        borderRadius: '10px',
                      }}
                    />
                  </div>
                </div>
                <div>
                  <div className=" w-full py-2">
                    <Accordion defaultExpanded>
                      <AccordionSummary
                        className=" border-b w-full"
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography className={classes.heading}>
                          Dynamic Default Embed Settings
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails className=" w-full border-t flex flex-col ">
                        <div className="flex items-center gap-3 py-3">
                          <h6
                            onClick={() => setActiveTab(0)}
                            className={
                              activeTab === 0
                                ? ' font-medium text-pr cursor-pointer text-sm border-b-2 border-pr'
                                : ' font-medium text-gray-500 cursor-pointer text-sm border-b-2 border-white'
                            }
                          >
                            Embed with Dynamic Defaults
                          </h6>
                          <h6
                            onClick={() => {
                              checkshow(true);
                              setActiveTab(1);
                            }}
                            className={
                              activeTab === 1
                                ? ' font-medium text-pr cursor-pointer text-sm border-b-2 border-pr'
                                : ' font-medium text-gray-500 cursor-pointer text-sm border-b-2 border-white'
                            }
                          >
                            Embed with Hardcode Details
                          </h6>
                        </div>
                        {activeTab === 0 && defaultEmebedLoaded && (
                          <div className="default-emded-setting  w-full">
                            <div className="wrap w-full">
                              <div className="form grid grid-cols-1 lg:grid-cols-3 gap-4">
                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                    Button Label
                                  </div>
                                  <div className="itm flex aic">
                                    <input
                                      className="cleanbtn iput font s15 b4 c000"
                                      value={defaultEmbedFields.buttonLabel}
                                      placeholder={defaultEmbed.buttonLabel}
                                      onChange={(e) => {
                                        setDefaultEmbedFields({
                                          ...defaultEmbedFields,
                                          buttonLabel: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                    Primary Color
                                  </div>
                                  <div className="itm flex aic">
                                    <input
                                      className="cleanbtn iput font s15 b4 c000"
                                      value={defaultEmbedFields.primaryColor}
                                      placeholder={defaultEmbed.primaryColor}
                                      onChange={(e) => {
                                        setDefaultEmbedFields({
                                          ...defaultEmbedFields,
                                          primaryColor: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                    Show Button
                                  </div>
                                  <Select
                                    labelId="select screen"
                                    id="select screen"
                                    value={defaultEmbedFields.showButton}
                                    onChange={(event) => {
                                      setDefaultEmbedFields({
                                        ...defaultEmbedFields,
                                        showButton: event.target.value,
                                      });
                                    }}
                                    disableUnderline
                                    style={{
                                      border: '1px solid #ddd',
                                      padding: '8px 15px 5px 15px',
                                      borderRadius: '4px',
                                    }}
                                  >
                                    {defaultEmbed.showButtonOptions.map(
                                      (item, index) => (
                                        <MenuItem
                                          key={index}
                                          value={item.label}
                                          style={{ backgroundColor: '#fff' }}
                                        >
                                          {item.label.toString()}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </div>
                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                    Start Open
                                  </div>
                                  <Select
                                    labelId="select screen"
                                    id="select screen"
                                    value={defaultEmbedFields?.startOpen}
                                    defaultValue={false}
                                    onChange={(event) => {
                                      setDefaultEmbedFields({
                                        ...defaultEmbedFields,
                                        startOpen: event.target.value,
                                      });
                                    }}
                                    disableUnderline
                                    style={{
                                      border: '1px solid #ddd',
                                      padding: '8px 15px 5px 15px',
                                      borderRadius: '4px',
                                    }}
                                  >
                                    {defaultEmbed.startOpenOptions.map(
                                      (item, index) => (
                                        <MenuItem
                                          key={index}
                                          value={item.label}
                                          style={{ backgroundColor: '#fff' }}
                                        >
                                          {item.label.toString()}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </div>
                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                    Layout
                                  </div>
                                  <Select
                                    labelId="select screen"
                                    id="select screen"
                                    value={defaultEmbedFields.layout}
                                    onChange={(event) => {
                                      setDefaultEmbedFields({
                                        ...defaultEmbedFields,
                                        layout: event.target.value,
                                      });
                                    }}
                                    disableUnderline
                                    style={{
                                      border: '1px solid #ddd',
                                      padding: '8px 15px 5px 15px',
                                      borderRadius: '4px',
                                    }}
                                  >
                                    {defaultEmbed.layouts.map((item, index) => (
                                      <MenuItem
                                        key={index}
                                        value={item.label}
                                        style={{ backgroundColor: '#fff' }}
                                      >
                                        {item.label}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </div>
                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                    Start Screen
                                  </div>
                                  <Select
                                    labelId="select screen"
                                    id="select screen"
                                    value={routeStartChoice} // If at least one routeOpts exist, choose the first, else return empty
                                    onChange={handleChangeMenu}
                                    disableUnderline
                                    style={{
                                      border: '1px solid #ddd',
                                      padding: '8px 15px 5px 15px',
                                      borderRadius: '4px',
                                    }}
                                  >
                                    {routeOpts.map(({ id, route, video }) => (
                                      <MenuItem
                                        key={id + route + video}
                                        value={route}
                                        style={{ backgroundColor: '#fff' }}
                                      >
                                        {id}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </div>
                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                    Background Opacity
                                  </div>
                                  <div className="itm flex aic">
                                    <input
                                      className="cleanbtn iput font s15 b4 c000"
                                      value={
                                        defaultEmbedFields.backgroundOpacity
                                      }
                                      placeholder={
                                        defaultEmbed.backgroundOpacity
                                      }
                                      onChange={(e) => {
                                        setDefaultEmbedFields({
                                          ...defaultEmbedFields,
                                          backgroundOpacity: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="feild flex flex-col">
                                    <div className="lbl font s14 b4 c000">
                                      Desktop Height
                                    </div>
                                    <div className="itm flex aic">
                                      <input
                                        className="cleanbtn iput font s15 b4 c000"
                                        value={defaultEmbedFields.desktopHeight}
                                        placeholder={defaultEmbed.desktopHeight}
                                        onChange={(e) => {
                                          setDefaultEmbedFields({
                                            ...defaultEmbedFields,
                                            desktopHeight: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                    Desktop Width
                                  </div>
                                  <div className="itm flex aic">
                                    <input
                                      className="cleanbtn iput font s15 b4 c000"
                                      value={defaultEmbedFields.desktopWidth}
                                      placeholder={defaultEmbed.desktopWidth}
                                      onChange={(e) => {
                                        setDefaultEmbedFields({
                                          ...defaultEmbedFields,
                                          desktopWidth: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                    Widget Type
                                  </div>
                                  <Select
                                    labelId="select screen"
                                    id="select screen"
                                    value={defaultEmbedFields.widgetType}
                                    onChange={(event) => {
                                      setDefaultEmbedFields({
                                        ...defaultEmbedFields,
                                        widgetType: event.target.value,
                                      });
                                    }}
                                    disableUnderline
                                    style={{
                                      border: '1px solid #ddd',
                                      padding: '8px 15px 5px 15px',
                                      borderRadius: '4px',
                                    }}
                                  >
                                    {defaultEmbed.widgetTypes.map(
                                      (item, index) => (
                                        <MenuItem
                                          key={index}
                                          value={item.label}
                                          style={{ backgroundColor: '#fff' }}
                                        >
                                          {item.label}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </div>
                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                    Show Close Button
                                  </div>
                                  <Select
                                    labelId="select screen"
                                    id="select screen"
                                    value={
                                      defaultEmbedFields?.showCloseButton ||
                                      false
                                    }
                                    //defaultValue={false}
                                    onChange={(event) => {
                                      setDefaultEmbedFields({
                                        ...defaultEmbedFields,
                                        showCloseButton: event.target.value,
                                      });
                                    }}
                                    disableUnderline
                                    style={{
                                      border: '1px solid #ddd',
                                      padding: '8px 15px 5px 15px',
                                      borderRadius: '4px',
                                    }}
                                  >
                                    {defaultEmbed.showCloseButtonOptions.map(
                                      (item, index) => (
                                        <MenuItem
                                          key={index}
                                          value={item.label}
                                          style={{ backgroundColor: '#fff' }}
                                        >
                                          {item.label.toString()}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </div>
                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                    Close Button Color
                                  </div>
                                  <div className="itm flex aic">
                                    <input
                                      className="cleanbtn iput font s15 b4 c000"
                                      value={
                                        defaultEmbedFields.closeButtonColor
                                      }
                                      //placeholder={defaultEmbed.closeButtonColor}
                                      onChange={(e) => {
                                        setDefaultEmbedFields({
                                          ...defaultEmbedFields,
                                          closeButtonColor: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>

                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                    Hidden urls
                                  </div>
                                  <ChipInput
                                    variant="outlined"
                                    placeholder="Hidden Urls"
                                    //className="my-0"
                                    fullWidth
                                    blurBehavior="add"
                                    value={defaultEmbedFields?.hiddenUrls?.map(
                                      (item) => item
                                    )}
                                    onAdd={(chip) => {
                                      handleAddChip(chip);
                                    }}
                                    onDelete={(chip, index) =>
                                      handleDeleteChip(chip, index)
                                    }
                                  />
                                </div>

                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                    Video *Object-fit
                                  </div>
                                  <Select
                                    labelId="select video option"
                                    id="select video option"
                                    value={
                                      defaultEmbedFields?.videoObjectfit
                                        ? defaultEmbedFields?.videoObjectfit
                                        : 'cover'
                                    }
                                    defaultValue={false}
                                    onChange={(event) => {
                                      setDefaultEmbedFields({
                                        ...defaultEmbedFields,
                                        videoObjectfit: event.target.value,
                                      });
                                    }}
                                    disableUnderline
                                    style={{
                                      border: '1px solid #ddd',
                                      padding: '8px 15px 5px 15px',
                                      borderRadius: '4px',
                                    }}
                                  >
                                    {defaultEmbed.videoObjectfitOptions.map(
                                      (item, index) => (
                                        <MenuItem
                                          key={index}
                                          value={item.label}
                                          style={{ backgroundColor: '#fff' }}
                                        >
                                          {item.label.toString()}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </div>
                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                    Enable Two factor Auth
                                  </div>
                                  <Select
                                    labelId="select screen"
                                    id="select screen"
                                    value={defaultEmbedFields?.authCodeLeadForm}
                                    //defaultValue={false}
                                    onChange={(event) => {
                                      setDefaultEmbedFields({
                                        ...defaultEmbedFields,
                                        authCodeLeadForm: event.target.value,
                                      });
                                    }}
                                    disableUnderline
                                    style={{
                                      border: '1px solid #ddd',
                                      padding: '8px 15px 5px 15px',
                                      borderRadius: '4px',
                                    }}
                                  >
                                    {defaultEmbed.authCodeLeadForm.map(
                                      (item, index) => (
                                        <MenuItem
                                          key={index}
                                          value={item.label}
                                          style={{ backgroundColor: '#fff' }}
                                        >
                                          {item.label.toString()}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </div>
                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                    Set *captureLeadAfter (in seconds)
                                  </div>
                                  <div className="itm flex aic">
                                  <input
                                      className="cleanbtn iput font s15 b4 c000"
                                      value={defaultEmbedFields.captureLeadAfter}
                                      placeholder={defaultEmbed.captureLeadAfter}
                                      onChange={(e) => {
                                        setDefaultEmbedFields({
                                          ...defaultEmbedFields,
                                          captureLeadAfter: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                    autoFullscreenDisabled
                                  </div>
                                  <Select
                                    labelId="select screen"
                                    id="select screen"
                                    value={defaultEmbedFields?.autoFullScreenDisabled}
                                    //defaultValue={false}
                                    onChange={(event) => {
                                      setDefaultEmbedFields({
                                        ...defaultEmbedFields,
                                        autoFullScreenDisabled: event.target.value,
                                      });
                                    }}
                                    disableUnderline
                                    style={{
                                      border: '1px solid #ddd',
                                      padding: '8px 15px 5px 15px',
                                      borderRadius: '4px',
                                    }}
                                  >
                                    {defaultEmbed.autoFullScreenDisabled?.map(
                                      (item, index) => (
                                        <MenuItem
                                          key={index}
                                          value={item.label}
                                          style={{ backgroundColor: '#fff' }}
                                        >
                                          {item.label.toString()}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </div>
                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                    y Position *Widget Button
                                  </div>
                                  <div className="itm flex aic">
                                    <input
                                      className="cleanbtn iput font s15 b4 c000"
                                      value={defaultEmbedFields.yPosition}
                                      placeholder={defaultEmbed.yPosition}
                                      onChange={(e) => {
                                        setDefaultEmbedFields({
                                          ...defaultEmbedFields,
                                          yPosition: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                    x Position *Widget Button
                                  </div>
                                  <div className="itm flex aic">
                                    <input
                                      className="cleanbtn iput font s15 b4 c000"
                                      value={defaultEmbedFields.xPosition}
                                      placeholder={defaultEmbed.xPosition}
                                      onChange={(e) => {
                                        setDefaultEmbedFields({
                                          ...defaultEmbedFields,
                                          xPosition: e.target.value,
                                        });
                                      }}
                                    />
                                  </div>
                                </div>
                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                    Hide Caption Button
                                  </div>
                                  <Select
                                    labelId="select screen"
                                    id="select screen"
                                    value={
                                      defaultEmbedFields?.hideCaptionButton ||
                                      false
                                    }
                                    //defaultValue={false}
                                    onChange={(event) => {
                                      setDefaultEmbedFields({
                                        ...defaultEmbedFields,
                                        hideCaptionButton: event.target.value,
                                      });
                                    }}
                                    disableUnderline
                                    style={{
                                      border: '1px solid #ddd',
                                      padding: '8px 15px 5px 15px',
                                      borderRadius: '4px',
                                    }}
                                  >
                                    {defaultEmbed.hideCaptionButtonOptions.map(
                                      (item, index) => (
                                        <MenuItem
                                          key={index}
                                          value={item.label}
                                          style={{ backgroundColor: '#fff' }}
                                        >
                                          {item.label.toString()}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </div>
                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                    isTwoColumn
                                  </div>
                                  <Select
                                    labelId="select screen"
                                    id="select screen"
                                    value={
                                      defaultEmbedFields?.isTwoColumn ||
                                      false
                                    }
                                    //defaultValue={false}
                                    onChange={(event) => {
                                      setDefaultEmbedFields({
                                        ...defaultEmbedFields,
                                        isTwoColumn: event.target.value,
                                      });
                                    }}
                                    disableUnderline
                                    style={{
                                      border: '1px solid #ddd',
                                      padding: '8px 15px 5px 15px',
                                      borderRadius: '4px',
                                    }}
                                  >
                                    {defaultEmbed.isTwoColumnOptions.map(
                                      (item, index) => (
                                        <MenuItem
                                          key={index}
                                          value={item.label}
                                          style={{ backgroundColor: '#fff' }}
                                        >
                                          {item.label.toString()}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </div>
                                <div className="feild flex flex-col">
                                  <div className="lbl font s14 b4 c000">
                                      showChatbotButton
                                  </div>
                                  <Select
                                    labelId="select screen"
                                    id="select screen"
                                    value={
                                      defaultEmbedFields?.showChatbotButton ||
                                      false
                                    }
                                    //defaultValue={false}
                                    onChange={(event) => {
                                      setDefaultEmbedFields({
                                        ...defaultEmbedFields,
                                        showChatbotButton: event.target.value,
                                      });
                                    }}
                                    disableUnderline
                                    style={{
                                      border: '1px solid #ddd',
                                      padding: '8px 15px 5px 15px',
                                      borderRadius: '4px',
                                    }}
                                  >
                                    {defaultEmbed.showChatbotButtonOptions.map(
                                      (item, index) => (
                                        <MenuItem
                                          key={index}
                                          value={item.label}
                                          style={{ backgroundColor: '#fff' }}
                                        >
                                          {item.label.toString()}
                                        </MenuItem>
                                      )
                                    )}
                                  </Select>
                                </div>
                              </div>
                            </div>
                            <div className=" flex items-center gap-2">
                              {/* <Button
                                variant="contained"
                                color="primary"
                                onClick={() => setTheDefaultEmbedFields({})}
                              >
                                Clear default Config
                              </Button> */}
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={() => updateSettings({})}
                              >
                                Save
                              </Button>
                              <NotificationBar />
                            </div>
                          </div>
                        )}
                        {activeTab === 1 && (
                          <>
                            <div className="default-emded-setting  w-full">
                              <div className="wrap w-full">
                                <div className="form grid grid-cols-1 lg:grid-cols-3 gap-4">
                                  <div className="feild flex flex-col">
                                    <div className="lbl font s14 b4 c000">
                                      Button Label
                                    </div>
                                    <div className="itm flex aic">
                                      <input
                                        className="cleanbtn iput font s15 b4 c000"
                                        value={defaultEmbedFields.buttonLabel}
                                        placeholder={defaultEmbed.buttonLabel}
                                        onChange={(e) => {
                                          setDefaultEmbedFields({
                                            ...defaultEmbedFields,
                                            buttonLabel: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="feild flex flex-col">
                                    <div className="lbl font s14 b4 c000">
                                      Primary Color
                                    </div>
                                    <div className="itm flex aic">
                                      <input
                                        className="cleanbtn iput font s15 b4 c000"
                                        value={defaultEmbedFields.primaryColor}
                                        placeholder={defaultEmbed.primaryColor}
                                        onChange={(e) => {
                                          setDefaultEmbedFields({
                                            ...defaultEmbedFields,
                                            primaryColor: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="feild flex flex-col">
                                    <div className="lbl font s14 b4 c000">
                                      Show Button
                                    </div>
                                    <Select
                                      labelId="select screen"
                                      id="select screen"
                                      value={defaultEmbedFields.showButton}
                                      onChange={(event) => {
                                        setDefaultEmbedFields({
                                          ...defaultEmbedFields,
                                          showButton: event.target.value,
                                        });
                                      }}
                                      disableUnderline
                                      style={{
                                        border: '1px solid #ddd',
                                        padding: '8px 15px 5px 15px',
                                        borderRadius: '4px',
                                      }}
                                    >
                                      {defaultEmbed.showButtonOptions.map(
                                        (item, index) => (
                                          <MenuItem
                                            key={index}
                                            value={item.label}
                                            style={{ backgroundColor: '#fff' }}
                                          >
                                            {item.label.toString()}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </div>
                                  <div className="feild flex flex-col">
                                    <div className="lbl font s14 b4 c000">
                                      Start Open
                                    </div>
                                    <Select
                                      labelId="select screen"
                                      id="select screen"
                                      value={defaultEmbedFields?.startOpen}
                                      defaultValue={false}
                                      onChange={(event) => {
                                        setDefaultEmbedFields({
                                          ...defaultEmbedFields,
                                          startOpen: event.target.value,
                                        });
                                      }}
                                      disableUnderline
                                      style={{
                                        border: '1px solid #ddd',
                                        padding: '8px 15px 5px 15px',
                                        borderRadius: '4px',
                                      }}
                                    >
                                      {defaultEmbed.startOpenOptions.map(
                                        (item, index) => (
                                          <MenuItem
                                            key={index}
                                            value={item.label}
                                            style={{ backgroundColor: '#fff' }}
                                          >
                                            {item.label.toString()}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </div>
                                  <div className="feild flex flex-col">
                                    <div className="lbl font s14 b4 c000">
                                      Layout
                                    </div>
                                    <Select
                                      labelId="select screen"
                                      id="select screen"
                                      value={defaultEmbedFields.layout}
                                      onChange={(event) => {
                                        setDefaultEmbedFields({
                                          ...defaultEmbedFields,
                                          layout: event.target.value,
                                        });
                                      }}
                                      disableUnderline
                                      style={{
                                        border: '1px solid #ddd',
                                        padding: '8px 15px 5px 15px',
                                        borderRadius: '4px',
                                      }}
                                    >
                                      {defaultEmbed.layouts.map(
                                        (item, index) => (
                                          <MenuItem
                                            key={index}
                                            value={item.label}
                                            style={{ backgroundColor: '#fff' }}
                                          >
                                            {item.label}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </div>
                                  <div className="feild flex flex-col">
                                    <div className="lbl font s14 b4 c000">
                                      Start Screen
                                    </div>
                                    <Select
                                      labelId="select screen"
                                      id="select screen"
                                      value={routeStartChoice} // If at least one routeOpts exist, choose the first, else return empty
                                      onChange={handleChangeMenu}
                                      disableUnderline
                                      style={{
                                        border: '1px solid #ddd',
                                        padding: '8px 15px 5px 15px',
                                        borderRadius: '4px',
                                      }}
                                    >
                                      {routeOpts.map(({ id, route, video }) => (
                                        <MenuItem
                                          key={id + route + video}
                                          value={route}
                                          style={{ backgroundColor: '#fff' }}
                                        >
                                          {id}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </div>
                                  <div className="feild flex flex-col">
                                    <div className="lbl font s14 b4 c000">
                                      Background Opacity
                                    </div>
                                    <div className="itm flex aic">
                                      <input
                                        className="cleanbtn iput font s15 b4 c000"
                                        value={
                                          defaultEmbedFields.backgroundOpacity
                                        }
                                        placeholder={
                                          defaultEmbed.backgroundOpacity
                                        }
                                        onChange={(e) => {
                                          setDefaultEmbedFields({
                                            ...defaultEmbedFields,
                                            backgroundOpacity: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  
                                  <div className="feild flex flex-col">
                                    <div className="lbl font s14 b4 c000">
                                      Desktop Width
                                    </div>
                                    <div className="itm flex aic">
                                      <input
                                        className="cleanbtn iput font s15 b4 c000"
                                        value={defaultEmbedFields.desktopWidth}
                                        placeholder={defaultEmbed.desktopWidth}
                                        onChange={(e) => {
                                          setDefaultEmbedFields({
                                            ...defaultEmbedFields,
                                            desktopWidth: e.target.value,
                                          });
                                        }}
                                      />
                                    </div>
                                  </div>
                                  <div className="feild flex flex-col">
                                    <div className="lbl font s14 b4 c000">
                                      Widget Type
                                    </div>
                                    <Select
                                      labelId="select screen"
                                      id="select screen"
                                      value={defaultEmbedFields.widgetType}
                                      onChange={(event) => {
                                        setDefaultEmbedFields({
                                          ...defaultEmbedFields,
                                          widgetType: event.target.value,
                                        });
                                      }}
                                      disableUnderline
                                      style={{
                                        border: '1px solid #ddd',
                                        padding: '8px 15px 5px 15px',
                                        borderRadius: '4px',
                                      }}
                                    >
                                      {defaultEmbed.widgetTypes.map(
                                        (item, index) => (
                                          <MenuItem
                                            key={index}
                                            value={item.label}
                                            style={{ backgroundColor: '#fff' }}
                                          >
                                            {item.label}
                                          </MenuItem>
                                        )
                                      )}
                                    </Select>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div>
                              <Button
                                variant="contained"
                                color="primary"
                                onClick={clearNow}
                              >
                                Clear
                              </Button>
                            </div>
                          </>
                        )}
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  <div className=" py-2 flex items-center flex-col w-full">
                    <div className=" w-full">
                      <h3 className=" pb-2 text-xl font-semibold">
                        Copy your code snippet
                      </h3>
                      {activeTab === 0 && (
                        <TextField
                          inputRef={EmbedCodeRef}
                          variant="outlined"
                          value={`<script src='${EMBED_HOST}/leasemagnets.js'></script> <script>LeaseMagnets({ uuid: "${magnetUuid}"})</script>`}
                          style={{ width: '100%' }}
                          multiline={true}
                        />
                      )}
                      {activeTab === 1 && (
                        <div className=" flex flex-col gap-2">
                          <TextField
                            inputRef={EmbedCodeRef}
                            variant="outlined"
                            value={`<script src='${EMBED_HOST}/leasemagnets.js'></script> <script>LeaseMagnets(${JSON.stringify(
                              { ...defaultEmbedFields, uuid: magnetUuid }
                            )})</script>`}
                            style={{ width: '100%' }}
                            multiline={true}
                          />
                          <div className=" flex items-center gap-2 px-2">
                            <input
                              onChange={(e) => checkshow(!e.target.checked)}
                              type="checkbox"
                              name="chatbot"
                              id="chatbot"
                            />{' '}
                            <label htmlFor="chatbot">Show chatbot</label>
                          </div>
                        </div>
                      )}
                    </div>

                    <div className=" py-2 flex items-center justify-center">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={(e) => copy(e, EmbedCodeRef)}
                      >
                        Copy
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
              <br />
              <br />
              <br />
              <br />
              <br />
            </Card>
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
          </>
        )}
        {embedType === EMBED.Inline && (
          <>
            <div className="embed-inline-blk flex flex-col">
              <h3 className="w-full text-2xl pb-4 font-semibold text-center">
                Embed your Tour.Video InLine
              </h3>
              <div className="inline-wrap flex">
                <div className={`iframe-blk ${layoutPreview}`}>
                  <iframe
                    src={inlineIframeUrl}
                    width={
                      layoutPreview === 'vertical'
                        ? '425px'
                        : layoutPreview === 'horizontal'
                        ? '700px'
                        : '700px'
                    }
                    height={
                      layoutPreview === 'vertical'
                        ? '800px'
                        : layoutPreview === 'horizontal'
                        ? '500px'
                        : '700px'
                    }
                    style={{
                      borderRadius: `${inlineIframeField.roundedCorners}px`,
                    }}
                  ></iframe>
                </div>
                <div className="inline-iframe-form flex flex-col">
                  <div className="title font s16 b6 c000">
                    Make your snippet
                  </div>
                  <div className="hdr flex aic">
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        value={layoutPreview}
                        onChange={(e) => setLayoutPreview(e.target.value)}
                      >
                        <FormControlLabel
                          value="square"
                          control={<Radio color="primary" />}
                          label="Square"
                        />

                        <FormControlLabel
                          value="horizontal"
                          control={<Radio color="primary" />}
                          label="Horizontal Rectangle"
                        />
                        <FormControlLabel
                          value="vertical"
                          control={<Radio color="primary" />}
                          label="Vertical Rectangle"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="feild flex flex-col">
                    <div className="lbl font s14 b4 c000">Default Screen</div>
                    <Select
                      labelId="select screen"
                      id="select screen"
                      value={screenChoice} // If at least one routeOpts exist, choose the first, else return empty
                      onChange={handleChangeMenu}
                      disableUnderline
                      style={{
                        border: '1px solid #ddd',
                        padding: '8px 15px 5px 15px',
                        borderRadius: '4px',
                      }}
                    >
                      {routeOpts.map(({ id, route, video }) => (
                        <MenuItem
                          key={id + route + video}
                          value={route}
                          style={{ backgroundColor: '#fff' }}
                        >
                          {id}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className="feild flex flex-col">
                    <div className="lbl font s14 b4 c000">Inline CTA</div>
                    <div className="itm flex aic">
                      <input
                        className="cleanbtn iput font s15 b4 c000"
                        defaultValue={inlineIframeField.inlineCta}
                        onChange={(e) => {
                          setInlineIframeField({
                            ...inlineIframeField,
                            inlineCta: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="feild flex flex-col">
                    <div className="lbl font s14 b4 c000">Rounded Corners</div>
                    <div className="itm flex aic">
                      <input
                        className="cleanbtn iput font s15 b4 c000"
                        defaultValue={inlineIframeField.roundedCorners}
                        onChange={(e) => {
                          setInlineIframeField({
                            ...inlineIframeField,
                            roundedCorners: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="feild flex flex-col">
                    <div className="lbl font s14 b4 c000">Copy video url</div>
                    <div className="itm flex aic">
                      <input
                        ref={inlineSrcUrlRef}
                        className="cleanbtn iput font s15 b4 c000"
                        value={inlineIframeUrl}
                        readOnly
                      />
                      <button
                        className="cleanbtn copy-btn font s13 cfff rel"
                        onClick={(e) => {
                          copy(e, inlineSrcUrlRef);
                          setInlineIframeField({
                            ...inlineIframeField,
                            inlineSrcUrlCopied: true,
                          });
                        }}
                      >
                        Copy
                        <div
                          className={`copied font s14 b4 cfff anim ${
                            inlineIframeField.inlineSrcUrlCopied ? 'sho' : 'hid'
                          }`}
                        >
                          Copied!
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="feild flex flex-col">
                    <h3 className=" pb-2 text-xl font-semibold">
                      Copy your code snippet
                    </h3>
                    <div className="itm area flex aic">
                      <textarea
                        ref={inlineIframeRef}
                        className="cleanbtn iput font s15 b4 c000"
                        value={`<iframe src='${inlineIframeUrl}' width='${
                          layoutPreview === 'vertical'
                            ? '425px'
                            : layoutPreview === 'horizontal'
                            ? '700px'
                            : '700px'
                        }' height='${
                          layoutPreview === 'vertical'
                            ? '800px'
                            : layoutPreview === 'horizontal'
                            ? '500px'
                            : '700px'
                        }' borderRadius: ${
                          inlineIframeField.roundedCorners
                        }px></iframe>`}
                        readOnly
                      />
                      <button
                        className="cleanbtn copy-btn font s13 cfff rel"
                        onClick={(e) => {
                          copy(e, inlineIframeRef);
                          setInlineIframeField({
                            ...inlineIframeField,
                            inlineIframeCopied: true,
                          });
                        }}
                      >
                        Copy
                        <div
                          className={`copied font s14 b4 cfff anim ${
                            inlineIframeField.inlineIframeCopied ? 'sho' : 'hid'
                          }`}
                        >
                          Copied!
                        </div>
                      </button>
                    </div>
                  </div>
                  <div className="feild flex flex-col">
                    <div className="lbl font s14 b4 c000">
                      Want more additional features
                    </div>
                    <a
                      href="https://docs.google.com/document/d/1VKJoYjWigQBYGmI2aQ5-tlyVvBk-yM5FlAnFOOjBaCI/edit"
                      target="_blank"
                      className="cleanbtn link-button font s14 cfff"
                    >
                      Click here for additional iframe layouts
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
        {embedType === EMBED.VideoLibrary && (
          <>
            <h3 className="w-full text-2xl pb-4 font-semibold text-center">
              Embed your Tour.Video VideoLibrary
            </h3>
            <Card>
              <Grid container spacing={3}>
                <Grid item>
                  <iframe
                    src={`${TOUR_HOST}/share?magnet_uuid=${magnetUuid}&share=intro.main&agent=0&name=John`}
                    width="700px"
                    height="600px"
                  />
                </Grid>
              </Grid>
              <br />
              <div>
                <h3 className=" pb-2 text-xl font-semibold">
                  Copy your code snippet
                </h3>
                <TextField
                  inputRef={EmbedCodeRef}
                  variant="outlined"
                  value={`<iframe src='${TOUR_HOST}/share?magnet_uuid=${magnetUuid}&share=intro.main&agent=0&name=John' width='700px' height='600px' />`}
                  style={{ width: '100%' }}
                  multiline={true}
                  className="my-2"
                />
                <div className=" py-2 flex items-center justify-center">
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={(e) => copy(e, EmbedCodeRef)}
                  >
                    Copy
                  </Button>
                </div>
              </div>
            </Card>
          </>
        )}
        {embedType === EMBED.Button && (
          <>
            <div className="embed-inline-blk flex flex-col">
              <h3 className="w-full text-2xl pb-4 font-semibold text-center">
                {' '}
                Embed your Tour.Video Button
              </h3>
              <div className="inline-wrap flex">
                <div className={`iframe-blk`}>
                  <div style={{ margin: 10 }}>
                    <PreviewWidgetEmbed
                      alwaysShow={true}
                      uuid={magnetUuid}
                      showButton={false}
                    />
                    {popUpIframeField.selectedButton === 'normal-button' ? (
                      <button
                        className="font"
                        style={{
                          backgroundColor: '#3898EC',
                          fontSize: '15px',
                          padding: '10px 20px',
                          color: '#fff',
                          borderRadius: '3px',
                          outline: 'none',
                          border: 'none',
                          fontFamily: 'Poppins, sans-serif',
                        }}
                        onClick={() => {
                          window.tempLeaseMagnets({
                            showButton: true, //variable
                            startOpen: false, //variable
                            layout: popUpIframeField.selectedLayout, //variable
                            primaryColor: '#872322', //variable
                            startScreen: routeStartChoice, //variable
                            buttonLabel: 'Take a virtual tour & $250 off', //variable
                            backgroundOpacity:
                              popUpIframeField.backgroundOpacity, //variable
                            desktopWidth: popUpIframeField.desktopWidth, //variable
                            widgetType: popUpIframeField.selectedWidgetType,
                            uuid: magnetUuid, //maybe make this a variable
                            blockEvents: 'keydown',
                          });
                        }}
                      >
                        Open tour
                      </button>
                    ) : popUpIframeField.selectedButton === 'video-button' ? (
                      <button
                        style={{
                          borderRadius: '10px',
                          position: 'relative',
                          outline: 'none',
                          border: 'none',
                          height: '120px',
                          width: '200px',
                          overflow: 'hidden',
                        }}
                        onClick={() => {
                          window.tempLeaseMagnets({
                            showButton: true, //variable
                            startOpen: false, //variable
                            layout: popUpIframeField.selectedLayout, //variable
                            primaryColor: '#872322', //variable
                            startScreen: routeStartChoice, //variable
                            buttonLabel: 'Take a virtual tour & $250 off', //variable
                            backgroundOpacity:
                              popUpIframeField.backgroundOpacity, //variable
                            desktopWidth: popUpIframeField.desktopWidth, //variable
                            widgetType: popUpIframeField.selectedWidgetType,
                            uuid: magnetUuid, //maybe make this a variable
                            blockEvents: 'keydown',
                          });
                        }}
                      >
                        <div
                          style={{
                            position: 'absolute',
                            inset: 0,
                            backgroundColor: 'rgba(0,0,0,0.2)',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            zIndex: 2,
                          }}
                        >
                          <div
                            className="font"
                            style={{
                              fontSize: '16px',
                              fontWeight: '600',
                              color: '#fff',
                              textShadow: '1px 1px 1px rgba(0,0,0,0.3)',
                            }}
                          >
                            WATCH INTRO
                          </div>
                          <div
                            style={{
                              marginLeft: 6,
                              color: '#fff',
                              fontSize: 18,
                            }}
                          >
                            &#9658;
                          </div>
                        </div>
                        <video
                          muted
                          autoPlay
                          playsInline
                          loop
                          src={videoStartChoice}
                          style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'cover',
                          }}
                        ></video>
                      </button>
                    ) : popUpIframeField.selectedButton === 'text-button' ? (
                      <button
                        className="font"
                        style={{
                          fontSize: '16px',
                          color: '#121212',
                          fontWeight: '500',
                          background: 'transparent',
                          outline: 'none',
                          border: 'none',
                        }}
                        onClick={() => {
                          window.tempLeaseMagnets({
                            showButton: true, //variable
                            startOpen: false, //variable
                            layout: popUpIframeField.selectedLayout, //variable
                            primaryColor: '#872322', //variable
                            startScreen: routeStartChoice, //variable
                            buttonLabel: 'Take a virtual tour & $250 off', //variable
                            backgroundOpacity:
                              popUpIframeField.backgroundOpacity, //variable
                            desktopWidth: popUpIframeField.desktopWidth, //variable
                            widgetType: popUpIframeField.selectedWidgetType,
                            uuid: magnetUuid, //maybe make this a variable
                            blockEvents: 'keydown',
                          });
                        }}
                      >
                        {popUpIframeField.selectedTextButton}
                      </button>
                    ) : null}
                  </div>
                </div>
                <div className="inline-iframe-form flex flex-col">
                  <div className="title font s16 b6 c000">
                    Make your snippet
                  </div>
                  <div className="hdr flex aic">
                    <FormControl component="fieldset">
                      <RadioGroup
                        row
                        aria-label="position"
                        name="position"
                        value={popUpIframeField.selectedButton}
                        onChange={(e) =>
                          setPopUpIframeField({
                            ...popUpIframeField,
                            selectedButton: e.target.value,
                          })
                        }
                      >
                        <FormControlLabel
                          value="video-button"
                          control={<Radio color="primary" />}
                          label="Video button"
                        />

                        <FormControlLabel
                          value="normal-button"
                          control={<Radio color="primary" />}
                          label="Normal button"
                        />
                        <FormControlLabel
                          value="text-button"
                          control={<Radio color="primary" />}
                          label="Text button"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  {popUpIframeField.selectedButton === 'text-button' && (
                    <div className="feild flex flex-col">
                      <div className="lbl font s14 b4 c000">
                        Choose Text Button
                      </div>
                      <Select
                        labelId="select screen"
                        id="select screen"
                        value={popUpIframeField.selectedTextButton}
                        onChange={handleTextButton}
                        disableUnderline
                        style={{
                          border: '1px solid #ddd',
                          padding: '8px 15px 5px 15px',
                          borderRadius: '4px',
                        }}
                      >
                        {popUpIframeField.textButtons.map((item, index) => (
                          <MenuItem
                            key={index}
                            value={item.label}
                            style={{ backgroundColor: '#fff' }}
                          >
                            {item.label}
                          </MenuItem>
                        ))}
                      </Select>
                    </div>
                  )}
                  <div className="feild flex flex-col">
                    <div className="lbl font s14 b4 c000">Layout</div>
                    <Select
                      labelId="select screen"
                      id="select screen"
                      value={popUpIframeField.selectedLayout}
                      onChange={handleLayout}
                      disableUnderline
                      style={{
                        border: '1px solid #ddd',
                        padding: '8px 15px 5px 15px',
                        borderRadius: '4px',
                      }}
                    >
                      {popUpIframeField.layouts.map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item.label}
                          style={{ backgroundColor: '#fff' }}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className="feild flex flex-col">
                    <div className="lbl font s14 b4 c000">Start Screen</div>
                    <Select
                      labelId="select screen"
                      id="select screen"
                      value={routeStartChoice} // If at least one routeOpts exist, choose the first, else return empty
                      onChange={handleChangeMenu}
                      disableUnderline
                      style={{
                        border: '1px solid #ddd',
                        padding: '8px 15px 5px 15px',
                        borderRadius: '4px',
                      }}
                    >
                      {routeOpts.map(({ id, route, video }) => (
                        <MenuItem
                          key={id + route + video}
                          value={route + '|' + video}
                          style={{ backgroundColor: '#fff' }}
                        >
                          {id}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className="feild flex flex-col">
                    <div className="lbl font s14 b4 c000">
                      Background Opacity
                    </div>
                    <div className="itm flex aic">
                      <input
                        className="cleanbtn iput font s15 b4 c000"
                        defaultValue={popUpIframeField.backgroundOpacity}
                        onChange={(e) => {
                          setPopUpIframeField({
                            ...popUpIframeField,
                            backgroundOpacity: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="feild flex flex-col">
                    <div className="lbl font s14 b4 c000">Desktop Width</div>
                    <div className="itm flex aic">
                      <input
                        className="cleanbtn iput font s15 b4 c000"
                        defaultValue={popUpIframeField.desktopWidth}
                        onChange={(e) => {
                          setPopUpIframeField({
                            ...popUpIframeField,
                            desktopWidth: e.target.value,
                          });
                        }}
                      />
                    </div>
                  </div>
                  <div className="feild flex flex-col">
                    <div className="lbl font s14 b4 c000">Widget Type</div>
                    <Select
                      labelId="select screen"
                      id="select screen"
                      value={popUpIframeField.selectedWidgetType}
                      onChange={handleWidgetType}
                      disableUnderline
                      style={{
                        border: '1px solid #ddd',
                        padding: '8px 15px 5px 15px',
                        borderRadius: '4px',
                      }}
                    >
                      {popUpIframeField.widgetTypes.map((item, index) => (
                        <MenuItem
                          key={index}
                          value={item.label}
                          style={{ backgroundColor: '#fff' }}
                        >
                          {item.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </div>
                  <div className="feild flex flex-col">
                    <h3 className=" pb-2 text-xl font-semibold">
                      Copy your code snippet
                    </h3>
                    <div className="itm area flex aic">
                      <textarea
                        ref={inlineIframeRef}
                        className="cleanbtn iput font s15 b4 c000"
                        value={`${
                          popUpIframeField.selectedButton === 'text-button'
                            ? `<script src='https://embed.tour.video/leasemagnets.js'></script><button  style='font-size: 16px; color: #121212; font-weight: 600; background: transparent; outline: none; border: none; cursor: pointer' onclick='window.tempLeaseMagnets({showButton: false, startOpen: true, layout: "${popUpIframeField.selectedLayout}", primaryColor: "#872322", startScreen: "${routeStartChoice}", buttonLabel: "Take a virtual tour & $250 off", backgroundOpacity: "${popUpIframeField.backgroundOpacity}", desktopWidth: "${popUpIframeField.desktopWidth}", widgetType: "${popUpIframeField.selectedWidgetType}", uuid: "${magnetUuid}", blockEvents: "keydown"})'>Open tour</button>`
                            : popUpIframeField.selectedButton === 'video-button'
                            ? `<script src='https://embed.tour.video/leasemagnets.js'></script><button  style='border-radius: 10px; position: relative; outline: none; border: none; height: 120px; width: 200px; padding: 0; margin: 0; cursor: pointer; overflow: hidden' onclick='window.tempLeaseMagnets({showButton: false, startOpen: true, layout: "${popUpIframeField.selectedLayout}", primaryColor: "#872322", startScreen: "${routeStartChoice}", buttonLabel: "Take a virtual tour & $250 off", backgroundOpacity: "${popUpIframeField.backgroundOpacity}", desktopWidth: "${popUpIframeField.desktopWidth}", widgetType: "${popUpIframeField.selectedWidgetType}", uuid: "${magnetUuid}", blockEvents: "keydown"})'><div style='position: absolute; inset: 0; background-color: rgba(0,0,0,0.2); display: flex; align-items: center; justify-content: center; z-index: 2'><div style='font-family: Poppins, sans-serif; font-size: 16px; font-weight: 600; color: #fff; text-shadow: 1px 1px 1px rgba(0,0,0,0.3)'>WATCH INTRO</div><div style='margin-left: 6px; color: #fff; font-size: 18px'>&#9658;</div></div><video muted autoPlay playsInline loop src='${videoStartChoice}' style='width: 100%; height: 100%; object-fit: cover'></video></button>`
                            : popUpIframeField.selectedButton ===
                              'normal-button'
                            ? `<script src="https://embed.tour.video/leasemagnets.js"></script><button  style="background-color: #3898EC; font-size: 15px; padding: 10px 20px; color: #fff; borderRadius: 3px; outline: none; border: none; cursor: pointer; font-family: Poppins, sans-serif" onclick='window.tempLeaseMagnets({showButton: false, startOpen: true, layout: "${popUpIframeField.selectedLayout}", primaryColor: "#872322", startScreen: "${routeStartChoice}", buttonLabel: "Take a virtual tour & $250 off", backgroundOpacity: "${popUpIframeField.backgroundOpacity}", desktopWidth: "${popUpIframeField.desktopWidth}", widgetType: "${popUpIframeField.selectedWidgetType}", uuid: "${magnetUuid}", blockEvents: "keydown"})'>Open tour</button>`
                            : ''
                        }`}
                        readOnly
                      />
                      <button
                        className="cleanbtn copy-btn font s13 cfff rel"
                        onClick={(e) => {
                          copy(e, inlineIframeRef);
                          setInlineIframeField({
                            ...inlineIframeField,
                            inlineIframeCopied: true,
                          });
                        }}
                      >
                        Copy
                        <div
                          className={`copied font s14 b4 cfff anim ${
                            inlineIframeField.inlineIframeCopied ? 'sho' : 'hid'
                          }`}
                        >
                          Copied!
                        </div>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  );
}
