import { useElements, useStripe } from '@stripe/react-stripe-js';
import axios from 'axios';
import moment from 'moment';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ReactCanvasConfetti from 'react-canvas-confetti';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import 'slick-carousel/slick/slick-theme.css';
import 'slick-carousel/slick/slick.css';
import { AccessLevelFunction } from '../../components/Reusable/AccessLevelWrapper';
import AnimatedDialog from '../../components/Reusable/AnimatedDialog';
import * as loader from '../../lottie/loader.json';
import { ACCESS_LEVEL } from '../../utils/constants.js';
import { API_HOST } from '../../utils/request';

// ReactCanvasConfetti methods //
function randomInRange(min, max) {
  return Math.random() * (max - min) + min;
}
const canvasStyles = {
  position: 'fixed',
  pointerEvents: 'none',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
};
function getAnimationSettings(originXA, originXB) {
  return {
    startVelocity: 30,
    spread: 360,
    ticks: 60,
    zIndex: 0,
    particleCount: 150,
    origin: {
      x: randomInRange(originXA, originXB),
      y: Math.random() - 0.2,
    },
  };
}
const defaultOptions = {
  loop: true,
  autoplay: true,
  animationData: loader.default,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};
// ReactCanvasConfetti methods //

const Rewards = ({ name, analytics = true, activity = true }) => {
  const [showstripeform, setshowstripeform] = useState(false);
  const [credits, setcredits] = useState('');
  const [stripeloading, setstripeloading] = useState(false);
  const { magnetData } = useSelector((state) => state.getMagnet);
  // const options = {
  //   // passing the client secret obtained from the server
  //   clientSecret:
  //     'sk_test_51K5VDmCgQX5AncW3KeM1ThhJk92tTxU6wJAd4Rb72wt8WZGpOE0Gl1k4s0etiyccH7oxBnr9moVangnsDGZ8Sw0500Gaz9tZNx',
  // };
  const stripe = useStripe();
  const elements = useElements();

  const [openCreditDialog, setOpenCreditDialog] = useState(false);
  const [selectedgiftTemplate, setSelectedGiftTemplate] = useState();
  const [customAmount, setCustomAmount] = useState(false);
  const [selectedCreditAmount, setSelectedCreditAmount] = useState(0);
  const [enableCreditCustomAmount, setEnableCreditCustomAmount] =
    useState(false);
  const [accesslvl, setacesslvl] = useState();
  const [billingdetails, setbillingdetails] = useState({
    name: '',
    address: '',
    phonenumber: '',
  });
  const [community_id, setcommunity_id] = useState('');

  const [openSendedGiftDialog, setopenSendedGiftDialog] = useState(false);

  const [page, setPage] = useState(1);
  const [fundamount, setfundamount] = useState('');

  const [reward, setReward] = useState({
    toName: '',
    toEmail: '',
    fromName: '',
    fromEmail: '',
    subject: ``,
    message: '',
  });
  const [selectedrewarddata, setselectedrewarddata] = useState('');

  var addCreditAmountSlider = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 2.1,
          slidesToScroll: 2,
        },
      },
    ],
  };

  const handleSubmit = async (event) => {
    // processpayment(result.token);
    // console.log('token', result.token);
    console.log('the_reward', reward);
    let old_amount = await getcommunity();
    console.log('amount', old_amount);
    console.log(
      'amount',
      fundamount,
      (old_amount ? old_amount : 0) + fundamount
    );
    postcommunity((old_amount ? old_amount : 0) + fundamount);
    managerewardindirectual({
      ...reward,
      rewardId: 'funded',
      type: 'funded',
      data: {
        ...JSON.parse(reward.data),
        funded: true,
      },
    });

    setOpenCreditDialog(false);
  };

  // update credits
  const getcommunity = async () => {
    const res = await axios.get(
      `https://api.directual.com/good/api/v5/data/community/getCommunityById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${community_id}`
    );
    console.log('response', res);
    console.log('credits', res.data?.payload[0]?.credits);
    if (res.data?.payload[0]?.credits) {
      setcredits(res.data?.payload[0]?.credits);
      return res.data?.payload[0]?.credits;
    } else {
      setcredits(0);
    }
  };

  // shift this to backend
  const postcommunity = async (newcredits) => {
    console.log('newcredits', newcredits);
    const res = await axios.post(
      `https://api.directual.com/good/api/v5/data/community/newCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83`,
      {
        id: community_id,
        credits: newcredits,
      }
    );
    console.log('postres', res);
    //getcommunity();
  };
  const managerewardindirectual = async (obj) => {
    console.log('obj', obj);
    const res = await axios.post(
      `https://api.directual.com/good/api/v5/data/request_history/getRequestHistoryByTypeAndCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=206&requestType=reward_request`,
      {
        ...obj,
      }
    );
    getrewardrequestlist();
    console.log('directual_res', res);
    return res;
  };

  const getaccesslevel = async () => {
    const tempAccessLevel = await AccessLevelFunction(community_id);
    setacesslvl(tempAccessLevel);
  };

  useEffect(() => {
    getaccesslevel();
    getcommunity();
  }, []);

  const processpayment = async (token) => {
    setstripeloading(true);
    try {
      console.log('payment', {
        token_id: token.id,
        name: billingdetails.name,
        address: billingdetails.address,
        phonenumber: billingdetails.phonenumber,
        amount: fundamount,
      });
      const res = await axios.post(`${API_HOST}/update_credits`, {
        token_id: token.id,
        name: billingdetails.name,
        address: billingdetails.address,
        phonenumber: billingdetails.phonenumber,
        amount: fundamount,
      });
      console.log('payment response', res);
      if (res.data?.res?.status == 'succeeded') {
        //setShowCreditDialog(false);
        const community_credits = await getcommunity();
        postcommunity(community_credits + fundamount);
        setstripeloading(false);
      }
    } catch (error) {
      console.log(error);
      console.log(error.response);
      setstripeloading(false);
    }
  };

  const [cancelexpand, setcancelexpand] = useState('');

  const handleCloseCreditDialog = () => {
    setOpenCreditDialog(false);
  };

  const subjectAreaRef = useRef();
  const messageAreaRef = useRef();
  function subjectAreaAdjust() {
    subjectAreaRef.current.style.height = '1px';
    subjectAreaRef.current.style.height =
      20 + subjectAreaRef.current.scrollHeight + 'px';
  }

  function messageAreaAdjust() {
    messageAreaRef.current.style.height = '1px';
    messageAreaRef.current.style.height =
      20 + messageAreaRef.current.scrollHeight + 'px';
  }

  useEffect(() => {
    if (reward.toName) {
      setReward({
        ...reward,
        subject: `${reward.toName}, from ${reward.fromName} at ${name}`,
      });
    }
  }, [reward.toName]);

  useEffect(() => {
    if (reward.fromName) {
      setReward({
        ...reward,
        subject: `${reward.toName}, from ${reward.fromName} at ${name}`,
      });
    }
  }, [reward.fromName]);

  const refAnimationInstance = useRef(null);

  const getInstance = useCallback((instance) => {
    refAnimationInstance.current = instance;
  }, []);

  const nextTickAnimation = useCallback(() => {
    if (refAnimationInstance.current) {
      refAnimationInstance.current(getAnimationSettings(0.1, 0.3));
      refAnimationInstance.current(getAnimationSettings(0.7, 0.9));
    }
  }, []);

  const [msg, setmsg] = useState(
    'I was hoping you could do me a favor and leave my team and me a review? Thank you in advance.'
  );
  const messagelist = [
    {
      msg: 'I was hoping you could do me a favor and leave my team and me a review? Thank you in advance.',
    },
    {
      msg: 'I wanted to just say sincerely thank you for leaving us a review. It really meant alot to us.',
    },
    {
      msg: 'I wanted to just say sincerely thank you for leaving us a review. Just wanted to check in to see if you were ever were able to leave us us a Google Review?\n It really means alot to us.',
    },
    {
      msg: 'Just wanted to share a special token as a thank you for kind words in the review and being an awesome resident here. \n It really means alot to us.',
    },
  ];

  const [rewardList, setrewardList] = useState([]);
  const [org_rewardlist, setorg_rewardlist] = useState([]);

  const getrewardrequestlist = async () => {
    try {
      console.log('magnetData.community_id', magnetData.community_id);
      const res = await axios.get(
        `https://api.directual.com/good/api/v5/data/request_history/getallrequesthistorybytype?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=&requestType=funding_request`
      );
      console.log('res', res);
      setrewardList(res.data.payload);
      setorg_rewardlist(res.data.payload);
      //filterrewardlist()
    } catch (error) {
      console.log('error', error);
    }
  };
  useEffect(() => {
    getrewardrequestlist();
  }, [magnetData.community_id]);

  return (
    <>
      <ReactCanvasConfetti refConfetti={getInstance} style={canvasStyles} />
      <div className="rewards-page flex flex-col">
        {/* <Header>
          <div className="hdr flex aic">
            <div className="left flex aic">
              <div className="page-title font s22 black b7">
                Rewards Playbook
              </div>
              <div className="balance font s14 b4 cfff">{`$${credits}`}</div>
            </div>
            <div className="right flex aic">
              <button
                onClick={() => {
                  setOpenCreditDialog(true);
                }}
                className="add-credits-btn cleanbtn font s15 b4 color"
              >
                Add credits
              </button>
            </div>
          </div>
        </Header> */}
        <div className="wrapper flex flex-col">
          <div className="flex forms-section">
            <div className="reward-request-side flex flex-col">
              <div className="reward-content">
                <RewardsTable
                  rewardList={rewardList}
                  page={page}
                  setmsg={setmsg}
                  selectedgiftTemplate={selectedgiftTemplate}
                  setSelectedGiftTemplate={setSelectedGiftTemplate}
                  messagelist={messagelist}
                  managerewardindirectual={managerewardindirectual}
                  setReward={setReward}
                  cancelexpand={cancelexpand}
                  setselectedrewarddata={setselectedrewarddata}
                  accesslvl={accesslvl}
                  setOpenCreditDialog={setOpenCreditDialog}
                  setfundamount={setfundamount}
                  setcommunity_id={setcommunity_id}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <AnimatedDialog
        open={openCreditDialog}
        onClose={handleCloseCreditDialog}
        maxWidth={'xs'}
        BackdropProps={{
          style: { backgroundColor: 'rgba(17, 24, 39, 0.9)' },
        }}
      >
        <div className="add-credit-dialog flex-col">
          <div className="hdr flex aic">
            <div className="title font s18 b6 c222">
              Add&nbsp;
              <span className="color">Credit</span>
            </div>
            <button
              className="cleanbtn cross-btn fontr s30 c222 flex aic anim"
              onClick={handleCloseCreditDialog}
            >
              &times;
            </button>
          </div>
          <div className="form">
            {/* <CardForm
              processpayment={processpayment}
              handleSubmit={handleSubmit}
            /> */}

            <button
              onClick={() => handleSubmit()}
              className="cleanbtn purchase-btn font s15 b5 cfff"
            >
              {/* {stripeloading && <Lottie options={defaultOptions} width={30} />}
              {!stripeloading && 'Purchase Credit'} */}
              Send Credit
            </button>
          </div>
        </div>
      </AnimatedDialog>

      <AnimatedDialog
        open={openSendedGiftDialog}
        //onClose={handleCloseGiftDialog}
        fullWidth
        maxWidth="xs"
      >
        <div className="add-category-dialog">
          <div className="hdr flex aic">
            <div className="title font s18 b6 c000"></div>
            <button
              className="cross-btn cleanbtn fontr"
              onClick={() => setopenSendedGiftDialog(false)}
            >
              &times;
            </button>
          </div>
          <div className="wrap flex flex-col">
            <div className="msg font s15 b4 black">
              Congratulation! Gift has been emailed to the user.
            </div>
          </div>
        </div>
      </AnimatedDialog>
    </>
  );
};

export default Rewards;

// RewardsTable Table
function RewardsTable({
  rewardCheck,
  page,
  rewardList,
  setSelectedGiftTemplate,
  selectedgiftTemplate,
  templates,
  setselectedrewardId,
  setGiftAmount,
  setOpenSendGiftConfirmDialog,
  setdialoguestatus,
  managerewardindirectual,
  setReward,
  cancelexpand,
  setgifttopending,
  setselectedrewarddata,
  accesslvl,
  setOpenCreditDialog,
  setfundamount,
  setcommunity_id,
}) {

  function displayTen() {
    const ten = rewardList.filter(
      (review, idx) => idx >= 10 * (page - 1) && idx < 10 * page
    );
    console.log(ten);
    return ten;
  }

  return (
    <div className="reward-table">
      <div className="table">
        <div className={`table-hdr flex aic ${rewardCheck}`}>
          <div className="column">
            <div className="label font">Date request</div>
          </div>
          <div className="column">
            <div className="label font">Community id</div>
          </div>
          <div className="column">
            <div className="label font">Amount</div>
          </div>
          <div className="column">
            <div className="label font">Status</div>
          </div>
          {/* <div className="column">
              <div className="label font">Comments</div>
            </div> */}
        </div>
        {displayTen().map((reward, idx) => {
          let _badges = reward.badges ? JSON.parse(reward.badges) : [];
          return (
            <CompletedRow
              key={idx}
              idx={idx}
              // date={review.created_date.substring(
              //   0,
              //   review.created_date.indexOf(':') - 2
              // )}
              selectedgiftTemplate={selectedgiftTemplate}
              setSelectedGiftTemplate={setSelectedGiftTemplate}
              templates={templates}
              reward={reward}
              setselectedrewardId={setselectedrewardId}
              setGiftAmount={setGiftAmount}
              setOpenSendGiftConfirmDialog={setOpenSendGiftConfirmDialog}
              setdialoguestatus={setdialoguestatus}
              managerewardindirectual={managerewardindirectual}
              setReward={setReward}
              cancelexpand={cancelexpand}
              setgifttopending={setgifttopending}
              setselectedrewarddata={setselectedrewarddata}
              rewardCheck={rewardCheck}
              accesslvl={accesslvl}
              setOpenCreditDialog={setOpenCreditDialog}
              setfundamount={setfundamount}
              setcommunity_id={setcommunity_id}
            />
          );
        })}
        {/* {reviews.length === 0 && <NoRows />} */}
      </div>
    </div>
  );
}

// Row for each table entry
function CompletedRow({
  idx,
  comments,
  reward,
  managerewardindirectual,
  setReward,
  cancelexpand,
  rewardCheck,
  accesslvl,
  setOpenCreditDialog,
  setfundamount,
  setcommunity_id,
}) {
  // Expand or not
  const [expand, setExpand] = useState(false);
  const [loading, setloading] = useState(true);
  const [giftstatus, setgiftstatus] = useState('');
  //console.log('reward',reward)
  // Constrain comments to 200 characters
  const parsedreward = JSON.parse(reward.data);
  const [showUserEmojis, setUserEmojis] = useState(false);
  //6/3/2022 , 23:34:34
  const getstripechagestatus = async () => {
    try {
      const res = await axios.post(
        `https://api.stripe.com/v1/charges/${parsedreward.stripeChargeId}`,
        {},
        {
          headers: {
            Authorization:
              'Bearer sk_test_51K5VDmCgQX5AncW3KeM1ThhJk92tTxU6wJAd4Rb72wt8WZGpOE0Gl1k4s0etiyccH7oxBnr9moVangnsDGZ8Sw0500Gaz9tZNx',
          },
        }
      );
      console.log('getstripechagestatus', res);
      if (res?.data?.paid) {
        setgiftstatus('paid');
        setloading(false);
      }
    } catch (error) {
      console.log('errrrr', error);
    }
  };
  useEffect(() => {
    getstripechagestatus();
  }, []);
  console.log('rewardCheck', reward);
  return (
    <>
      <div className="table-row flex aic" onClick={() => setExpand(!expand)}>
        <div className="column">
          <div className="txt font">
            {moment(parsedreward.funding_request_date).format(
              'MMMM Do YYYY, h:mm:ss a'
            )}
          </div>
        </div>
        <div
          className="column"
          style={{ cursor: 'pointer' }}
          onMouseEnter={(e) => {
            setUserEmojis(true);
          }}
          onMouseLeave={(e) => {
            setUserEmojis(false);
          }}
        >
          {reward.src_community_id}
        </div>
        <div className="column">
          <div style={{ display: 'inline-block' }}>{parsedreward.cost}</div>
        </div>
        {loading ? (
          <div className="column">
            <div style={{ display: 'inline-block' }}>Loading</div>
          </div>
        ) : (
          <div className="column">
            <div style={{ display: 'inline-block' }}>{giftstatus}</div>
          </div>
        )}
      </div>
      {expand && (
        <>
          <ExpandedComments
            idx={idx}
            parsedreward={parsedreward}
            rewardId={reward.id}
            managerewardindirectual={managerewardindirectual}
            setReward={setReward}
            reward={reward}
            community_id={reward.src_community_id}
            accesslvl={accesslvl}
            setOpenCreditDialog={setOpenCreditDialog}
            setfundamount={setfundamount}
            setcommunity_id={setcommunity_id}
          />
        </>
      )}
    </>
  );
}

// Expanded comments section
function ExpandedComments({
  idx,
  parsedreward,
  rewardId,
  managerewardindirectual,
  reward,
  accesslvl,
  setOpenCreditDialog,
  setfundamount,
  setcommunity_id,
  community_id,
  setReward,
}) {
  const [cost, setcost] = useState(parsedreward.cost);

  console.log('community_id', community_id);
  return (
    <div className="expanded-reward-request flex flex-col">
      {accesslvl == ACCESS_LEVEL['global'] && (
        <div className="actions flex aic">
          <button
            onClick={async () => {
              const res = await managerewardindirectual({
                id: rewardId,
                rewardId: 'deleted',
              });
              if (res?.data?.status == 'OK') {
                toast('Deleted successfully');
              }
            }}
            className="action del-btn font"
          >
            Delete
          </button>
          <button
            onClick={() => {
              console.log('parsed.cost', Number(parsedreward.cost));
              setReward(reward);
              setOpenCreditDialog(true);
              setfundamount(Number(parsedreward.cost));
              setcommunity_id(community_id);

              //startAnimation()
            }}
            className="action approve-btn font"
          >
            Send Funds
          </button>
        </div>
      )}
    </div>
  );
}
