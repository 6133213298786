import { Grid as MuiGrid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Chip from '@material-ui/core/Chip';
import CircularProgress from '@material-ui/core/CircularProgress';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import InputBase from '@material-ui/core/InputBase';
import Paper from '@material-ui/core/Paper';
import Portal from '@material-ui/core/Portal';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import AddIcon from '@material-ui/icons/Add';
import CloseIcon from '@material-ui/icons/Close';
import EmailIcon from '@material-ui/icons/Email';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import GetAppIcon from '@material-ui/icons/GetApp';
import ShareIcon from '@material-ui/icons/Share';
import Alert from '@material-ui/lab/Alert';
import axios from 'axios';
import 'c3/c3.css';
import html2canvas from 'html2canvas';
import jsPDF from 'jspdf';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useSelector } from 'react-redux';
//import { Dropdown } from 'tabler-react';
//import 'tabler-react/dist/Tabler.css';
import { DonutChart, ReverseBarChart } from '../../components/charts';
import ContentLoader from '../../components/ContentLoader';
import CustomHeader from '../../components/Reusable/CustomHeader';
import { getMagnetData } from '../../store/actions/magnetActions';
import firebase from '../../utils/base';
import { DASHBOARD_HOST, get, post } from '../../utils/request';
import SimpleLeads from '../videoembed/SimpleLeadsTable';
import VideomagnetDetails from '../videoembed/VideomagnetDetails';
// ******************for future table*********************
// function createData(name, calories, fat, carbs, protein) {
//   return { name, calories, fat, carbs, protein };
// }

// const rows = [
//   createData('https://thegeorge.com/', 159, 6.0, 24, 4.0),
//   createData('https://thegeorge.com/1-bedroom', 237, 9.0, 37, 4.3),
//   createData('https://thegeorge.com/floorplans', 262, 16.0, 24, 6.0),
//   createData('https://thegeorge.com/amenities', 305, 3.7, 67, 4.3),
//   createData('https://thegeorge.com/2-bedroom', 356, 16.0, 49, 3.9),
// ];

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

// function that sorts parallel arrays by values in first array
function parallelSort(array1, array2) {
  let temp = Array.from(Array(array1.length).keys());
  temp = temp.sort((firstEL, secondEL) => {
    return array1[secondEL] - array1[firstEL];
  });
  const startArray1 = [...array1];
  const startArray2 = [...array2];
  for (let i = 0; i < temp.length; i++) {
    array1[i] = startArray1[temp[i]];
    array2[i] = startArray2[temp[i]];
  }
}

const MainAnalytics = ({
  name,
  community_id,
  url,
  analytics = true,
  activity = true,
  title,
}) => {
  const [magnetUuid, setMagnetUuid] = useState();
  let { template, magnetLoading } = useSelector((state) => state.getMagnet);
  const [dates, setDates] = useState();
  // share info
  const [shareOpen, setShareOpen] = useState(false);
  const [activityShare, setActivityShare] = useState(true);
  const [analyticsShare, setAnalyticsShare] = useState(true);
  const [copyOpen, setCopyOpen] = useState(false);
  const [downloadInProgress, setDownloadInProgress] = useState(false);
  const [emailShareOpen, setEmailShareOpen] = useState(false);
  const [emails, setEmails] = useState([]);
  const [currEmail, setCurrEmail] = useState();
  const [emailError, setEmailError] = useState();

  // Data from backend
  const [basicData, setBasicData] = useState();
  const [categoryData, setCategoryData] = useState();
  const [liveVisitors, setLiveVisitors] = useState(0);
  const [timeData, setTimeData] = useState();
  const [badges, setBadges] = useState();
  const [change, setChange] = useState();
  const [moddata, setmodedata] = useState();
  const [virtualtourdata, setvirtualtourdata] = useState();
  // Errors
  const [categoryError, setCategoryError] = useState();
  const [basicError, setBasicError] = useState();
  const [timeError, setTimeError] = useState();
  const [badgeError, setBadgeError] = useState();
  const [liveError, setLiveError] = useState();

  // Bar chart
  const [barChartData, setBarChartData] = useState();
  const [barChartSize, setBarChartSize] = useState(10);
  // Dates
  const [lengthSelection, setLengthSelection] = useState(2);
  const selectionOptions = [14, 45, 90, 180, 365, 'all'];
  const classes = useStyles();

  const [iframeurl, setiframeurl] = useState('');

  // Calculate start date and business hours
  let startDate = 'all';
  if (selectionOptions[lengthSelection] !== 'all') {
    const currDate = new Date();
    currDate.setDate(currDate.getDate() - selectionOptions[lengthSelection]);
    startDate =
      currDate.getUTCFullYear() +
      ('0' + (currDate.getUTCMonth() + 1)).slice(-2) +
      ('0' + currDate.getUTCDate()).slice(-2) +
      '-' +
      ('0' + currDate.getUTCHours()).slice(-2) +
      ('0' + currDate.getUTCMinutes()).slice(-2);
  }
  let startBusiness = new Date('2021-05-03T10:00');
  startBusiness =
    ('0' + startBusiness.getUTCHours()).slice(-2) +
    ('0' + startBusiness.getUTCMinutes()).slice(-2);
  let endBusiness = new Date('2021-05-03T16:00');
  endBusiness =
    ('0' + endBusiness.getUTCHours()).slice(-2) +
    ('0' + endBusiness.getUTCMinutes()).slice(-2);
  const timeZoneOffset = Math.floor(new Date().getTimezoneOffset() / 60);

  useEffect(() => {
    //if(!template){
    async () => {
      getMagnetData(community_id);
    };

    //}
    getMagnet().then((res) => {
      if (res) {
        //getBadgeData(res);
        //getBasicData(res);
        //getTimeData(res);
        //getCategoryData(res);
      }
    });
  }, [community_id]);

  // Update basic and category when we change the number of days we are looking at
  // useEffect(() => {
  //   if (magnetUuid) {
  //     //getBasicData();
  //     getCategoryData();
  //     //getTimeData();
  //   }
  // }, [lengthSelection]);

  const [analyticsApiKey, setAnalyticsApiKey] = useState('');
  const getMagnet = async () => {
    const res = await get(`/magnets?queryByCommunity=${community_id}`, {
      auth: false,
    });
    console.log('resssss1', res);
    setMagnetUuid(res.magnets[0].uuid);
    getmetabaseiframe(res.magnets[0].uuid);
    //get90daysanalyicsbigquery();
    count_details_from_rows(
      res.magnets[0].uuid,
      res?.magnets[0]?.magnet_details?.template
    );
    setAnalyticsApiKey(
      res.magnets[0]?.magnet_details?.template?.magnetSettings?.analyticsApiKey
    );
    return res.magnets[0].uuid;
  };

  const getBasicData = async (inData) => {
    //const getBasicData = async (uuid = magnetUuid) => {
    // const inData = await get(
    //   `/analytics/basic?tours&leads&views&leased&magnet_uuid=${uuid}&start=${startDate}&business_start=${startBusiness}&business_end=${endBusiness}`,
    //   { auth: false }
    // );
    if (!inData.error) {
      inData.tourstotal = Math.round(
        inData?.tours?.reduce((result, number) => result + number)
      );
      inData.watchtimetotal = Math.round(
        inData?.watchtime?.reduce((result, number) => result + number)
      );
      inData.viewstotal = Math.round(
        inData?.views?.reduce((result, number) => result + number)
      );
      inData.leadstotal = Math.round(
        inData?.leads?.reduce((result, number) => result + number)
      );
      //console.log('inData....', inData);
      setBasicData(inData);

      if (!change) {
        setChangeData(inData);
      }
      const end = new Date(inData.end);
      const start = new Date(inData.start);
      const interval = (end.getTime() - start.getTime()) / inData.tours.length;
      const time = start;
      const tempDates = [];
      for (let i = 0; i < inData.tours.length - 1; i += 1) {
        tempDates.push(moment(time).format('YYYY-MM-DD'));
        time.setTime(time.getTime() + interval);
      }
      setDates(tempDates);
    } else {
      setBasicError(
        'Unable to display data at this time. If this issue persists please contact us.'
      );
    }
  };

  const setChangeData = (inData) => {
    //const thisWeekViews = inData.tours?.activity
    const thisWeekViews = inData.tours
      .slice(inData.tours.length - 7, inData.tours.length)
      .reduce((a, b) => a + b, 0);
    const lastWeekViews = inData.tours
      .slice(inData.tours.length - 14, inData.tours.length - 7)
      .reduce((a, b) => a + b, 0);
    setChange({
      percent: (thisWeekViews / (lastWeekViews || 1)) * 100,
      number: thisWeekViews - lastWeekViews,
    });
  };

  const getBadgeData = async (uuid = magnetUuid) => {
    const inDataBadges = await get(
      `/analytics/summary_badges?magnet_uuid=${uuid}&business_start=${startBusiness}&business_end=${endBusiness}&start=${startDate}`,
      { auth: false }
    );
    if (!inDataBadges.error) setBadges(inDataBadges);
    else {
      setBadgeError(
        'Unable to get badge data at this time. If this issue persists please contact us.'
      );
    }
    const inData = await get(`/analytics/live_visitors?magnet_uuid=${uuid}`, {
      auth: false,
    });
    if (!inData.error) setLiveVisitors(inData?.total);
    else
      setLiveError(
        'Unable to get live data at this time. If this issue persists please contact us.'
      );
  };

  const getmetabaseiframe = async (magnetuuid) => {
    try {
      const { data } = await axios.post(
        `https://api.leasemagnets.com/get_metabase_iframeurl_byuuid`,
        {
          dashboard_id: 10,
          dashboard_params: { magnet_uuid: [magnetuuid] },
        }
      );
      console.log('got data', data);
      setiframeurl(data.res);
    } catch (error) {
      console.log('errror', error);
    }
  };

  const count_details_from_rows = async (magnetuuid, template) => {
    try {
      const { data } = await axios.post(
        `https://api.leasemagnets.com/count_details_from_rows`,
        {
          magnet_uuid: magnetuuid,
        }
      );
      let categoryNames = [];
      let categoryTotals = [];
      let screenobj = {};
      let categoryScreenNames = [];
      let categoryScreenTotals = [];
      let allScreenNames = [];
      let allScreenTotals = [];
      if (data?.res) {
        data?.res?.map((item) => {
          if (
            template?.categories[item[0]?.split('.')[0]]?.screens[
              item[0]?.split('.')[1]
            ]?.title &&
            template?.categories[item[0]?.split('.')[0]]?.title
          ) {
            categoryScreenNames.push([
              template?.categories[item[0].split('.')[0]]?.screens[
                item[0].split('.')[1]
              ]?.title,
            ]);
            categoryScreenTotals.push([item[1]]);
            allScreenNames.push(
              `${
                template?.categories[item[0].split('.')[0]]?.screens[
                  item[0].split('.')[1]
                ]?.title
              } -  ${template?.categories[item[0].split('.')[0]]?.title}`
            );
            allScreenTotals.push(item[1]);
            if (screenobj[item[0].split('.')[0]]) {
              screenobj[item[0].split('.')[0]] =
                screenobj[item[0].split('.')[0]] + item[1];
            } else {
              screenobj[item[0].split('.')[0]] = item[1];
            }
          }
        });
        Object.keys(screenobj).map((item) => {
          categoryNames.push(template?.categories[item]?.title);
          categoryTotals.push(screenobj[item]);
        });
      }
      let templatecategorynames = [];
      categoryNames.map((item) => {
        templatecategorynames.push(template?.categories[item]?.title);
      });
      let tempcategory = [];
      let tempcategoryScreenNames = [];
      let tempcategoryScreenTotals = [];
      allScreenNames.map((item, index) => {
        if (tempcategory.includes(item?.split(' - ')[1])) {
          let theindex = tempcategory.findIndex(
            (item2) => item2 == item?.split(' - ')[1]
          );
          tempcategoryScreenNames[theindex]?.push(item?.split(' - ')[0]);
          tempcategoryScreenTotals[theindex]?.push(allScreenTotals[index]);
        } else {
          tempcategory.push(item?.split(' - ')[1]);
          tempcategoryScreenNames.push([item?.split(' - ')[0]]);
          tempcategoryScreenTotals.push([allScreenTotals[index]]);
        }
      });
      setBarChartData({
        title: 'All Screens',
        titles: allScreenNames,
        data: allScreenTotals,
      });
      setCategoryData({
        categoryNames,
        categoryTotals,
        allScreenNames,
        allScreenTotals,
        categoryScreenNames: tempcategoryScreenNames,
        categoryScreenTotals: tempcategoryScreenTotals,
      });
    } catch (error) {
      console.log('errror', error);
    }
  };
  //console.log('setCategoryData', categoryData);
  const getCategoryData = async (uuid = magnetUuid) => {
    console.log('calleddd');
    // const inData2 = await get(
    //   `/analytics/basic?tours&leads&views&leased&magnet_uuid=${uuid}&start=${startDate}&business_start=${startBusiness}&business_end=${endBusiness}`,
    //   { auth: false }
    // );
    console.log('lengthSelection', selectionOptions[lengthSelection]);
    console.log('uuid', uuid);
    const currDate = new Date();
    const end = new Date();
    end.setDate(end.getDate() + 1);
    if (selectionOptions[lengthSelection] != 'all') {
      //currDate.setDate(currDate.getDate() - selectionOptions[lengthSelection]);
      currDate.setDate(
        currDate.getDate() - selectionOptions[lengthSelection] - 1
      );
    }
    // const InDataTime = await get(
    //   `/custom_event?magnet_uuid=${uuid}&selectedlength=${selectionOptions[lengthSelection]}&start=${currDate
    //     .toISOString()
    //     .replace('Z', '')}&end=${new Date().toISOString().replace('Z', '')}`
    // );
    const authToken = await firebase.user().getIdToken(true);
    console.log('authtoken', authToken);
    let InDataTime = {};
    try {
      const { data } = await axios.get(
        //refreshcache=true&
        //`http://localhost:8080/custom_event?refreshcache=true&magnet_uuid=${uuid}&selectedlength=${
        `https://api.leasemagnets.com/custom_event?magnet_uuid=${uuid}&selectedlength=${
          selectionOptions[lengthSelection]
        }&start=${currDate.toISOString().replace('Z', '')}&end=${end
          .toISOString()
          .replace('Z', '')}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: authToken,
          },
        }
      );
      console.log('test_data', data);
      InDataTime = data;
    } catch (error) {
      console.log('test_data_error', error);
      console.log('test_data_error', error.response);
    }

    if (InDataTime.response?.hourly_count) {
      setTimeData({
        time: InDataTime.response?.hourly_count,
        day: InDataTime.response?.weekday_count,
      });
    }
    if (!InDataTime?.response?.response?.category) {
      return;
    }
    if (InDataTime?.response?.interval_count) {
      getBasicData(InDataTime.response.interval_count);
    }
    // let tours = [...InDataTime.response.interval_count.tours]
    // let views = [...InDataTime.response.interval_count.views]
    // let leads = [...InDataTime.response.interval_count.leads]
    // let watchtime = [...InDataTime.response.interval_count.watchtime]

    // tours.pop()
    // views.pop()
    // leads.pop()
    // watchtime.pop()
    //tour.pop()
    // InDataTime.response.interval_count.tours = tours
    // InDataTime.response.interval_count.views = views
    // InDataTime.response.interval_count.leads = leads
    // InDataTime.response.interval_count.watchtime = watchtime
    console.log(
      'InDataTime.response.interval_count',
      InDataTime.response.interval_count
    );
    setmodedata(InDataTime.response.interval_count);
    // had to convert the data into proper format
    let all_categoires = InDataTime.response.response.category;
    const category_keys = Object.keys(all_categoires);
    const screen_keys = [];
    category_keys.map((item) => {
      screen_keys.push(Object.keys(all_categoires[item]));
    });
    let obj = {};
    category_keys.map((item, i) => {
      obj[item] = {
        screens: {},
        screen_keys: screen_keys[i],
        title: all_categoires[item]['title'],
      };
      screen_keys[i].map((item2) => {
        if (item2 != 'title') {
          obj[item]['screens'][item2] = {
            title: all_categoires[item][item2]['title'],
            total: all_categoires[item][item2]['count'],
            video: all_categoires[item][item2]['video'],
          };
        }
      });
    });
    let inData = {
      categories: obj,
      category_keys,
    };

  
    if (!inData.error) {
      setCategoryError(false);
      const localCategoryTotals = [];
      const localCategoryNames = [];
      const localAllScreenNames = [];
      const localAllAcreenTotals = [];
      const localCategoryScreenNames = [];
      const localCategoryScreenTotals = [];

      inData.category_keys.map((category_key) => {
        localCategoryNames.push(inData.categories[category_key].title);
        let categoryTotal = 0;

        const tempScreenNames = [];
        const tempScreenValues = [];

        inData.categories[category_key].screen_keys.forEach((screen_key) => {
          const screen = inData.categories[category_key].screens[screen_key];
          console.log('screen_total', screen);
          if (screen?.total) {
            categoryTotal += screen.total;

            localAllScreenNames.push(
              screen.title + ' - ' + inData.categories[category_key].title
            );
            localAllAcreenTotals.push(screen.total);

            tempScreenNames.push(screen.title);
            tempScreenValues.push(screen.total);
          }
        });

        console.log('parallelSort', {
          tempScreenValues,
          tempScreenNames,
        });
        // Orders the screens within each category
        parallelSort(tempScreenValues, tempScreenNames);
        localCategoryScreenNames.push(tempScreenNames);
        localCategoryScreenTotals.push(tempScreenValues);

        localCategoryTotals.push(categoryTotal);
      });

      // Orders all screens
      parallelSort(localAllAcreenTotals, localAllScreenNames);

      console.log('dbcategory', {
        categoryNames: localCategoryNames,
        categoryTotals: localCategoryTotals,
        allScreenNames: localAllScreenNames,
        allScreenTotals: localAllAcreenTotals,
        categoryScreenNames: localCategoryScreenNames,
        categoryScreenTotals: localCategoryScreenTotals,
      });

      setCategoryData({
        categoryNames: localCategoryNames,
        categoryTotals: localCategoryTotals,
        allScreenNames: localAllScreenNames,
        allScreenTotals: localAllAcreenTotals,
        categoryScreenNames: localCategoryScreenNames,
        categoryScreenTotals: localCategoryScreenTotals,
      });

      setBarChartData({
        title: 'All Screens',
        titles: localAllScreenNames,
        data: localAllAcreenTotals,
      });
    } else {
      setCategoryError(
        'Unable to get category data at this time. If this issue persists please contact us.'
      );
    }
  };

  function handleCategorySelection(selection) {
    if (selection[0][0] === undefined) {
      setBarChartData({
        title: 'All Screens',
        titles: categoryData.allScreenNames,
        data: categoryData.allScreenTotals,
      });
    } else {
      setBarChartData({
        title: categoryData.categoryNames[selection],
        titles: categoryData.categoryScreenNames[selection],
        data: categoryData.categoryScreenTotals[selection],
      });
    }
  }

  const emailRef = useRef();
  function handleSubmitEmail(email = currEmail) {
    const re = /^[^\s@]+@[^\s@]+$/;
    if (emails.includes(email.toLowerCase())) {
      setEmailError('Email already entered');
    } else if (re.test(email)) {
      setEmailError();
      const temp = emails;
      temp.push(email.toLowerCase());
      setEmails([...temp]);
      setCurrEmail('');
      emailRef.current.value = '';
    } else {
      setEmailError('Please enter a valid email address');
    }
  }
  const TimeframeDropdown = function () {
    return (
      <Dropdown
        //triggerAs={El.A}
        triggerContent="Last 7 days"
        triggerProps={{ className: 'text-muted-light', href: '#' }}
        position="bottom-end"
        arrowPosition="right"
        itemsObject={[
          { value: 'Last 7 days' },
          { value: 'Last 30 days' },
          { value: 'Last 3 months' },
        ]}
      />
    );
  };

  function printDocument(inDiv) {
    // https://stackoverflow.com/questions/44989119/generating-a-pdf-file-from-react-components
    // may be needed later https://stackoverflow.com/questions/27045704/how-do-i-get-a-multi-page-pdf-from-a-website-using-jspdf-and-html2canvas
    setDownloadInProgress(true);
    const input = document.getElementById(inDiv);
    html2canvas(input)
      .then((canvas) => {
        const imgData = canvas.toDataURL('image/png');
        const pdf = new jsPDF('p', 'pt', 'a4', false);
        pdf.addImage(imgData, 'PNG', 20, 20, 560, 0, undefined, false);
        // pdf.output('dataurlnewwindow');
        pdf.save(`${name}_tour_video_Analytics.pdf`);
      })
      .then(() => setDownloadInProgress(false));
  }

  const AnalyticsItem = ({ analyticsRange = '30' }) => {
    let range = parseInt(analyticsRange);
    let lastDays = 0;
    let watches = 0;
    let leads = 0;
    let leased = 0;

    {
      /* Tour Deliver */
    }
    console.log('basicData', basicData);
    for (let i = range === 30 ? 8 : range === 60 ? 4 : 0; i <= 12; i++) {
      lastDays = basicData?.tours[i] + lastDays;
      watches = basicData?.views[i] + watches;
      leads = basicData?.leads[i] + leads;
      //leased = basicData?.leased[i] + leased;
    }

    return (
      <>
        <MuiGrid item container xs={12} sm={12} md={4} lg={4}>
          <MuiGrid item xs={12} sm={6} md={12} lg={12}>
            <div className="font s16 analytics-range-header">{`Last  ${analyticsRange} days`}</div>
            <ContentLoader error={basicError && 'unable to display'}>
              <div className="state-item flex aic">
                <div className="flex aic ico icon-video s20" />
                <div className="flex flex-col">
                  <div className="lbl font s16 c333">
                    <span className="s18 b6">{`${lastDays}`}</span>
                    &nbsp;
                    {`Tour${basicData?.viewstotal !== 1 && 's'} delivered`}
                  </div>
                  {/* <div className="txt font s13 c999">
                    {Math.round(watches) + ' Seconds Watched'}
                  </div> */}
                </div>
              </div>
            </ContentLoader>
          </MuiGrid>

          {/* <MuiGrid item xs={12} sm={6} md={12} lg={12}>
                    <ContentLoader error={basicError && 'unable to display'}>
                      <div className="state-item flex aic">
                        <div className="flex aic ico icon-eye s20" />
                        <div className="flex flex-col">
                          <div className="lbl font s16 c333">
                            <span className="s18 b6">
                              {change?.number >= 0 ? '+' : '-'}&nbsp;
                              {Math.round(change?.percent)}%
                            </span>
                            &nbsp;
                            {`${
                              change?.number >= 0 ? 'Increase' : 'Decrease'
                            } in Tours`}
                          </div>
                          <div className="txt font s13 c999">
                            {`${change?.number || 'NaN'} ${
                              change?.number >= 0 ? 'More' : 'Less'
                            } Tours this Week`}
                          </div>
                        </div>
                      </div>
                    </ContentLoader>
                  </MuiGrid> */}

          <MuiGrid item xs={12} sm={6} md={12} lg={12}>
            <ContentLoader error={basicError && 'unable to display'}>
              <div className="state-item flex aic">
                <div className="flex aic ico icon-users s20" />
                <div className="flex flex-col">
                  <div className="lbl font s16 c333">
                    <span className="s18 b6">{leads}</span>
                    &nbsp; Lead
                    {basicData?.leadstotal !== 1 ? 's' : ''}
                  </div>
                  <div className="txt font s13 c999"></div>
                </div>
              </div>
            </ContentLoader>
          </MuiGrid>
          <MuiGrid item xs={12} sm={6} md={12} lg={12}>
            <ContentLoader error={liveError}>
              <div className="state-item flex aic">
                <div className="flex aic ico icon-send s20" />
                <div className="flex flex-col">
                  <div className="lbl font s16 c333">
                    <span className="s18 b6"> {leased}</span>
                    {` Lease${basicData?.leased?.total !== 1 ? 's' : ''}`}
                  </div>
                  <div className="txt font s13 c999"></div>
                </div>
              </div>
            </ContentLoader>
          </MuiGrid>
        </MuiGrid>
      </>
    );
  };

  if (lengthSelection === undefined || !magnetUuid) {
    return (
      <div className="analytics-page flex flex-col">
        <div className="tit holder" />
        <div className="duration-drop holder" />
        <div className="analytic-wrap flex">
          <div className="graph holder" />
          <div className="summary flex flex-col">
            <div className="summary-item holder" />
            <div className="summary-item holder" />
            <div className="summary-item holder" />
            <div className="summary-item holder" />
          </div>
        </div>
        <div className="analytic-wrap flex">
          <div className="graph holder" />
          <div className="graph holder" />
        </div>
      </div>
    );
  }

  // Calculate the number and percent of tours in and outside of business hours
  // const outside_b_percent = Math.round(
  //   (100 *
  //     basicData?.tours.business_hours.reduce(
  //       (result, number) =>
  //         (result.outside !== undefined ? result.outside : result) +
  //         number.outside
  //     )) /
  //     basicData?.tours.business_hours.reduce(
  //       (result, number) =>
  //         (result.outside !== undefined
  //           ? result.outside + result.inside
  //           : result) +
  //         number.outside +
  //         number.inside
  //     ) || 0
  // );

  if (analyticsApiKey)
    return <VideomagnetDetails key_value={analyticsApiKey} name={name} />;

  return (
    <>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      <CustomHeader
        url={url}
        name={name}
        community_id={community_id}
        title="Tracking & Analytics"
      />

      {/* <div className=" mt-1 w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 p-2">
        <div className=" p-2 flex items-center flex-col justify-center w-full h-40 border rounded-md bg-white">
          <h1 className=" text-3xl font-bold text-gray-600">78</h1>
          <p className=" text-gray-400">Tours Count</p>
        </div>
      </div> */}
      <iframe
        width="100%"
        height={800}
        frameBorder="0"
        src={iframeurl}
        allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
      />
      <div id="MainAnalytics" className="MainAnalytics tour-magnet-analytics-p">
        <div className="flex flex-col">
          <div id="printableArea">
            {
              <Box>
                <IconButton
                  onClick={() => {
                    setDownloadInProgress(true);
                    printDocument('printableArea');
                  }}
                >
                  <GetAppIcon />
                </IconButton>
                <IconButton onClick={() => setEmailShareOpen(true)}>
                  <EmailIcon />
                </IconButton>
                <IconButton onClick={() => setShareOpen(!shareOpen)}>
                  <ShareIcon />
                </IconButton>

                <Dialog
                  open={emailShareOpen}
                  onClose={() => {
                    setEmailShareOpen(false);
                    setEmails([]);
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {'Send Weekly Email'}
                  </DialogTitle>
                  <DialogContent>
                    <MuiGrid
                      container
                      direction="row"
                      justify="center"
                      alignItems="flex-start"
                      spacing={1}
                    >
                      <MuiGrid item xs={12}>
                        Add emails to receive {name}'s weekly analytics summary:
                        <br />
                        {emails.map((email, idx) => (
                          <Chip
                            color="primary"
                            style={{ marginLeft: 5, marginTop: 5 }}
                            label={email}
                            onDelete={() => {
                              let temp = emails;
                              temp = temp.filter((val, i) => i !== idx);
                              setEmails(temp);
                            }}
                          />
                        ))}
                      </MuiGrid>
                      <MuiGrid item xs={12}>
                        <Paper component="form" className={classes.root}>
                          <InputBase
                            className={classes.input}
                            placeholder="Email"
                            inputRef={emailRef}
                            onChange={(e) => {
                              setCurrEmail(e.target.value);
                            }}
                            onKeyDown={(e) => {
                              if (e.key === 'Enter') {
                                e.preventDefault();
                                handleSubmitEmail(e.target.value);
                              }
                            }}
                            inputProps={{ 'aria-label': 'Add email' }}
                          />
                          <Divider
                            className={classes.divider}
                            orientation="vertical"
                          />
                          <IconButton
                            color="primary"
                            className={classes.iconButton}
                            onClick={() => {
                              handleSubmitEmail();
                            }}
                            aria-label="directions"
                          >
                            <AddIcon />
                          </IconButton>
                        </Paper>
                        {emailError && (
                          <Alert severity="error">{emailError}</Alert>
                        )}
                      </MuiGrid>
                    </MuiGrid>
                  </DialogContent>
                  <DialogActions>
                    <Button
                      onClick={() => {
                        setEmailShareOpen(false);
                        setEmails([]);
                      }}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      onClick={() => {
                        setEmailShareOpen(false);
                        if (emails) {
                          post(
                            `/email/analytics`,
                            {
                              uuid: magnetUuid,
                              name: name,
                              cid: community_id,
                              target: emails.join(','),
                            },
                            { auth: false }
                          );
                        }
                        setEmails([]);
                      }}
                      color="primary"
                    >
                      Send
                    </Button>
                  </DialogActions>
                </Dialog>

                <Dialog
                  open={shareOpen}
                  onClose={() => {
                    setShareOpen(false);
                    setActivityShare(true);
                    setAnalyticsShare(true);
                  }}
                  aria-labelledby="alert-dialog-title"
                  aria-describedby="alert-dialog-description"
                >
                  <DialogTitle id="alert-dialog-title">
                    {'Share Link'}
                  </DialogTitle>
                  <DialogContent>
                    <MuiGrid
                      container
                      direction="row"
                      justify="center"
                      alignItems="flex-start"
                      spacing={1}
                    >
                      <MuiGrid item xs={12}>
                        Share this link with others to grant access to {name}'s:
                      </MuiGrid>
                      <MuiGrid item xs={12}>
                        <Chip
                          color={activityShare ? 'primary' : 'default'}
                          clickable
                          label="Activity"
                          onClick={() => {
                            setActivityShare((prev) => !prev);
                          }}
                        />
                        <Chip
                          color={analyticsShare ? 'primary' : 'default'}
                          clickable
                          label="Analytics"
                          onClick={() => {
                            setAnalyticsShare((prev) => !prev);
                          }}
                        />
                      </MuiGrid>
                      <MuiGrid item xs={12}>
                        <Paper component="form" className={classes.root}>
                          <InputBase
                            className={classes.input}
                            value={
                              analyticsShare || activityShare
                                ? DASHBOARD_HOST +
                                  '/share/analytics?' +
                                  (analyticsShare ? 'analytics=true' : '') +
                                  (analyticsShare && activityShare ? '&' : '') +
                                  (activityShare ? 'activity=true' : '') +
                                  '&id=' +
                                  community_id +
                                  '&name=' +
                                  name
                                : ''
                            }
                            readOnly
                            inputProps={{ 'aria-label': 'Shareable URL' }}
                          />
                          <Divider
                            className={classes.divider}
                            orientation="vertical"
                          />
                          <Tooltip
                            open={copyOpen}
                            disableFocusListener
                            disableHoverListener
                            disableTouchListener
                            leaveTouchDelay={60}
                            title="Copied to clipboard"
                          >
                            <IconButton
                              color="primary"
                              className={classes.iconButton}
                              aria-label="directions"
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  analyticsShare || activityShare
                                    ? DASHBOARD_HOST +
                                        '/share/analytics?' +
                                        (analyticsShare
                                          ? 'analytics=true'
                                          : '') +
                                        (analyticsShare && activityShare
                                          ? '&'
                                          : '') +
                                        (activityShare ? 'activity=true' : '') +
                                        '&id=' +
                                        community_id +
                                        '&name=' +
                                        name
                                    : ''
                                );
                                setCopyOpen(true);
                                setTimeout(() => setCopyOpen(false), 4000);
                              }}
                            >
                              <FileCopyIcon />
                            </IconButton>
                          </Tooltip>
                        </Paper>
                      </MuiGrid>
                    </MuiGrid>
                  </DialogContent>
                  <DialogActions>
                    <Button onClick={() => setShareOpen(false)} color="primary">
                      Done
                    </Button>
                  </DialogActions>
                </Dialog>
                <Portal>
                  <Snackbar
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'left',
                    }}
                    open={downloadInProgress}
                    message="Download in progress"
                    action={
                      <React.Fragment>
                        <CircularProgress />
                        <IconButton
                          size="small"
                          aria-label="close"
                          color="inherit"
                          onClick={() => setDownloadInProgress(false)}
                        >
                          <CloseIcon fontSize="small" />
                        </IconButton>
                      </React.Fragment>
                    }
                  />
                </Portal>
              </Box>
            }

            <div className="page-content flex flex-col">
              <MuiGrid item xs={12} sm={12} md={barChartSize > 10 ? 12 : 6}>
                <div className="Card">
                  <Box m={1}>
                    <ContentLoader error={categoryError}>
                      {categoryData ? (
                        <DonutChart
                          title="All Categories"
                          labels={categoryData.categoryNames}
                          data={categoryData.categoryTotals}
                          selected={handleCategorySelection}
                        />
                      ) : (
                        <div className="graph-loading holder" />
                      )}
                    </ContentLoader>
                  </Box>
                </div>
              </MuiGrid>
              <MuiGrid item xs={12} sm={12} md={barChartSize > 10 ? 12 : 6}>
                <div className="Card">
                  <Box m={1}>
                    <ContentLoader error={categoryError}>
                      {barChartData ? (
                        <ReverseBarChart
                          title={barChartData.title}
                          categories={barChartData.titles.slice(
                            0,
                            barChartSize
                          )}
                          data={barChartData.data.slice(0, barChartSize)}
                          size={barChartSize}
                          customIcons={[
                            {
                              icon:
                                barChartSize === 10
                                  ? '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/></svg>'
                                  : '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z"/></svg>',
                              index: 0,
                              title:
                                barChartSize === 10
                                  ? 'View all screens'
                                  : 'View less screens',
                              class: 'custom-icon',
                              click: function (chart, options, e) {
                                if (
                                  barChartSize === 10 &&
                                  barChartData.data.length > 10
                                ) {
                                  setBarChartSize(barChartData.data.length);
                                } else if (barChartSize === 10) {
                                  setBarChartSize(15);
                                } else {
                                  setBarChartSize(10);
                                }
                              },
                            },
                          ]}
                        />
                      ) : (
                        <div className="graph-loading holder" />
                      )}
                    </ContentLoader>
                  </Box>
                </div>
              </MuiGrid>
              {/* {analytics && (
              <MuiGrid item xs={12} sm={12} md={12} lg={12}>
                <div
                  className="Card"
                  style={{ border: 'none', boxShadow: 'none' }}
                  title=""
                >
                  <ContentLoader error={timeError}>
                    {timeData ? (
                      <>
                        <div className="community-name font s24 b6 c333">
                          Average virtual tour traffic at {name}
                        </div>
                        // commented
                        <div className="live-visitor">
                          <span className="circle"></span>
                          <div className="txt font s13 c999 text-block">
                            {liveVisitors +
                              ` Visitor${
                                liveVisitors !== 1 ? 's' : ''
                              } in the Last 2 Hours`}{' '}
                            | {outside_b_percent} % outside business hours
                          </div>
                        </div>
                        // commented

                        <SplineAreaChart
                          // .slice and .concat below makes it so that we are able to get the data in the correct timezone.
                          // timeData is from midnight - midnight in UTC time. In onder to get the local time midnight - midnight we must remove the first timeZoneOffset and add it to the end
                          dataAndLabels={[
                            ['Tours', 'tours'],
                            ['Views', 'views'],
                            ['Leads', 'leads'],
                            ['Leases', 'leased'],
                          ].map((label) => {
                            return {
                              name: label[0],
                              data: timeData?.time[label[1]]
                                ?.slice(timeZoneOffset, 24)
                                ?.concat(
                                  timeData?.time[label[1]]?.slice(
                                    0,
                                    timeZoneOffset
                                  )
                                ),
                            };
                          })}
                          height={300}
                          id="TourOnlyArea"
                          toursOnly={true}
                          xAxisData={[...Array(24).keys()].map((num) =>
                            new Date(0, 0, 0, num).toLocaleTimeString([], {
                              hour: 'numeric',
                            })
                          )}
                        />
                      </>
                    ) : (
                      <div className="graph-loading holder" />
                    )}
                  </ContentLoader>
                </div>
              </MuiGrid>
            )} */}

              {/* {analytics && (
              <MuiGrid spacing={2} container>
                <AnalyticsItem analyticsRange={'90'} />
                <AnalyticsItem analyticsRange={'60'} />
                <AnalyticsItem analyticsRange={'30'} />
              </MuiGrid>
            )} */}

              {magnetUuid && activity && (
                <SimpleLeads magnet_uuid={magnetUuid} name={name} />
              )}

              {/* {activity ? (
              <Leads
                magnet_uuid={magnetUuid}
                start={startDate}
                startBusiness={startBusiness}
                endBusiness={endBusiness}
                name={name}
                timeSelection={lengthSelection}
              />
            ) : (
              <div className="graph-loading holder" />
            )} */}

              {/* <FormControl fullWidth>
              <Select
                value={lengthSelection}
                onChange={(event) => setLengthSelection(event.target.value)}
              >
                <MenuItem value={0}>Last 14 Days</MenuItem>
                <MenuItem value={1}>Last 45 Days</MenuItem>
                <MenuItem value={2}>Last 90 Days</MenuItem>
                <MenuItem value={3}>Last 180 Days</MenuItem>
                <MenuItem value={4}>Last 1 Year</MenuItem>
                <MenuItem value={5}>All Time</MenuItem>
              </Select>
            </FormControl>
            <br />
            <br />
            {analytics && (
              <MuiGrid spacing={2} container>
                <MuiGrid item xs={12} sm={12} md={8} lg={8}>
                  <div
                    className="Card"
                    boxShadow={0}
                    style={{ border: 'none', boxShadow: 'none' }}
                    title=""
                  >
                    <ContentLoader error={basicError}>
                      {moddata && dates ? (
                        <>
                          <br />
                          <center>
                            <h1>{basicData.tourstotal}</h1>
                            <span style={{ marginBottom: '-20px' }}>
                              Virtual Tours
                            </span>
                          </center>
                          <SplineAreaChart
                            dataAndLabels={[
                              { name: 'Tours', data: moddata.tours },
                              { name: 'Views', data: moddata.views },
                              { name: 'Leads', data: moddata.leads },
                              {
                                name: 'Leases',
                                data: moddata?.leased,
                              },
                            ]}
                            xAxisData={dates}
                          />
                        </>
                      ) : (
                        <div className="graph-loading holder" />
                      )}
                    </ContentLoader>
                  </div>
                </MuiGrid>

                <MuiGrid item container xs={12} sm={12} md={4} lg={4}>
                  <MuiGrid item xs={12} sm={6} md={12} lg={12}>
                    <ContentLoader error={basicError && 'unable to display'}>
                      <div className="state-item flex aic">
                        <div className="flex aic ico icon-video s20" />
                        <div className="flex flex-col">
                          <div className="lbl font s16 c333">
                            <span className="s18 b6">{`${basicData?.viewstotal}`}</span>
                            &nbsp;
                            {`Video View${basicData?.viewstotal !== 1 && 's'}`}
                          </div>
                         // <div className="txt font s13 c999">
                         //   {basicData?.watchtimetotal + ' Seconds Watched'}
                         // </div>
                        </div>
                      </div>
                    </ContentLoader>
                  </MuiGrid>

                  <MuiGrid item xs={12} sm={6} md={12} lg={12}>
                    <ContentLoader error={basicError && 'unable to display'}>
                      <div className="state-item flex aic">
                        <div className="flex aic ico icon-eye1 s20" />
                        <div className="flex flex-col">
                          <div className="lbl font s16 c333">
                            <span className="s18 b6">
                              {change?.number >= 0 ? '+' : '-'}&nbsp;
                              {Math.round(change?.percent)}%
                            </span>
                            &nbsp;
                            {`${
                              change?.number >= 0 ? 'Increase' : 'Decrease'
                            } in Tours`}
                          </div>
                          <div className="txt font s13 c999">
                            {`${change?.number || 'NaN'} ${
                              change?.number >= 0 ? 'More' : 'Less'
                            } Tours this Week`}
                          </div>
                        </div>
                      </div>
                    </ContentLoader>
                  </MuiGrid>

                  <MuiGrid item xs={12} sm={6} md={12} lg={12}>
                    <ContentLoader error={basicError && 'unable to display'}>
                      <div className="state-item flex aic">
                        <div className="flex aic ico icon-users s20" />
                        <div className="flex flex-col">
                          <div className="lbl font s16 c333">
                            <span className="s18 b6">
                              {basicData?.leadstotal}
                            </span>
                            &nbsp; Lead
                            {basicData?.leadstotal !== 1 ? 's' : ''}
                          </div>
                          <div className="txt font s13 c999">
                            {basicData?.leased?.total +
                              ` Lease${
                                basicData?.leased?.total !== 1 ? 's' : ''
                              }`}
                          </div>
                        </div>
                      </div>
                    </ContentLoader>
                  </MuiGrid>
                  <MuiGrid item xs={12} sm={6} md={12} lg={12}>
                    <ContentLoader error={liveError}>
                      <div className="state-item flex aic">
                        <div className="flex aic ico icon-send s20" />
                        <div className="flex flex-col">
                          <div className="lbl font s16 c333">
                            <span className="s18 b6">{liveVisitors}</span>&nbsp;
                            Live Visitor{liveVisitors !== 1 && 's'}
                          </div>
                          <div className="txt font s13 c999">
                            {liveVisitors +
                              ` Visitor${
                                liveVisitors !== 1 ? 's' : ''
                              } in the Last 2 Hours`}
                          </div>
                        </div>
                      </div>
                    </ContentLoader>
                  </MuiGrid>
                </MuiGrid>
              </MuiGrid>
            )} */}

              {/* {analytics && (
              <MuiGrid spacing={2} container>
                <MuiGrid item xs={12} sm={12} md={barChartSize > 10 ? 12 : 6}>
                  <div className="Card">
                    <Box m={1}>
                      <ContentLoader error={categoryError}>
                        {barChartData ? (
                          <ReverseBarChart
                            title={barChartData.title}
                            categories={barChartData.titles.slice(
                              0,
                              barChartSize
                            )}
                            data={barChartData.data.slice(0, barChartSize)}
                            size={barChartSize}
                            customIcons={[
                              {
                                icon:
                                  barChartSize === 10
                                    ? '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M24 24H0V0h24v24z" fill="none" opacity=".87"/><path d="M16.59 8.59L12 13.17 7.41 8.59 6 10l6 6 6-6-1.41-1.41z"/></svg>'
                                    : '<svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#000000"><path d="M0 0h24v24H0V0z" fill="none"/><path d="M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14l-6-6z"/></svg>',
                                index: 0,
                                title:
                                  barChartSize === 10
                                    ? 'View all screens'
                                    : 'View less screens',
                                class: 'custom-icon',
                                click: function (chart, options, e) {
                                  if (
                                    barChartSize === 10 &&
                                    barChartData.data.length > 10
                                  ) {
                                    setBarChartSize(barChartData.data.length);
                                  } else if (barChartSize === 10) {
                                    setBarChartSize(15);
                                  } else {
                                    setBarChartSize(10);
                                  }
                                },
                              },
                            ]}
                          />
                        ) : (
                          <div className="graph-loading holder" />
                        )}
                      </ContentLoader>
                    </Box>
                  </div>
                </MuiGrid>
                <MuiGrid item xs={12} sm={12} md={barChartSize > 10 ? 12 : 6}>
                  <div className="Card">
                    <Box m={1}>
                      <ContentLoader error={categoryError}>
                        {categoryData ? (
                          <DonutChart
                            title="All Categories"
                            labels={categoryData.categoryNames}
                            data={categoryData.categoryTotals}
                            selected={handleCategorySelection}
                          />
                        ) : (
                          <div className="graph-loading holder" />
                        )}
                      </ContentLoader>
                    </Box>
                  </div>
                </MuiGrid>
                // commented
                <MuiGrid item xs={12} sm={12} md={12} lg={12}>
                  {activity && (
                    <Leads
                      magnet_uuid={magnetUuid}
                      start={startDate}
                      startBusiness={startBusiness}
                      endBusiness={endBusiness}
                      name={name}
                      timeSelection={lengthSelection}
                    />
                  )}
                </MuiGrid>
                // commnented
                <MuiGrid item xs={12} sm={12} md={8} lg={8}>
                  <div
                    className="Card"
                    style={{ border: 'none', boxShadow: 'none' }}
                    title=""
                  >
                    <ContentLoader error={timeError}>
                      {timeData ? (
                        <>
                          <br />
                          <center>
                            // commented
                            <h1>{outside_b_percent} %</h1>
                             <span style={{ marginBottom: '-20px' }}>
                              Tours Outside Business Hours
                            </span>
                            //commnented
                          </center>
                          <SplineAreaChart
                            // .slice and .concat below makes it so that we are able to get the data in the correct timezone.
                            // timeData is from midnight - midnight in UTC time. In onder to get the local time midnight - midnight we must remove the first timeZoneOffset and add it to the end
                            dataAndLabels={[
                              ['Tours', 'tours'],
                              ['Views', 'views'],
                              ['Leads', 'leads'],
                              ['Leases', 'leased'],
                            ].map((label) => {
                              return {
                                name: label[0],
                                data: timeData?.time[label[1]]
                                  ?.slice(timeZoneOffset, 24)
                                  ?.concat(
                                    timeData?.time[label[1]]?.slice(
                                      0,
                                      timeZoneOffset
                                    )
                                  ),
                              };
                            })}
                            height={300}
                            id="TourOnlyArea"
                            toursOnly={true}
                            xAxisData={[...Array(24).keys()].map((num) =>
                              new Date(0, 0, 0, num).toLocaleTimeString([], {
                                hour: 'numeric',
                              })
                            )}
                          />
                        </>
                      ) : (
                        <div className="graph-loading holder" />
                      )}
                    </ContentLoader>
                  </div>
                </MuiGrid>
                <MuiGrid item xs={12} sm={12} md={4} lg={4}>
                  <div
                    className="Card"
                    style={{ border: 'none', boxShadow: 'none' }}
                    title=""
                  >
                    <ContentLoader error={timeError || badgeError}>
                      {timeData && badges ? (
                        <>
                          <br />
                          <center>
                            <h1>{badges.avg_num_tours_daily.toFixed(2)}</h1>
                            <span style={{ marginBottom: '-20px' }}>
                              Avg Tours Each Day
                            </span>
                          </center>
                          <BarChart
                            dataAndLabels={[
                              ['Tours', 'tours'],
                              ['Views', 'views'],
                              ['Leads', 'leads'],
                              ['Leases', 'leased'],
                            ].map((label) => {
                              return {
                                name: label[0],
                                data: timeData?.day[label[1]],
                              };
                            })}
                            height={300}
                            id="ToursOnlyBar"
                            toursOnly={true}
                            xAxisData={['S', 'M', 'T', 'W', 'T', 'F', 'S']}
                          />
                        </>
                      ) : (
                        <div className="graph-loading holder" />
                      )}
                    </ContentLoader>
                  </div>
                </MuiGrid>
              </MuiGrid>
            )} */}

              {/*
          ********************DO NOT DELETE (will be added later)*******************
        <Card title="Tours are most likely open on ">
          <Tag.List>
            <Tag addOn={<Icon name="user" />}>Studio</Tag>
            <Tag color="danger" addOnIcon="activity">
              Floor Plan
            </Tag>
            <Tag addOn="passing" addOnColor="success">
              tests
            </Tag>
            <Tag color="dark" addOn="1kb" addOnColor="warning">
              CSS gzip size
            </Tag>
          </Tag.List>

          <TableContainer component={Paper}>
            <MaterialTable className={classes.table} aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="right">Open Tour</TableCell>
                  <TableCell align="right">Videos Watched</TableCell>
                  <TableCell align="right">Form Submissions</TableCell>
                  <TableCell align="right">Average Page Depth</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {rows.map((row) => (
                  <TableRow key={row.name}>
                    <TableCell component="th" scope="row">
                      {row.name}
                    </TableCell>
                    <TableCell align="right">{row.calories}</TableCell>
                    <TableCell align="right">{row.fat}</TableCell>
                    <TableCell align="right">{row.carbs}</TableCell>
                    <TableCell align="right">{row.protein}</TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </MaterialTable>
          </TableContainer>

          <Card.Footer>
            <center>
              <a href="/leads">+ See All Leads</a>
            </center>
          </Card.Footer>
        </Card>
        ********************DO NOT DELETE (will be added later)*******************
        */}
            </div>
          </div>
        </div>
        <br />
      </div>
    </>
  );
};

export default MainAnalytics;
