import * as d3 from 'd3';
import mapboxgl from 'mapbox-gl';
import 'mapbox-gl/dist/mapbox-gl.css';
import { useEffect, useRef, useState } from 'react';
import ReactMapboxGl, { Cluster, Marker, Popup } from 'react-mapbox-gl';
import iconUrl from '../../assets/location_yellow.png';
import axios from 'axios';
import { API_HOST } from '../../utils/request';
mapboxgl.workerClass =
  require('worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker').default;

const Map = ReactMapboxGl({
  accessToken:
    'pk.eyJ1IjoiZGF1ZGk5NyIsImEiOiJjanJtY3B1bjYwZ3F2NGFvOXZ1a29iMmp6In0.9ZdvuGInodgDk7cv-KlujA',
});

const styles = {
  clusterMarker: {
    borderRadius: '50%',
    borderWidth: '7px',
    borderStyle: 'double',
    justifyContent: 'center',
    alignItems: 'center',
    display: 'flex',
    color: 'cornsilk',
    fontWeight: 900,
    fontSize: '1em',
    borderColor: '#22a7b5',
    backgroundColor: 'black',
    cursor: 'pointer',
  },
  marker: {
    backgroundColor: 'red',
    borderColor: '#777',
    borderWidth: '2px',
    height: '20px',
    width: '20px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    color: 'transparent',
    cursor: 'pointer',
  },
};

const INIT_STATE = {
  isIconLoaded: false,
  zoom: 2.5,
  center: [-0.481747846041145, 51.3233379650232],
  data: null,
  activeAction: null,
};

const MapContainer = (props) => {
  const [state, setState] = useState({ ...INIT_STATE });
  const mapRef = useRef(null);
  const querystring =
'SELECT `default.events`.`event_type` AS `event_type`, `default.events`.`local_tz_offset` AS `local_tz_offset`, `default.events`.`location_city` AS `location_city`, `default.events`.`location_continent` AS `location_continent`, `default.events`.`location_country` AS `location_country`, `default.events`.`location_country_name` AS `location_country_name`, `default.events`.`location_latitude` AS `location_latitude`, `default.events`.`location_longitude` AS `location_longitude`, `default.events`.`location_region` AS `location_region`, `default.events`.`location_zip` AS `location_zip` FROM `default.events`' 
+ 
'WHERE `default.events`.`magnet_uuid` = ' +
  `'${props.magnetUuid}' `
  +
  "AND (`default.events`.`event_type` = 'open_tour' OR `default.events`.`event_type` = 'form_submission') " +
  'AND `default.events`.`_timestamp` >= CAST(datetime_trunc(datetime_add(current_datetime(), INTERVAL -12 month), month) AS timestamp) ' +
  'AND `default.events`.`_timestamp` < CAST(datetime_trunc(current_datetime(), month) AS timestamp) ' +
  'LIMIT 1048575'
 ;

  let apiUrl =
    `https://api.leasemagnets.com/run_sqlquery_inbigquery?querystring=${querystring}` 
  useEffect(() => {
    loadData();
  }, []);

  const loadData = async () => {
    console.log("apiUrl",apiUrl)
    const { data } = await axios.post(
      `${API_HOST}/run_sqlquery_inbigquery`,
      {
        querystring: querystring,
      }
    );
    console.log("apiUrl_ data",data)
    let dataArr = getDataArr(data);
    let geo = geoData(dataArr);
    setState({
      ...state,
      data: JSON.parse(JSON.stringify(geo)),
    });
  };

  const getDataArr = (jsonData) => {
    let fields = [
      'event_type',
      'local_tz_offset',
      'location_city',
      'location_continent',
      'location_country',
      'location_country_name',
      'location_latitude',
      'location_longitude',
      'location_region',
      'location_zip',
    ];

    let { res } = jsonData;
    return res.map((entry) => {
      let obj = {};
      entry.map((value, i) => {
        obj[fields[i]] = value;
      });

      return obj;
    });
  };

  const geoData = (dt) => {
    let fc = { type: 'FeatureCollection', features: [] };

    fc.features = dt.map((item) => {
      return {
        type: 'Feature',
        geometry: {
          type: 'Point',
          coordinates: [
            parseFloat(item.location_longitude),
            parseFloat(item.location_latitude),
          ],
        },
        properties: { ...item },
      };
    });

    return fc;
  };

  const handleZoomEnd = (map) => {
    if (state.zoom !== 2.5) {
      // setState({
      //     ...state,
      //     zoom:map.getZoom(),
      //     center:map.getCenter(),
      // });
    }
  };

  const handleStyleLoad = (map) => {
    if (!map.hasImage('marker-icon')) {
      map.loadImage(iconUrl, function (error, image) {
        if (error) {
          console.log(error);
          return;
        }

        map.addImage('marker-icon', image, { sdf: true });
      });
    }
  };

  const handleMoveEnd = (map) => {
    // if(state.zoom != 2.5) {
    //     console.log("Active Action");
    //     setState({
    //         ...state,
    //         zoom:map.getZoom(),
    //         center:map.getCenter(),
    //         activeAction:null
    //     });
    // }
  };

  const handleMapClick = (e) => {
    let { map } = mapRef.current.state;

    setState({
      ...state,
      zoom: map.getZoom(),
      center: map.getCenter(),
      data: { ...state.data },
      activeAction: null,
    });
  };

  const handleMouseOver = (feature) => {
 
    let { map } = mapRef.current.state;

    setState({
      ...state,
      zoom: map.getZoom(),
      center: map.getCenter(),
      activeAction: { ...feature },
    });
  };

  const renderMarkers = (data) => {
    const getBgColor = (event_type) => {
      switch (event_type) {
        case 'open_tour':
          return 'teal';
        case 'form_submission':
          return 'red';
        default:
          return 'orange';
      }
    };

    return data.features.map((feature, key) => {
      // console.log(feature);

      return (
        <Marker
          style={{
            ...styles.marker,
            backgroundColor: getBgColor(feature.properties.event_type),
          }}
          key={`${key}-marker`}
          coordinates={feature.geometry.coordinates}

          // onClick={this.onMarkerClick.bind(this, feature.geometry.coordinates)}
        >
          <div onMouseOver={() => handleMouseOver(feature)}>M</div>
        </Marker>
      );
    });
  };

  console.log(state.data);
  let { data, activeAction, center } = state;

  return (
    <Map
      style="mapbox://styles/mapbox/light-v10"
      containerStyle={{
        height: '100vh',
        width: '100%',
      }}
      center={center}
      onZoomEnd={handleZoomEnd}
      onMoveEnd={handleMoveEnd}
      onClick={handleMapClick}
      zoom={[state.zoom]}
      onStyleLoad={handleStyleLoad}
      ref={mapRef}
    >
      {data && data.features[0] && (
        <Cluster
          ClusterMarkerFactory={clusterMarkerFactory}
          zoomOnClick={[true]}
          maxZoom={15}
        >
          {renderMarkers(data)}
        </Cluster>
      )}

      {activeAction && (
        <Popup coordinates={activeAction.geometry.coordinates} offset={15}>
          <div className="popup-content">
            <div>
              Event Type: <b>{activeAction.properties.event_type}</b>
            </div>
          </div>
        </Popup>
      )}
    </Map>
  );
};

const clusterMarkerFactory = (coordinates, pointCount) => {
  let dimension = getSize(pointCount) + 'px';
  let point_count = convertCount(pointCount, 1);
  let key = Math.random() * 100000;
  return (
    <Marker
      key={key.toString()}
      coordinates={coordinates}
      style={{
        ...styles.clusterMarker,
        height: dimension,
        width: dimension,
      }}
    >
      {/* <div className='cluster-marker'> */}
      {point_count}
      {/* </div> */}
    </Marker>
  );
};

function getSize(pointCount) {
  if (pointCount < 20) {
    return 25;
  }
  return 25 + (pointCount / 40) * 0.5;
}

function convertCount(n, d) {
  let x = ('' + n).length;
  let p = Math.pow;

  d = p(10, d);
  x -= x % 3;

  return Math.round((n * d) / p(10, x)) / d + ' kMGTPE'[x / 3];
}

export default MapContainer;

{
  /* <Marker
coordinates={[-0.481747846041145, 51.3233379650232]}
anchor="bottom">
<img src={iconUrl} alt="icon" style={{height:"40px", width:"40px", }}/>
</Marker>

<Layer 
type="symbol" 
id="marker" 
layout={{
    "icon-image":"marker-icon",
    "icon-size":0.2,
    "icon-padding":0.08
}}
paint={{
    'icon-color':'red'
}}
>
<Feature coordinates={[-0.481747846041145, 51.3233379650232]} />
</Layer> */
}
