import React from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { API_HOST } from '../../utils/request';

const EntrataLeadStatus = ({ tour_details, magnetData, leadData, handleEntrataUpdate }) => {
  const sendEntrataLead = async () => {
    const loadingToastId = toast.loading('Saving Lead to Entrata...');
    console.log("EntrataLeadStatus",{magnetData, leadData, tour_details})
    try {
      let response = {}
      if(leadData?.details?.metadata?.[0]?.lead_type == "Tour") {
        response = await axios.post(`${API_HOST}/integrations/entrata/sendLeads`, {
            creds: {
            username: magnetData?.magnets[0]?.integration_details?.["api-entrata"]?.username,
            password: magnetData?.magnets[0]?.integration_details?.["api-entrata"]?.password,
            },
            property_id: magnetData?.magnets[0]?.integration_details?.["api-entrata"]?.property_id,
            originating_source_id: magnetData?.magnets[0]?.integration_details?.["api-entrata"]?.originating_source_id || '',
            first_name: leadData?.details?.first_name,
            last_name: leadData?.details?.last_name,
            email: leadData?.details?.email,
            phone: leadData?.details?.phone,
            date: leadData?.details?.metadata?.[0]?.tour_date,
            note: leadData?.details?.metadata?.[0]?.tour_summary,
            tour_type: leadData?.details?.metadata?.[0]?.tour_type,
            appointmentDate: leadData?.details?.metadata?.[0]?.tour_date,
            timeFrom: leadData?.details?.metadata?.[0]?.tour_time_from,
            timeTo: leadData?.details?.metadata?.[0]?.tour_time_to,
            tourLength: leadData?.details?.metadata?.[0]?.tour_length,
            eventReasons: 'Tour Book Appointment, LeaseMagnets',
        });
      } else {
        const now = new Date();
        const tourDate = now.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
        const tourTime = now.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
        const thirtyMinsLater = new Date(now.getTime() + 30 * 60000);
        const endTime = thirtyMinsLater.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });
        const shortDate = now.toLocaleDateString('en-US', { month: 'short', day: 'numeric' });

        response = await axios.post(`${API_HOST}/integrations/entrata/sendLeads`, {
            creds: {
            username: magnetData?.magnets[0]?.integration_details?.["api-entrata"]?.username,
            password: magnetData?.magnets[0]?.integration_details?.["api-entrata"]?.password,
            },
            property_id: magnetData?.magnets[0]?.integration_details?.["api-entrata"]?.property_id,
            originating_source_id: magnetData?.magnets[0]?.integration_details?.["api-entrata"]?.originating_source_id || '',
            first_name: leadData?.name?.split(" ")[0],
            last_name: leadData?.name?.split(" ")[1],
            email: leadData?.email,
            phone: leadData?.phone,
            "tour_type": "Virtual",
            "tour_time_start_originating_timezone": `${tourDate} ${tourTime}`,
            "tour_date": shortDate,
            "timeFrom": tourTime,
            "timeTo": endTime,
            "eventReasons": "",
            "comments": "Tour Book Appointment, LeaseMagnets",
            "tour_timezone": "EST",
        });
      }

      const { data } = response

      console.log("EntrataLeadStatus_data",data)

      if (data?.response?.error) {
        toast.update(loadingToastId, {
          render: `Request failed: ${data?.response?.error.message}`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
      } else if (data?.status === "Failure" || data?.success === false || data?.response?.result?.prospects?.prospect?.[0]?.status === "Failure") {
        return toast.update(loadingToastId, {
          render: `Request failed: ${data?.message ? data?.message : data?.response}`,
          type: 'error',
          isLoading: false,
          autoClose: 5000,
        });
      } else {
        toast.update(loadingToastId, {
          render: `Lead saved successfully!`,
          type: 'success',
          isLoading: false,
          autoClose: 5000,
        });

        let entrata_prospect = data?.response?.result?.prospects?.prospect?.[0];
        handleEntrataUpdate({
          status: 'success',
          applicant_id: entrata_prospect?.applicantId,
          application_id: entrata_prospect?.applicationId,
        });
      }
    } catch (error) {
      toast.update(loadingToastId, {
        render: `Request failed: ${error.message}`,
        type: 'error',
        isLoading: false,
        autoClose: 5000,
      });
      console.error('Error sending request:', error);
    }
  };

  const checkifPropisCenturion = () => {
    const threeWeeksInMs = 21 * 24 * 60 * 60 * 1000; // 21 days in milliseconds
    const tourTime = new Date(leadData?.time).getTime();
    const currentTime = new Date().getTime();
    
    if (!tour_details?.entrata && (currentTime - tourTime) < threeWeeksInMs && magnetData?.magnets[0]?.name?.includes("Centurion")) {
      return true;
    }
    return false;
  }

  return (
    <>
      <ToastContainer />
      {(tour_details?.entrata?.status === "error" || checkifPropisCenturion())  && (
        <div>
          <div className="flex gap-x-3 items-center">
            ❌
            <h2 className="text-md font-medium">{tour_details?.entrata?.error}</h2>
          </div>
          <button
            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 border border-blue-700 rounded"
            onClick={sendEntrataLead}
          >
            Resend lead to Entrata
          </button>
        </div>
      )}
      {tour_details?.entrata?.status === "success" && (
        <div className="flex gap-x-3 items-center">
          ✅
          <h2 className="text-md font-medium">
            Entrata Id: {tour_details?.entrata?.applicant_id}
          </h2>
        </div>
      )}
    </>
  );
};

export default EntrataLeadStatus;
