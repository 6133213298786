import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import CommunityForm from './AddCommunityForm';

const AddDeleteCommunities = (props) => {
  const { communities, setCommunities, width, setSignupStep, hasCommunities } =
    props;
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  const history = useHistory();

  const communityCreateDiv = {
    padding: isMobile ? '0px' : '20px',
    width: width || isMobile ? '100%' : '90%',
    // border: "2px solid lightgrey",
    background: '#fff',
    // borderRadius: "15px"
  };

  // easily add delete communities
  const handleDelete = (index) => {
    const newArr = [...communities];
    newArr.splice(index, 1);
    setCommunities(newArr);
  };

  // when you click add community button, add the new community to the communityToAdd state
  const handleSubmit = (communityToAdd) => {
    console.log('Up a level', communityToAdd);
    setCommunities((communities) => communities.concat(communityToAdd));
  };

  // when you click continue to the next step, add the community to the specific users list of apartments
  const onContinue = async () => {
    setSignupStep(3);
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      setIsMobile(true);
    }
  }, []);

  return (
    <center>
      <div className="editCommunities" style={communityCreateDiv}>
        <CommunityForm
          onFormSubmit={handleSubmit}
          communities={communities}
          handleDelete={handleDelete}
          onContinue={onContinue}
          hasCommunities={hasCommunities}
        />
      </div>
    </center>
  );
};

export default AddDeleteCommunities;
