import React, { useContext, useEffect, useState } from 'react';
import { InlineWidget, useCalendlyEventListener } from 'react-calendly';
import Cookies from 'universal-cookie';
import { v4 as uuidv4 } from 'uuid';
import { formSubmission, formView } from './api';
import './common.css';
import './Iframe.css';
import MagnetContext from './MagnetContext';

const Iframe = ({
  src,
  backgroundColor,
  openTourState,
  onIframeFormSubmissionComplete,
  previousScreenLink,
}) => {
  const { currentScreenTitle, currentCategory, currentScreen } =
    useContext(MagnetContext);
  const [iframeState, setIframeState] = useState();
  const [iframeViewUuid, setIframeViewUuid] = useState(uuidv4());
  const iframe_src_url = src ? new URL(src) : '';
  const [iframeSrc, setIframeSrc] = useState(src);

  const cookies = new Cookies();
  useEffect(() => {
    const tempIframeViewState = {
      iframe_hostname: iframe_src_url.hostname,
      iframe_src: src,
      form_view_uuid: iframeViewUuid,
      iframe: true,
      screen_title: currentScreenTitle,
      form_title: iframe_src_url.hostname + iframe_src_url.pathname,
      form_route: [currentCategory, currentScreen],
      from: previousScreenLink,
    };
    setIframeState(tempIframeViewState);

    formView(tempIframeViewState);
  }, []);

  useEffect(() => {
    if (src.includes('hy.ly')) {
      iframe_src_url.searchParams.set('ssid', cookies.get('hyly_ssid'));
      iframe_src_url.searchParams.set('hyly_assist', 'leasemagnets');
    }
    setIframeSrc(iframe_src_url.toString());
  }, [src]);

  useEffect(() => {
    const handler = (event) => {
      console.log('event2: ', event?.data);
      // const data = JSON.parse(event.data);
      // localStorage.getItem('tourUtmAndSourceParams')
      if (
        event?.origin.includes('https://app.usetour.com') &&
        event?.data?.event_type === 'form_submission'
      ) {
        console.log('event usetour form submission', iframeState, {
          ...JSON.parse(localStorage.getItem('tourUtmAndSourceParams')),
          ...openTourState,
          ...iframeState,
          ...event?.data,
          ignoreEventApi: true,
          iframe: true,
          iframe_hostname: iframe_src_url.hostname,
          iframe_src: src,
          iframe_view_uuid: iframeViewUuid,
          screen_title: currentScreenTitle,
          form_title: iframe_src_url.hostname + iframe_src_url.pathname,
        });
        formSubmission(
          {},
          {
            ...JSON.parse(localStorage.getItem('tourUtmAndSourceParams')),
            ...openTourState,
            ...iframeState,
            ...event?.data,
            ignoreEventApi: true,
            iframe: true,
            iframe_hostname: iframe_src_url.hostname,
            iframe_src: src,
            form_view_uuid: iframeViewUuid,
            screen_title: currentScreenTitle,
            form_title: iframe_src_url.hostname + iframe_src_url.pathname,
          }
        );
      }
    };

    window.addEventListener('message', handler);

    // clean up
    return () => window.removeEventListener('message', handler);
  }, []); // empty array => run only once

  // listen form_submission events posted to us window.postMessage=

  // function alwayssendMessageIframe() {
  //   const message = document.querySelector("#message").value;
  //   const iframe = document.querySelector("iframe");
  //   iframe.contentWindow.postMessage(message, "*");
  //   // route, utm paraemters,
  // }
  // https://stackoverflow.com/questions/62476826/passing-a-ref-from-a-child-to-a-parent-in-react-with-class-component
  // https://codesandbox.io/s/stn39
  // https://javascriptbit.com/transfer-data-between-parent-window-and-iframe-postmessage-api/
  useCalendlyEventListener({
    onProfilePageViewed: () => console.log('onProfilePageViewed'),
    onDateAndTimeSelected: () => console.log('onDateAndTimeSelected'),
    onEventTypeViewed: () => console.log('onEventTypeViewed'),
    onEventScheduled: (e) => {
      console.log(e.data.payload);
      formSubmission(
        {},
        {
          ...JSON.parse(localStorage.getItem('tourUtmAndSourceParams')),
          ...openTourState,
          ignoreEventApi: true,
          iframe: true,
          iframe_app_name: 'Calendly - Book Appointment',
          iframe_src: src,
          screen_title: currentScreenTitle,
          form_view_uuid: iframeViewUuid,
          screen_title: currentScreenTitle,
          form_title: iframe_src_url.hostname + iframe_src_url.pathname,
        }
      );
    },
  });

  return (
    <>
      <div className="iframe-container" style={{ backgroundColor }}>
        {iframeSrc.includes('calendly.com') ? (
          <InlineWidget
            url={src}
            styles={{
              height: '100%',
            }}
          />
        ) : (
          <iframe
            frameBorder="0"
            src={iframeSrc}
            allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
          />
        )}
      </div>
    </>
  );
};

export default Iframe;
