import Rating from '@material-ui/lab/Rating';
import React, { useRef, useState } from 'react';
import { AiFillHeart, AiOutlineHeart, AiOutlinePause } from 'react-icons/ai';
import { FiPlay } from 'react-icons/fi';
import { MdOutlineArrowRightAlt } from 'react-icons/md';
import Mcomponent from './Mcomponent';

const ReviewCard = ({
  item,
  ind,
  item2,
  managesavedreviews,
  deletereviewbyindex,
  embeded,
}) => {
  const vidRef = useRef(null);
  const isplaying = useRef(false);
  const handlePlayVideo = () => {
    console.log(vidRef);
    if (isplaying.current == false) {
      //isplaying.current = true
      let playPromise = vidRef.current.play();

      if (playPromise !== undefined) {
        playPromise
          .then((_) => {
            // Automatic playback started!
            // Show playing UI.
            isplaying.current = true;
            vidRef.current.play();
          })
          .catch((error) => {
            // Auto-play was prevented
            // Show paused UI.
            console.log(error);
          });
      }
    }
  };

  const handlePauseVideo = () => {
    if (isplaying.current == true) {
      isplaying.current = false;
      vidRef.current.pause();
    }
  };
  const [play, setPlay] = useState({
    id: -1,
    status: false,
    ref: false,
  });

  const [issave, setIssave] = useState(false);
  const [addopen, setAddopen] = useState(false);

  return (
    <div
      key={ind}
      className=" w-full  rounded-xl py-3 overflow-hidden border relative bg-white hover:bg-gray-100 transition duration-150"
      style={{ height: '100%' }}
    >
      {item2 !== undefined && (
        <Mcomponent
          item={item2}
          addopen={addopen}
          setAddopen={setAddopen}
          ind={ind}
          embeded={embeded}
          deletereviewbyindex={deletereviewbyindex}
        />
      )}
      {/* {item.type === 2 && ( */}
      <div onClick={() => setAddopen(true)}>
        {item?.video ? (
          <div className=" relative w-full overflow-hidden  mb-.5">
            <>
              <video
                ref={vidRef}
                className="item-video cursor-pointer"
                style={{ height: '100%' }}
                loop
              >
                <source src={item?.video} type="video/mp4" />
                Your browser does not support HTML5 Video.
              </video>
              <div className=" absolute bottom-3 left-3 z-20">
                {play.id === ind &&
                play.status === true &&
                play.ref === true ? (
                  <AiOutlinePause
                    onClick={() => {
                      handlePauseVideo();
                      setPlay({
                        id: ind,
                        status: false,
                        ref: false,
                      });
                    }}
                    className=" w-7 h-7 text-white cursor-pointer"
                  />
                ) : (
                  <FiPlay
                    onClick={() => {
                      handlePlayVideo();
                      setPlay({
                        id: ind,
                        status: true,
                        ref: true,
                      });
                    }}
                    className=" w-7 h-7 text-white cursor-pointer"
                  />
                )}
              </div>
            </>
          </div>
        ) : (
          <div className="  px-3 py-3 h-52 mb-6 cursor-pointer ">
            <h1
              className=" font-semibold text-lg pr-4"
              style={{ lineHeight: '23px' }}
            >
              {item?.useranswer?.slice(0, 100)}...
            </h1>
          </div>
        )}
      </div>
      <div
        className=" w-full  px-3 py-3 cursor-pointer h-24 border-t"
        onClick={() => setAddopen(true)}
      >
        <div className="flex justify-between	">
          {item?.question && (
            <p className=" text-gray-800 text-sm font-medium">
              {item?.question?.slice(0, 40)}
            </p>
          )}
          <div>
            {item2?.question?.length > 1
              ? `+${item2?.question?.length - 1}`
              : ''}
          </div>
        </div>
        <div className=" w-full flex items-center  gap-2 pt-3 justify-between">
          <div>
            <Rating
              name="simple-controlled"
              value={item2?.rating}
              onChange={(event, newValue) => {
                setValue(newValue);
              }}
            />
            {item?.to && (
              <p className=" text-gray-500 flex items-center gap-1 text-xs">
                <MdOutlineArrowRightAlt />
                {item?.to}
              </p>
            )}
          </div>
          <div>
            {item2?.name && (
              <p className=" text-gray-800 text-sm font-medium">
                {item2?.name}
              </p>
            )}
            {item?.date && (
              <p className=" text-gray-500 text-xs font-medium">{item?.date}</p>
            )}
          </div>
        </div>
      </div>
      {/* )}  */}
      <div className=" absolute top-3 right-3 z-20">
        {!item2?.saved ? (
          <AiOutlineHeart
            onClick={() => managesavedreviews(ind, true)}
            className={
              item?.type === 2
                ? ' w-6 h-6 text-white cursor-pointer'
                : ' w-6 h-6 text-gray-500 cursor-pointer'
            }
          />
        ) : (
          <AiFillHeart
            onClick={() => managesavedreviews(ind, false)}
            className={
              item?.type === 2
                ? ' w-6 h-6 text-white cursor-pointer'
                : ' w-6 h-6 text-gray-500 cursor-pointer'
            }
          />
        )}
      </div>
    </div>
  );
};

export default ReviewCard;
