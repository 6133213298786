import {
  Accordion,
  AccordionSummary,
  Button,
  CircularProgress,
  ClickAwayListener,
  FormControlLabel,
  IconButton,
  Snackbar,
  Step,
  StepButton,
  StepContent,
  Stepper,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import InfoIcon from '@material-ui/icons/Info';
import Alert from '@material-ui/lab/Alert';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import DebouncedTextField from '../../components/Reusable/DebouncedTextField';
import firebase from '../../utils/base';
import { get, post } from '../../utils/request';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  text: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  tooltip: {
    maxWidth: 500,
  },
  MuiAccordionroot_success: {
    marginTop: 12,
    boxShadow: 'none',
    boxSizing: 'border-box',
    border: '2px solid green !important',
  },
  MuiAccordionroot_failure: {
    marginTop: 12,
    boxShadow: 'none',
    boxSizing: 'border-box',
    border: '2px solid red !important',
  },
  content: {
    flexGrow: 0,
  },
}));

export default function EntrataIntegration({ match, form_id }) {
  const classes = useStyles();
  // Stepper
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState(-1);
  const [disabled, setDisabled] = useState(true);

  const [loading, setLoading] = useState(false);
  // Tooltip
  const [open, setOpen] = useState(false);
  const [error, setError] = useState();
  // Snackbar
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  // Data
  const [communities, setCommunities] = useState();
  const [testLead, setTestLead] = useState({
    first_name: 'John',
    last_name: 'Doe',
    city: '',
    state: '',
    email: 'johndoe@leasemagnets.com',
    phone: '1234567890',
  });
  const [entrataIntegration, setEntrataIntegration] = useState({
    username: '',
    password: '',
    // api_url: '',
    property_id: '',
    originating_source_id: '',
    source_id_name: '',
    live: false,
  });
  // test sample lead
  const [testLeadSuccess, setTestLeadSuccess] = useState(false);
  const [testLeadError, setTestLeadError] = useState(false);
  const [testLeadResponse, setTestLeadResponse] = useState('');
  const [magnetUuid, setMagnetUuid] = useState();
  const params = useParams();
  console.log('magnetUuid', magnetUuid);

  const handleTooltipClose = (e) => {
    e.stopPropagation();
    setOpen(false);
  };

  const handleTooltipOpen = (e) => {
    e.stopPropagation();
    setOpen(true);
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  useEffect(() => {
    //}
    getMagnet().then((res) => {
      if (res) {
        console.log('ress', res);
      }
    });
  }, []);

  const getMagnet = async () => {
    const res = await get(`/magnets?queryByCommunity=${params.community_id}`, {
      auth: false,
    });
    //console.log('resssss1', res);
    setMagnetUuid(res.magnets[0].uuid);
    return res.magnets[0].uuid;
  };

  async function saveinLocal() {
    try {
      console.log('entrata integration TYG: ', entrataIntegration);
      const res = await post('/integration/details', {
        magnet_uuid: magnetUuid,
        data: {
          ['api-entrata']: {
            ...entrataIntegration,
          },
        },
      });
    } catch (err) {
      console.log('error while in directual', err);
      return false;
    }
  }

  async function updateEntrataIntegration() {
    // Coppied from Integration.js
    // Firebase cheatsheet https://medium.com/@aaron_lu1/firebase-cloud-firestore-add-set-update-delete-get-data-6da566513b1b
    try {
      await firebase.db // await to catch any errors
        .collection('videoembed')
        .doc(form_id)
        .collection('integrations')
        .doc('entrata')
        .set(entrataIntegration, { merge: true });
      saveinLocal();
      setSnackbarOpen(true);
      setLoading(false);
      ///
      // saving data in directual
      // await axios.post(
      //   `https://api.directual.com/good/api/v5/data/message/getAndCreateMessage?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83`,
      //   {
      //     id: params.community_id,
      //     type: 'entrata_creds',
      //     data: (
      //       await firebase.db
      //         .collection('videoembed')
      //         .doc(form_id)
      //         .collection('integrations')
      //         .doc('entrata')
      //         .get()
      //     ).data(),
      //   }
      // );
      // setLoading(true);
      // console.log(
      //   'entrataIntegrationasdasd',
      //   (
      //     await firebase.db
      //       .collection('videoembed')
      //       .doc(form_id)
      //       .collection('integrations')
      //       .doc('entrata')
      //       .get()
      //   ).data()
      // );
      //
      return true;
    } catch (err) {
      console.log('Failure getting leads document, check firebase key :', err);
      setLoading(false);
      return false;
    }
  }

  const getEntrataIntegration = async () => {
    // Coppied from Integration.js
    console.log('fetching entrata integration');
    setLoading(true);
    try {
      const doc = await get(`/integration/details?magnet_uuid=${magnetUuid}`, {
        auth: false,
        host: 'https://api.leasemagnets.com',
      });

      // const doc = await firebase.db
      //   .collection('videoembed')
      //   .doc(form_id)
      //   .collection('integrations')
      //   .doc('entrata')
      //   .get();
      // console.log('entrata docdata', doc.data);
      // const data = doc.data();
      // console.log('entrata docdata', doc, ' | magnetUUID', magnetUuid);
      const data = doc['api-entrata'];
      if (data) {
        console.log('entrata docdata integrations field exists', data);
        // https://docs.microsoft.com/en-us/dotnet/api/system.datetime.tostring?view=netcore-3.1
        setEntrataIntegration(data);
        setCompleted(steps.length);
        setDisabled(false);
        setActiveStep(steps.length - 1);
      } else {
        console.log('leads field does not exists');
      }
      setLoading(false);
      return true;
    } catch (err) {
      console.log('Failure getting leads document, check firebase key :', err);
      setLoading(false);
      return false;
    }
  };

  useEffect(() => {
    console.log('attempting to run getentrataintegrations');
    if (magnetUuid) getEntrataIntegration();
  }, [magnetUuid]);

  async function getProperties() {
    setError();
    if (communities) {
      setActiveStep(1);
      return;
    }

    setLoading(true);

    const resp = await post('/integrations/entrata/getProperties', {
      creds: {
        username: entrataIntegration.username,
        password: entrataIntegration.password,
      },
    });
    if (!resp.error) {
      setCommunities(resp?.properties);
      setActiveStep(1);
      setDisabled(true);
    } else {
      setError(
        resp?.resp?.error?.message ||
          'An error occured while fetching properties, please check credentials and try again'
      );
    }
    setLoading(false);
  }

  const handleNext = (skipTo = 0) => {
    if (skipTo > 0) {
      if (activeStep == 0) {
        setCompleted(activeStep);
        setActiveStep(skipTo);
        // getProperties();
        return;
      } else {
        setActiveStep(skipTo);
      }
    }
    if (activeStep === 0) {
      getProperties();
      if (completed <= activeStep) {
        setCompleted(activeStep);
      }
    } else {
      if (completed <= activeStep) {
        setCompleted(activeStep);
      }
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setDisabled(true);
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  async function getRandomLeadSource() {
    setLoading(true);
    const resp = await post('/integrations/entrata/getLeads', {
      creds: {
        username: entrataIntegration.username,
        password: entrataIntegration.password,
      },
      propertyId: entrataIntegration.property_id,
    });

    setEntrataIntegration({
      ...entrataIntegration,
      originating_source_id: resp?.leads[0]?.lead_source?.id,
      source_id_name: resp?.leads[0]?.lead_source?.type,
    });
    setLoading(false);
    setDisabled(false);
    console.log('got entrata? ', resp.leads[0].lead_source);
  }

  const steps = [
    <>
      Add Entrata credentials
      {activeStep === 0 && (
        <ClickAwayListener onClickAway={(e) => handleTooltipClose(e)}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            placement="right"
            onClose={(e) => handleTooltipClose(e)}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <React.Fragment>
                <Typography color="inherit">
                  If you do not have a username and password, please ask your
                  Entrata rep for a sendLeads/getLeads API key and mention
                  LeaseMagnets as your vendor.
                </Typography>
              </React.Fragment>
            }
          >
            <IconButton onClick={(e) => handleTooltipOpen(e)}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </ClickAwayListener>
      )}
    </>,
    'Select property',
    <>
      Add LeaseMagnets as a lead source
      {activeStep === 2 && (
        <ClickAwayListener onClickAway={(e) => handleTooltipClose(e)}>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            placement="right"
            onClose={(e) => handleTooltipClose(e)}
            open={open}
            disableFocusListener
            disableHoverListener
            disableTouchListener
            title={
              <React.Fragment>
                {/* TODO link tutorial */}
                <Typography color="inherit">
                  Please enter your lead source ID for LeaseMagnets below.
                  Questions? Watch this tutorial.
                </Typography>
              </React.Fragment>
            }
          >
            <IconButton onClick={(e) => handleTooltipOpen(e)}>
              <InfoIcon />
            </IconButton>
          </Tooltip>
        </ClickAwayListener>
      )}
    </>,
    'Send a test lead',
    'Go live & save API settings',
  ];

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <>
            <DebouncedTextField
              label="Username"
              variant="outlined"
              className={classes.text}
              fullWidth
              onChange={(newValue) => {
                setCompleted(-1);
                setEntrataIntegration({
                  ...entrataIntegration,
                  username: newValue,
                });
                setCommunities();
                if (entrataIntegration.password && newValue) setDisabled(false);
              }}
              value={entrataIntegration.username}
            />
            <DebouncedTextField
              type="password"
              label="Password"
              variant="outlined"
              className={classes.text}
              fullWidth
              onChange={(newValue) => {
                setCompleted(-1);
                setCommunities();
                setEntrataIntegration({
                  ...entrataIntegration,
                  password: newValue,
                });
                if (entrataIntegration.username && newValue) setDisabled(false);
              }}
              value={entrataIntegration.password}
            />
            {error && <Alert severity="error">{error}</Alert>}
          </>
        );
      case 1:
        return (
          <>
            <DebouncedTextField
              label="Entrata Property Id"
              variant="outlined"
              className={classes.text}
              fullWidth
              onChange={(newValue) => {
                setEntrataIntegration({
                  ...entrataIntegration,
                  property_id: newValue,
                  // originating_source_id: '',
                  // source_id_name: '',
                });
              }}
              value={entrataIntegration.property_id}
            />
            <DebouncedTextField
              label="Entrata Property Name"
              variant="outlined"
              className={classes.text}
              fullWidth
              onChange={(newValue) => {
                setEntrataIntegration({
                  ...entrataIntegration,
                  property_name: newValue,
                  // originating_source_id: '',
                  // source_id_name: '',
                });
              }}
              value={entrataIntegration.property_name}
            />
            {communities?.map((community) => (
              <Button
                variant="contained"
                className={classes.button}
                color={
                  community.property_id === entrataIntegration?.property_id
                    ? 'primary'
                    : 'default'
                }
                onClick={() => {
                  setEntrataIntegration({
                    ...entrataIntegration,
                    property_id: community.property_id,
                    property_name: community.name,
                    originating_source_id: '',
                    source_id_name: '',
                  });
                  setDisabled(false);
                  setCompleted(activeStep - 1);
                }}
              >
                Property name: {community.name}
                <br />
                Property id: {community.property_id}
              </Button>
            ))}
            {!communities &&
              !loading &&
              'No properties found. Please check credentials and try again'}
          </>
        );
      case 2:
        return (
          <>
            {entrataIntegration.source_id_name && (
              <DebouncedTextField
                label="Lead Source Name"
                variant="outlined"
                className={classes.text}
                fullWidth
                value={entrataIntegration.source_id_name}
              />
            )}
            <DebouncedTextField
              label="Lead Source ID"
              variant="outlined"
              className={classes.text}
              fullWidth
              onChange={(newValue) => {
                setCompleted(activeStep - 1);
                setDisabled(false);
                setEntrataIntegration({
                  ...entrataIntegration,
                  originating_source_id: newValue,
                  source_id_name: '',
                });
              }}
              value={entrataIntegration.originating_source_id}
            />
            <Button
              fullWidth
              onClick={() => {
                setCompleted(activeStep - 1);
                setDisabled(true);
                getRandomLeadSource();
              }}
            >
              Generate a random test lead source ID
            </Button>
          </>
        );
      case 3:
        return (
          <>
            <DebouncedTextField
              className={classes.text}
              placeholder="First Name"
              fullWidth
              onChange={(newValue) =>
                setTestLead({ ...testLead, first_name: newValue })
              }
              value={testLead.first_name}
            />
            <DebouncedTextField
              className={classes.text}
              placeholder="Last Name"
              fullWidth
              onChange={(newValue) =>
                setTestLead({ ...testLead, last_name: newValue })
              }
              value={testLead.last_name}
            />
            <DebouncedTextField
              placeholder="Email"
              className={classes.text}
              fullWidth
              onChange={(newValue) =>
                setTestLead({ ...testLead, email: newValue })
              }
              value={testLead.email}
            />
            <DebouncedTextField
              placeholder="phone"
              className={classes.text}
              fullWidth
              onChange={(newValue) =>
                setTestLead({ ...testLead, phone: newValue })
              }
              value={testLead.phone}
            />
            <DebouncedTextField
              placeholder="city"
              className={classes.text}
              fullWidth
              onChange={(newValue) =>
                setTestLead({ ...testLead, city: newValue })
              }
              value={testLead.city}
            />
            <DebouncedTextField
              placeholder="state"
              className={classes.text}
              fullWidth
              onChange={(newValue) =>
                setTestLead({ ...testLead, state: newValue })
              }
              value={testLead.state}
            />
            <Button
              fullWidth
              variant="contained"
              color="secondary"
              onClick={async () => {
                setTestLeadSuccess(false);
                setTestLeadError(false);
                setTestLeadResponse('');
                setLoading(true);
                const res = await post('/integrations/entrata/sendLeads', {
                  creds: {
                    username: entrataIntegration.username,
                    password: entrataIntegration.password,
                  },
                  property_id: entrataIntegration.property_id,
                  originating_source_id:
                    entrataIntegration.originating_source_id || '',
                  ...testLead,
                });
                setLoading(false);
                setTestLeadResponse(res);
                if (res.response.code == 200) {
                  setTestLeadSuccess(true);
                  // added by ahad
                  setDisabled(false);
                } else {
                  setTestLeadError(true);
                }
              }}
            >
              Send Sample Lead
            </Button>
            {testLeadSuccess && (
              <Accordion
                classes={{
                  root: classes.MuiAccordionroot_success,
                }}
              >
                <AccordionSummary
                  classes={{
                    content: classes.content,
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Successfully sent test lead!</Typography>
                </AccordionSummary>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <SyntaxHighlighter language="json" style={docco}>
                    {JSON.stringify(testLeadResponse, null, 2)}
                  </SyntaxHighlighter>
                </div>
              </Accordion>
            )}
            {testLeadError && (
              <Accordion
                classes={{
                  root: classes.MuiAccordionroot_failure,
                }}
              >
                <AccordionSummary
                  classes={{
                    content: classes.content,
                  }}
                  expandIcon={<ExpandMoreIcon />}
                >
                  <Typography>Failed to send test lead</Typography>
                </AccordionSummary>
                <div style={{ display: 'flex', justifyContent: 'center' }}>
                  <SyntaxHighlighter language="json" style={docco}>
                    {JSON.stringify(testLeadResponse, null, 2)}
                  </SyntaxHighlighter>
                </div>
              </Accordion>
            )}
          </>
        );
      case 4:
        return (
          <>
            {entrataIntegration.source_id_name && (
              <Alert severity="warning">
                Warning: you are using a lead source ID linked to{' '}
                {entrataIntegration.source_id_name}
              </Alert>
            )}
            <Typography>
              <b>Username:</b> {entrataIntegration.username}
            </Typography>
            <Typography>
              <b>Property name:</b> {entrataIntegration.property_name}
            </Typography>
            <Typography>
              <b>Property ID:</b> {entrataIntegration.property_id}
            </Typography>
            <Typography>
              <b>Lead source ID:</b> {entrataIntegration.originating_source_id}
            </Typography>
            <FormControlLabel
              control={
                <Switch
                  color="primary"
                  checked={entrataIntegration.live}
                  onChange={(event) =>
                    setEntrataIntegration({
                      ...entrataIntegration,
                      live: event.target.checked,
                    })
                  }
                  name="checkedLive"
                  inputProps={{ 'aria-label': 'live checkbox' }}
                />
              }
              label="✅ Go Live"
            />
            <Button
              fullWidth
              variant="outlined"
              onClick={() => {
                updateEntrataIntegration().then((e) => {
                  console.log('bool vals', e);
                  if (e) {
                    setCompleted(activeStep);
                  }
                });
              }}
            >
              {' '}
              Save Settings{' '}
            </Button>
          </>
        );

      default:
        return 'Unknown step';
    }
  }

  return (
    <div className={classes.root}>
      <Stepper nonLinear activeStep={activeStep} orientation="vertical">
        {steps.map((label, index) => (
          <Step key={label}>
            <StepButton
              onClick={() => {
                if (completed + 1 >= index && !loading) {
                  setActiveStep(index);
                  if (index === 1 && !communities) {
                    getProperties();
                  }
                }
              }}
              completed={completed >= index}
            >
              {label}
            </StepButton>
            <StepContent>
              <Typography>{getStepContent(index)}</Typography>
              {activeStep != steps.length - 1 && (
                <div className={classes.actionsContainer}>
                  {loading && <CircularProgress />}
                  <div>
                    <Button
                      disabled={loading || activeStep === 0}
                      onClick={handleBack}
                      className={classes.button}
                    >
                      Back
                    </Button>
                    <Button
                      disabled={
                        loading ||
                        (disabled &&
                          activeStep != steps.length - 2 &&
                          !(completed >= index))
                      }
                      variant="contained"
                      color="primary"
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Next
                    </Button>
                    {(activeStep == 0 || activeStep == 1) && (
                      <Button
                        disabled={
                          // loading ||
                          disabled &&
                          activeStep != steps.length - 2 &&
                          !(completed >= index)
                        }
                        variant="contained"
                        color="secondary"
                        onClick={() => handleNext(activeStep + 1)}
                        className={classes.button}
                      >
                        Save & Skip Verification
                      </Button>
                    )}
                  </div>
                </div>
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
      <Snackbar
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        message="Settings saved"
        action={
          <React.Fragment>
            <IconButton
              size="small"
              aria-label="close"
              color="inherit"
              onClick={handleSnackbarClose}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </React.Fragment>
        }
      />
    </div>
  );
}
