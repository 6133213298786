import MaterialTable from '@material-table/core';
import { Grid } from '@material-ui/core';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import StepContent from '@material-ui/core/StepContent';
import Stepper from '@material-ui/core/Stepper';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import AddBox from '@material-ui/icons/AddBox';
import ArrowUpward from '@material-ui/icons/ArrowUpward';
import Check from '@material-ui/icons/Check';
import ChevronLeft from '@material-ui/icons/ChevronLeft';
import ChevronRight from '@material-ui/icons/ChevronRight';
import Clear from '@material-ui/icons/Clear';
import DeleteOutline from '@material-ui/icons/DeleteOutline';
import Edit from '@material-ui/icons/Edit';
import FilterList from '@material-ui/icons/FilterList';
import FirstPage from '@material-ui/icons/FirstPage';
import LastPage from '@material-ui/icons/LastPage';
import Search from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import Remove from '@material-ui/icons/Remove';
import SaveAlt from '@material-ui/icons/SaveAlt';
import ScheduleIcon from '@material-ui/icons/Schedule';
import moment from 'moment';
import React, { forwardRef, useEffect, useState } from 'react';
import ReactExport from 'react-export-excel';
import ContentLoader from '../../components/ContentLoader';
import LeadActionPanel from './LeadActionPanel';
import { creategooglesheet } from '../../utils/base';
import Notification from '../../components/Notification';
import { get, post, useRequest } from '../../utils/request';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(0.5),
    },
  },
  button: {
    marginTop: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
  actionsContainer: {
    marginBottom: theme.spacing(2),
  },
  resetContainer: {
    padding: theme.spacing(3),
  },
  tableRow: {
    backgroundColor: white,
    '&:hover': {
      backgroundColor: lightgrey,
    },
  },
}));

const tableIcons = {
  Add: forwardRef((props, ref) => (
    <AddBox {...props} ref={ref} keyboard-shortcut="add-icon-handler" />
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: forwardRef((props, ref) => <FilterList {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowUpward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

function CustomStepper({ activity }) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(-1);

  function handleStep(index) {
    if (activeStep === index) {
      setActiveStep(-1);
    } else {
      setActiveStep(index);
    }
  }

  return (
    <Stepper activeStep={activeStep} nonLinear orientation="vertical">
      {activity.map((action, idx) => (
        <Step key={idx}>
          <StepButton
            onClick={() => {
              handleStep(idx);
            }}
            active={true}
          >
            {action.tour_type === 'tour_page'
              ? action.name + ' viewed custom tour home page'
              : action.url
              ? action.url
              : action.category_title + ' - ' + action.screen_title}
            {'  '}
            {action.form_data && (
              <div className="badge font s14 cfff">FORM SUBMITTED</div>
            )}
            {action.type === 'close_tour' && (
              <div className="badge font s14 cfff">CLOSED</div>
            )}
            {action.type === 'open_tour' && (
              <div className="badge font s14 cfff">OPENED</div>
            )}
          </StepButton>
          <StepContent>
            <div className={classes.actionsContainer}>
              {action.type === 'open_tour' && (
                <>
                  Tour opened from{' '}
                  <a href={action.url} target="_blank">
                    {action.url}
                  </a>
                </>
              )}
              {action.screen_title && (
                <>
                  <b>Screen title: </b>
                  {action.route && (
                    <a
                      href={
                        window.location.href.split('?')[0] +
                        '?back=#' +
                        action.route[0] +
                        '.' +
                        action.route[1]
                      }
                      target="_blank"
                    >
                      {action.screen_title}
                    </a>
                  )}
                  <br />
                </>
              )}
              {action.category_title && (
                <>
                  <b>Category title: </b>
                  <a href={window.location.href.split('?')[0]} target="_blank">
                    {action.category_title}
                  </a>{' '}
                  <br />
                </>
              )}
              {action.watch_time && (
                <>
                  <b>Watch time: </b>
                  {action.watch_time.toFixed(2)} seconds
                  <br />
                </>
              )}
              {action.form_data && (
                <>
                  <b>Form data: </b>
                  {Object.entries(action.form_data).map(([key, value]) => (
                    <>
                      <br />
                      {key}: {value}
                    </>
                  ))}{' '}
                  <br />
                </>
              )}
            </div>
          </StepContent>
        </Step>
      ))}
    </Stepper>
  );
}

const Downloadxlsx = ({ leads, setNotification }) => {
  const ExcelFile = ReactExport.ExcelFile;
  const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
  const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;
  console.log('leadds', leads);

  return (
    <ExcelFile
      element={
        <button
          //onClick={() => creategooglesheet(magnet_uuid)}
          onClick={() =>
            setNotification(
              'Location/OS data in file may be incomplete. Expand leads with missing data below to view.',
              'info'
            )
          }
          className="cleanbtn google-sheet-btn flex aic"
        >
          <div className=" ico icon-file-spreadsheet1 s20 c000" />
          <div className="font s12 c333">
            <b>Download xlsx</b>
          </div>
        </button>
      }
    >
      <ExcelSheet data={leads.leads} name="Leads">
        <ExcelColumn label="Leased?" value="leased" />
        <ExcelColumn label="Name" value="name" />
        <ExcelColumn label="Phone" value="phone" />
        <ExcelColumn label="Email" value="email" />
        <ExcelColumn
          label="Lead Type"
          value={(col) => col?.details?.lead_type}
        />
        <ExcelColumn
          label="Time"
          value={(col) => {
            console.log('excel col =', col);
            return moment(col.time).format('MMMM Do YY, h:mm a');
          }}
        />
        <ExcelColumn
          label="Note"
          value={(col) => {
            if (Array.isArray(col?.details?.metadata)) {
              // Concatenate all messages from the metadata array
              return col.details.metadata
                .map((item) => item.message)
                .filter(Boolean) // To exclude any undefined or null values
                .join(', ');
            }
            return (
              col?.details?.metadata?.message ?? 
              col?.details?.metadata?.tour_summary ?? 
              col?.details?.your_question ?? 
              col?.details?.question
            );
          }}
        />
        <ExcelColumn
          label="City"
          value={(col) => col?.details?.location?.city}
        />
        <ExcelColumn
          label="State"
          value={(col) => col?.details?.location?.region}
        />
        <ExcelColumn
          label="Country"
          value={(col) => col?.details?.location?.country}
        />
        <ExcelColumn label="OS" value={(col) => col?.details?.os} />
        <ExcelColumn
          label="UTM Source"
          value={(col) => col?.details?.utm_source}
        />
      </ExcelSheet>
    </ExcelFile>
  );
};

const SimpleLeads = ({
  magnet_uuid,
  name,
  showTitle = true,
  showLeadCount = true,
  leads,
  setLeads,
  setNotification,
}) => {
  const leadsPerPage = 10;
  const [typeSelection, setTypeSelection] = useState(1);
  const [groupSelection, setGroupSelection] = useState(1);
  const [loading, error, data, makeRequest] = useRequest({ loading: false });

  if (!leads) {
    return (
      <>
        <ContentLoader />
      </>
    );
  }

  return (
    <div id="visit-visitor-tables">
      <ContentLoader
        loading={!leads}
        error={
          leads.error
            ? 'Unable to get activity data at this time. If this issue persists please contact us.'
            : ''
        }
      >
        <div id="printMe">
          <MaterialTable
            localization={{
              toolbar: { searchPlaceholder: 'Search' },
            }}
            icons={tableIcons}
            options={{
              headerStyle: {
                backgroundColor: 'white',
                color: 'darkgrey',
                // border: "1px solid black",
                // borderCollapse: "collapse",
              },
              rowStyle: {
                backgroundColor: 'white',
              },
              filtering: true,
              pageSize: Math.min(leadsPerPage, leads?.leads?.length) || 0,
              pageSizeOptions: [5, 10, 20, leads?.leads?.length || 0],
            }}
            columns={[
              {
                title: 'Name',
                field: 'name',
                render: (rowData) => {
                  return (
                    <>
                      {rowData?.name ||
                        rowData?.details?.name ||
                        'Visitor ' + rowData.visitor_uuid}
                    </>
                  );
                },
                customSort: (a, b) => {
                  if (a.name === undefined) return 1;
                  if (b.name === undefined) return -1;
                  return b.name.localeCompare(a.name);
                },
                customFilterAndSearch: (term, rowData) =>
                  rowData.lead?.name
                    ?.toLowerCase()
                    .includes(term.toLowerCase()) ||
                  (!rowData.lead?.name &&
                    ('Visitor ' + rowData.visitor_uuid)
                      .toLowerCase()
                      .includes(term.toLowerCase())),
              },
              {
                title: 'Email',
                field: 'email',
                render: (rowData) => {
                  return <>{rowData?.email}</>;
                },
                customSort: (a, b) => {
                  if (a?.email === undefined) return 1;
                  if (b?.email === undefined) return -1;
                  return a?.email < b?.email ? 1 : -1;
                },
                customFilterAndSearch: (term, rowData) =>
                  rowData?.email?.toLowerCase().includes(term.toLowerCase()),
              },
              {
                title: 'Visit Date',
                field: 'visited_at',
                render: (rowData) => {
                  return (
                    <>
                     {
                      (rowData.updated_at && moment(rowData.updated_at).diff(moment(rowData.time)) > 10000)
                      && 
                       <div className={`badge font s14 cfff promotion`}>
                        <ScheduleIcon style={{ height: '15px' }} />{' '}
                        <p className="inline my-auto">
                          {moment(rowData.updated_at).format('MMMM Do YYYY')}
                        </p>
                       </div>
                     }
                      <div className={`badge font s14 cfff  info`}>
                        <ScheduleIcon style={{ height: '15px' }} />{' '}
                        <p className="inline my-auto">
                          {moment(Date.parse(rowData.time)).format('MMMM Do YYYY')}
                        </p>
                      </div>
                    </>
                  );
                },
                // customSort: (a, b) => {
                //   return moment(Date.parse(a.time)).diff(
                //     moment(Date.parse(b.time))
                //   );
                // },
                customSort: (a, b) => {
                  // If both updated_at are null or undefined, compare by time
                  if (!a.updated_at && !b.updated_at) {
                    return moment(a.time).diff(moment(b.time));
                  }
                
                  // If only a.updated_at exists, compare it with b.time
                  if (a.updated_at && !b.updated_at) {
                    return moment(a.updated_at).diff(moment(b.time));
                  }
                
                  // If only b.updated_at exists, compare it with a.time
                  if (!a.updated_at && b.updated_at) {
                    return moment(a.time).diff(moment(b.updated_at));
                  }
                
                  // If both updated_at exist, compare them
                  const updatedAtDiff = moment(a.updated_at).diff(moment(b.updated_at));
                
                  // If updated_at values are equal, compare by time
                  if (updatedAtDiff === 0) {
                    return moment(a.time).diff(moment(b.time));
                  }
                
                  return updatedAtDiff;
                },                               
                customFilterAndSearch: (term, rowData) =>
                  rowData.time
                    .toLowerCase()
                    .includes(term.replace('/', '-').toLowerCase()) ||
                  moment(Date.parse(rowData.time))
                    .format('MMMM Do YYYY, h:mm:ss a')
                    .toLowerCase()
                    .includes(term.toLowerCase()) ||
                  (
                    rowData?.visitor?.location?.city +
                    ', ' +
                    rowData?.visitor?.location?.state
                  )
                    .toLowerCase()
                    .includes(term.toLowerCase()),
                defaultSort: 'desc',
              },
              {
                title: 'Actions',
                field: 'actions',
                render: (rowData) => {
                  // based on type of tag, give different class name to change
                  let tags = rowData?.details?.leased
                    ? [{ color: 'bg-orange-500', value: 'LEASED' }]
                    : [];

                  const tagData = Array.isArray(rowData?.details?.metadata)
                    ? rowData?.details?.metadata
                    : [
                        {
                          ...rowData?.details,
                          lead_type: rowData?.details?.lead_type,
                        },
                      ];
                  for (const elem of tagData) {
                    if (elem.lead_type === 'Tour') {
                      if (elem.tour_type === 'Virtual') {
                        tags = [
                          ...tags,
                          { color: 'virtualTour', value: 'VIRTUAL TOUR' },
                        ];
                      } else {
                        tags = [
                          ...tags,
                          { color: 'inPersonTour', value: 'IN PERSON TOUR' },
                        ];
                      }
                    } else if (
                      elem.lead_type === 'Question' ||
                      elem.lead_type === 'Floor Plan'
                    )
                      tags = [
                        ...tags,
                        { color: 'question', value: 'QUESTION' },
                      ];
                    else if (elem.lead_type === 'Promotion')
                      tags = [...tags, { color: 'promotion', value: 'PROMO' }];
                    else if (elem.lead_type === 'Referral')
                      tags = [
                        ...tags,
                        { color: 'referral', value: 'REFERRAL' },
                      ];
                  }

                  return (
                    <>
                      {tags.map((tag) => {
                        return (
                          <div
                            className={`badge font s14 cfff mr-1 ${tag.color}`}
                            key={tag.value}
                          >
                            {tag.value}
                            
                          </div>
                        );
                      })}
                      {/* {rowData?.details?.leased && (
                        <div className="badge font s14 cfff danger">LEASED</div>
                      )} */}
                    </>
                  );
                },
                customSort: (a, b) => {
                  if (a?.leased) return -1;
                  return 1;
                },
                customFilterAndSearch: (term, rowData) =>
                  (rowData &&
                    rowData.details?.lead_type
                      ?.toLowerCase()
                      ?.includes(term.toLowerCase())) ||
                  (rowData.lead?.leased &&
                    'leased'.includes(term.toLowerCase())) ||
                  (rowData.details?.lead_type === 'Tour' &&
                    rowData.details.metadata.tour_type
                      .toLowerCase()
                      .includes(term.toLowerCase())),
              },
              {
                title: '',
                field: '',
                render: (rowData) => {
                  // based on type of tag, give different class name to change

                  return (
                    <>
                        <div>
                          {
                          rowData?.details?.entrata?.status === "error" ? 
                            <div
                              title={"Entrata status failed"} // Tooltip text
                              className="tooltip-icon"
                              style={{
                                display: 'inline-block',
                                marginLeft: '5px',
                                cursor: 'pointer',
                              }}
                            >
                              ❌
                            </div>
                            : rowData?.details?.entrata?.status === "success" ?
                            <div
                              title={"Entrata status failed"} // Tooltip text
                              className="tooltip-icon"
                              style={{
                                display: 'inline-block',
                                marginLeft: '5px',
                                cursor: 'pointer',
                              }}
                            >
                              ✅
                            </div>
                            : <></>
                          }
                        </div>
                      
                    </>
                  );
                },
              },
            ]}
            data={leads.leads}
            title={
              <div className="simple-leads-title flex aic">
                {showTitle && (
                  <Typography>Show {name}'s &nbsp; Leads</Typography>
                )}
                {showLeadCount && (
                  <button
                    onClick={() => creategooglesheet(magnet_uuid)}
                    className="cleanbtn google-sheet-btn flex aic"
                  >
                    <div className=" ico icon-users s22 c000" />
                    <div className="font s14 c333">
                      <b>
                        {leads?.leads && leads?.leads?.length > 0
                          ? `${leads?.leads?.length}`
                          : 'N/A'}
                      </b>
                    </div>
                  </button>
                )}
                {leads && (
                  <Downloadxlsx
                    leads={leads}
                    setNotification={setNotification}
                  />
                )}
              </div>
            }
            detailPanel={(rowData) => {
              return (
                <LeadActionPanel
                  leadData={rowData.rowData}
                  leads={leads}
                  setLeads={setLeads}
                  magnet_uuid={magnet_uuid}
                  groupVisitors={groupSelection}
                />
              );
            }}
            onRowClick={(event, rowData, togglePanel) => togglePanel()}
          />
        </div>
      </ContentLoader>
    </div>
  );
};

export default React.memo(SimpleLeads);
