import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

const objectKeyValueComparator = ([keyA, valueA], [keyB, valueB]) => {
  if (valueB === valueA) {
    return keyA.localeCompare(keyB);
  }
  return valueB - valueA;
};

export default function TopPages({ userVisitHistory }) {
  const { data: communityData } = useSelector((state) => {
    return state.listCommunityDetial;
  });

  const [historyByVisitNumbers, setHistoryByVisitNumbers] = useState();

  useEffect(() => {
    
    // setHistoryByVisitNumbers(
    //   userVisitHistory?.total?.pagesVisited?.reduce((acc, next) => {
    //     acc[next.path] = (acc[next.path] ?? 0) + 1;
    //     return acc;
    //   }, {})
    // );
  }, [userVisitHistory]);

  return (
    <div class="VisitorOverview__topPages snipcss-aBQL5 mt-4">
      <div class="VisitorOverview__topPages-header">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
          />
        </svg>
        Top pages visited
      </div>
      <div className="flex flex-col gap-2 w-full">
        {Object.entries(historyByVisitNumbers ?? {})
          ?.toSorted(objectKeyValueComparator)
          ?.map(([route, timesVisited]) => (
            <div className="flex gap-2 w-full">
              <a
                href={`${communityData?.[0]?.url}${route.slice(1)}`}
                target="_blank"
                className="flex-grow whitespace-nowrap"
              >
                {route === '/' ? 'homepage' : route}
              </a>
              <div className="flex-none">
                {timesVisited} visit{timesVisited !== 1 && 's'}
              </div>
            </div>
          ))}
      </div>

      <div class="VisitorOverview__topPages-header">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          className="w-6 h-6"
        >
          <path
            strokeLinecap="round"
            strokeLinejoin="round"
            d="M12 21a9.004 9.004 0 008.716-6.747M12 21a9.004 9.004 0 01-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 017.843 4.582M12 3a8.997 8.997 0 00-7.843 4.582m15.686 0A11.953 11.953 0 0112 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0121 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0112 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 013 12c0-1.605.42-3.113 1.157-4.418"
          />
        </svg>
        Goals Hit
      </div>
    </div>
  );
}
