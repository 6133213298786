import { useState, useEffect, useRef } from 'react';
import { getanalyicsbigquery } from '../../../utils/api';
import { HiOutlineLocationMarker } from 'react-icons/hi';
import { AiOutlineSend } from 'react-icons/ai';
import { Fragment } from 'react';
import { Menu, Transition } from '@headlessui/react';
import { supabase } from '../../../services/supabase';
import AISparkleIcon from '../../../assets/svg/AISparkleIcon';
import MessageIcon from '../../../assets/svg/MessageIcon';
import RefreshIcon from '@material-ui/icons/Refresh';
import moment from 'moment';
import {
  getRoom,
  getActiveRoom,
  getRoomCodes,
  getPeers,
} from '../../../utils/hms';
import firebase from '../../../utils/base';
import './InfoScreen.css';
import { sendrealtimemessage } from '../utils';
import Talk from 'talkjs';
import { SiGooglemeet } from 'react-icons/si';
import generateUsername from '../../../utils/usernameGeneration';
import TopPages from './TopPages';
import MessageBox from '../../../components/MessageBox'

function parseUserVisitHistory(userVisitHistory) {
  if (typeof userVisitHistory === 'string') {
    return {
      total: {
        pagesVisited: userVisitHistory.split(' => ').map((next) => {
          return { path: next };
        }, {}),
      },
    };
  } else {
    return userVisitHistory;
  }
}

function InfoScreen({
  children,
  onCall,
  visitorData,
  communityId,
  communityName,
  meChatId,
  setCobrowseKey,
  magnet_uuid,
  currentVisitorUuid,
  activevisitors,
  setactivevisitors,
  setMessages,
  messages,
  currentVisitorUuidRef
}) {
  const [rooms, setRooms] = useState([]);
  const [callUrl, setCallUrl] = useState('');
  const [greeting, setGreeting] = useState('');

  const getRoomIds = async () => {
    const { data, error } = await supabase
      .from('Community')
      .select('rooms')
      .eq('id', communityId);
    if (error) {
      console.error('Error fetching from supabase for roomIds', error);
      return;
    }
    if (!data.length) {
      console.error(`Community with id ${communityId} not found in supabase`);
      return;
    }
    return data[0].rooms;
  };

  const getRooms = async () => {
    // console.log('getRooms started');
    const newRoomsState = [];
    const roomIds = await getRoomIds();

    for (const roomId of roomIds) {
      // for loop because we don't want to break the control flow by calling this as an async callback

      const roomInfo = { id: roomId.id, enabled: roomId.enabled };

      if (roomId.enabled) {
        const [basicRoomInfo, activeRoomInfo, roomCodes] = await Promise.all([
          getRoom(roomId.id),
          getActiveRoom(roomId.id),
          getRoomCodes(roomId.id),
        ]);
        roomInfo.name = basicRoomInfo.name.replaceAll('_', ' ');
        // roomInfo.description = basicRoomInfo.description;

        if (activeRoomInfo.code === 404) {
          roomInfo.active = false;
        } else {
          roomInfo.active = true;
          roomInfo.started = activeRoomInfo.session.created_at;
          const peers = await getPeers(roomId.id);
          roomInfo.peers = Object.values(peers).map((elem) => elem.name);
        }

        roomInfo.roomCodes = roomCodes.map(({ code, role }) => ({
          code,
          role,
        }));
      }
      newRoomsState.push(roomInfo);
    }
    console.log('newRoomsState', newRoomsState);
    setRooms(newRoomsState);
    // console.log('getRooms ended naturally');
  };

  useEffect(() => {
    console.log('running get rooms');
    getRooms();
  }, []);

  useEffect(() => console.log('tourRooms', rooms), [rooms]);

  const [talkLoaded, markTalkLoaded] = useState(false);
  const chatboxEl = useRef();

  Talk.ready.then(() => markTalkLoaded(true));

  const conversationId = `${communityId}_${visitorData.visitorId}`;
  console.log('conversationId', conversationId);
  const me = {
    id: `${meChatId}`, // version 1 had a welcome message set and we dont like that
    name: `${firebase?.user()?.displayName?.split(' ')?.[0]}`,
    role: 'default',
  };

  const setMessageText = useRef(() => {});

  useEffect(() => {
    console.log('chat ready');
    if (talkLoaded) {
      // safe to use SDK here

      const currentUser = new Talk.User(me);

      // After `Talk.ready`
      // const otherUser = new Talk.User({
      //   id: visitorData.visitorId,
      //   name: `Anonymous from ${}`,
      //   email: 'unknown',
      //   role: 'default',
      // });

      const session = new Talk.Session({
        appId: 'tPxDGb1X',
        me: currentUser,
      });
      // const conversation = session.getOrCreateConversation(conversationId);
      const conversation = session.getOrCreateConversation(conversationId);
      conversation.setParticipant(currentUser);
      // conversation.setParticipant(otherUser);

      const chatbox = session.createChatbox();
      chatbox.select(conversation);

      // chatbox.messageField.typeText('bananas bananas bananas bananas bananas ');
      setMessageText.current = (text) => {
        chatbox.messageField.setText(text);
      };

      chatbox.onSendMessage(({ message }) => {
        initiateChat(visitorData.visitorId, message.text);
      });

      if (chatboxEl?.current) chatbox.mount(chatboxEl.current);

      return () => session.destroy();
    }
  }, [talkLoaded, visitorData, callUrl]);

  const initiateChat = (visitorId, message) => {
    Talk.ready.then(() => {
      console.log('talk ready now');
    });
    console.log('visitorId initiateChat', visitorId);
    sendrealtimemessage(visitorId, {
      sender: `${
        firebase?.user()?.displayName?.split(' ')?.[0]
      } from ${communityName}`,
      message: message,
      initials: firebase
        .user()
        .displayName.split(' ')
        .map((word) => word[0].toUpperCase())
        .join(''),
      conversationId: conversationId,
    });
  };


  

  return (
    <div className="p-2 my-2 mx-2 rounded">
      <section className="grid  grid-cols-1 gap-3 lg:grid-cols-3">
        <div
          key={visitorData.uuid}
          className={`p-4 border rounded-3xl ${
            callUrl ? 'lg:col-span-1' : 'lg:col-span-2'
          }  w-full`}
          style={{ background: '#FBFBFB', minHeight: '800px' }}
        >
          <div className="grid grid-cols-1 md:grid-cols-2 mb-6">
            <div className="col-span-1">
              <div className="flex items-center justify-start gap-3 w-full pb-2">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M11.5391 6.328C12.6546 6.328 13.5585 7.2319 13.5585 8.34735C13.5585 9.4628 12.6546 10.3667 11.5391 10.3667C10.4237 10.3667 9.51977 9.4628 9.51977 8.34735C9.51977 7.2319 10.4237 6.328 11.5391 6.328ZM11.5391 14.9823C14.3951 14.9823 17.4048 16.3863 17.4048 17.0017V18.0594H5.6734V17.0017C5.6734 16.3863 8.68319 14.9823 11.5391 14.9823ZM11.5391 4.50098C9.414 4.50098 7.69275 6.22223 7.69275 8.34735C7.69275 10.4725 9.414 12.1937 11.5391 12.1937C13.6642 12.1937 15.3855 10.4725 15.3855 8.34735C15.3855 6.22223 13.6642 4.50098 11.5391 4.50098ZM11.5391 13.1553C8.97167 13.1553 3.84637 14.4438 3.84637 17.0017V19.8865H19.2319V17.0017C19.2319 14.4438 14.1066 13.1553 11.5391 13.1553Z"
                    fill="black"
                  />
                </svg>
                <div>
                  <p className="text-gray-400">Name</p>
                  <h1 className="text-md font-bold pb-1">
                    {visitorData?.name
                      ? visitorData.name
                      : generateUsername(visitorData.visitorId)}
                  </h1>
                </div>
              </div>
              <div className="flex items-center justify-start gap-3 w-full pb-2">
                <HiOutlineLocationMarker className="w-5 h-5" />
                <div>
                  <p className="text-gray-400">Location</p>
                  <h1 className="text-md font-bold pb-1">
                    {' '}
                    {visitorData?.location
                      ? `${
                          visitorData.location?.city
                            ? visitorData.location?.city + ', '
                            : ''
                        }${
                          visitorData.location?.country
                            ? visitorData.location?.country + ', '
                            : ''
                        }${
                          visitorData.location?.continent
                            ? visitorData.location?.continent
                            : ''
                        }`
                      : 'Fetching location...'}
                  </h1>
                </div>
              </div>
              <div className="flex items-center justify-start gap-3 w-full pb-2">
                <div className="rounded-2xl h-3 w-3 bg-blue-400"></div>
                <div>
                  <p className="text-gray-400">Started Session</p>
                  <h1 className="text-md font-bold pb-1">
                    {moment(new Date(visitorData?.online_at)).fromNow()}
                  </h1>
                </div>
              </div>
            </div>
            <div className="flex md: justify-end">
              <img
                style={{ width: '300px', height: '150px' }}
                className="col-span-1 rounded-xl border"
                src={`https://maps.googleapis.com/maps/api/staticmap?key=AIzaSyDCLPeP901HX8XrtRAWo7JOjYWTVtKPffw&center=${visitorData.location?.latitude},${visitorData.location?.longitude}&size=300x150&zoom=3&markers=${visitorData.location?.latitude},${visitorData.location?.longitude}`}
              />
            </div>
          </div>

          <div className=" rounded-xl border border-gray-200 p-3">
            <div className="flex">
              <div class="SessionUrl__url">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M3.9 12C3.9 10.29 5.29 8.9 7 8.9H11V7H7C4.24 7 2 9.24 2 12C2 14.76 4.24 17 7 17H11V15.1H7C5.29 15.1 3.9 13.71 3.9 12ZM8 13H16V11H8V13ZM17 7H13V8.9H17C18.71 8.9 20.1 10.29 20.1 12C20.1 13.71 18.71 15.1 17 15.1H13V17H17C19.76 17 22 14.76 22 12C22 9.24 19.76 7 17 7Z"
                    fill="black"
                  />
                </svg>
                <p class="SessionUrl__url=text">{visitorData?.pageUrl}</p>
              </div>
              <button
                className="bg-white rounded-lg border border-gray-200 h-10 w-10 ml-1"
                onClick={() => {
                  setCobrowseKey(crypto.randomUUID());
                }}
              >
                <RefreshIcon />
              </button>
            </div>

            <div>{children}</div>
          </div>

          <TopPages
            userVisitHistory={parseUserVisitHistory(
              visitorData.currentTourHistoryObj?.uservisitHistory
            )}
          />
        </div>

        <div
          className={`p-4 border rounded-3xl ${
            callUrl ? 'lg:col-span-2' : 'lg:col-span-1'
          }`}
          style={{ background: '#FBFBFB', minHeight: '800px' }}
        >
          {/* <h1 className="text-3xl font-bold mb-4">Reach Out</h1> */}
          {callUrl ? (
            <>
              <iframe
                style={{
                  height: '800px',
                  maxHeight: '90vh',
                  borderRadius: '12px',
                }}
                className="w-full"
                src={callUrl}
                title="embeddedVideoCall"
                allow="camera *; microphone *; autoplay *; encrypted-media *; fullscreen *; display-capture *;"
              ></iframe>
              <div className="flex justify-center mt-4 mb-8">
                <button
                  className="border border-radius-4 p-2"
                  onClick={() => {
                    setCallUrl('');
                  }}
                >
                  X Close Meeting Frame
                </button>
              </div>
            </>
          ) : (
            <>
            <MessageBox currentVisitorUuidRef={currentVisitorUuidRef} messages={messages} setMessages={setMessages}  setactivevisitors={setactivevisitors} activevisitors={activevisitors} magnet_uuid={magnet_uuid} visitorUuid={currentVisitorUuid} visitorData={visitorData} />
            {/* <div>
              <div
                key={me.id}
                ref={chatboxEl}
                style={{ height: '600px' }}
              ></div>
              <button onClick={() => setMessageText.current('testing')}>
                Set Message
              </button>
              <h1 className="text-xl font-bold mt-8">Invite to Tour Room</h1>
              <textarea
                onChange={(e) => setGreeting(e.target.value)}
                value={greeting}
                placeholder="Write a custom invitation message, or let AI generate one. Example: 'I saw you were looking at our 4x4 floorplan, is ther any question I can answer for you?'"
                className="w-full rounded-xl resize-none p-2 border-gray-300 border mt-2 mb-2 h-32"
              ></textarea>
            </div> */}
            </>
          )}
          <div className="flex-col items-start gap-2">
            <Menu as="div" className="relative inline-block text-left w-full">
              <Menu.Button
                style={{ background: '#3898ec' }}
                className={`p-3 w-full rounded-xl font-bold text-white text-lg flex gap-3 itmes-center justify-center`}
              >
                <SiGooglemeet className="h-7" />
                Invite to Tour Room
              </Menu.Button>

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <Menu.Items className="absolute right-0 z-10 mt-2 w-56  origin-top-right rounded-md bg-white shadow-md ring-1 ring-black ring-opacity-5 focus:outline-none">
                  {rooms.length > 0 ? (
                    rooms.map((room) => (
                      <div>
                        <Menu.Item
                          onClick={() => {
                            setCallUrl(
                              `https://meet.tour.video/preview/${
                                room.roomCodes[0].code
                              }?skip_preview_headful=true&name=${
                                firebase.user().displayName
                              }&cid=${communityId}&uid=${firebase.uid()}`
                            );
                            onCall(
                              visitorData.visitorId,
                              `https://meet.tour.video/preview/${room.roomCodes[1].code}`,
                              greeting
                            );
                          }}
                        >
                          <p className="px-4 py-2  text-sm flex items-center gap-2 hover:bg-gray-100 cursor-pointer font-semibold">
                            {room.name}
                          </p>
                        </Menu.Item>
                      </div>
                    ))
                  ) : (
                    <p className="px-4 py-2  text-sm text-gray-500 flex items-center gap-2 hover:bg-gray-100 cursor-pointer font-semibold">
                      Loading rooms...
                    </p>
                  )}
                </Menu.Items>
              </Transition>
            </Menu>
          </div>
          {/* <div className="">
            <form
              onSubmit={(e) => e.preventDefault()}
              className="flex px-2 py-2 border w-full items-center gap-2"
            >
              <input
                required
                placeholder="Send user a message"
                type="text"
                className="w-full"
              />
              <button type="submit">
                <AiOutlineSend className="w-5 h-5 text-gray-500" />
              </button>
            </form>
          </div> */}
        </div>
      </section>
    </div>
  );
}

export default InfoScreen;
