import { makeStyles } from '@material-ui/core/styles';
import Rating from '@material-ui/lab/Rating';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { AiFillCloseCircle, AiOutlinePlus } from 'react-icons/ai';
import { BiEditAlt } from 'react-icons/bi';
import { HiVideoCamera } from 'react-icons/hi';
import { IoCheckmarkOutline } from 'react-icons/io5';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import rimg from '../../assets/createReview.png';
import { isValidURL } from '../../core';
import SendRecording from '../../views/manage-magnets/SendRecording';
import AnimatedDialog from '../Reusable/AnimatedDialog';
import EditReview from './EditReviewSettings';
import ThankYouDialog from './ThankyouDialogue';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    '& > * + *': {
      marginTop: theme.spacing(1),
    },
  },
}));
function cleaner(str) {
  if (str) {
    var strLower = str.toLowerCase();
    return strLower.replace(/\W/g, '');
  }

  return false;
}

export const EditReviewModal = ({ open, setOpen }) => {
  return (
    <AnimatedDialog
      open={open}
      title="Edit Review Settings"
      onClose={() => setOpen(false)}
    >
      <button
        onClick={() => setOpen(false)}
        type="button"
        class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
      >
        <span class="sr-only">Close menu</span>
        <svg
          class="h-6 w-6"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          aria-hidden="true"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke-width="2"
            d="M6 18L18 6M6 6l12 12"
          />
        </svg>
      </button>

      <EditReview setOpenEditReviewModal={setOpen} />
    </AnimatedDialog>
  );
};
const CreateReview = ({
  fullHeight = false,
  hideReviewSettings = true,
  personalizedShareRequestor = '',
  tempaskGoogleReview = 0,
  tempselectedTeamMemberIndex,
  url,
}) => {
  const _dispatch = useDispatch();
  const { community_id } = useParams();

  const [askGoogleReview, setAskGoogleReview] = useState(tempaskGoogleReview);
  const [selectedTeamMemberIndex, setSelectedTeamMemberIndex] = useState(
    tempselectedTeamMemberIndex
  );

  useEffect(() => {
    setSelectedTeamMemberIndex(tempselectedTeamMemberIndex);
  }, [tempselectedTeamMemberIndex]);

  useEffect(() => {
    setAskGoogleReview(tempaskGoogleReview);
  }, [tempaskGoogleReview]);

  const [team, setteam] = useState([]);

  const [review, setReview] = useState({
    phone: '',
    name: '',
    touchpoints: {},
    //id: "",
    pic: '',
    communitybadges: [
      {
        name: 'Great amenities',
        category: '',
      },
      {
        name: 'Helpful staff',
        category: '',
      },
      {
        name: 'Awesome location',
        category: '',
      },
    ],
    email: '',
    question: [
      {
        inputvideo: true,
        question: '',
        questiontype: 'default',
        required: false,
        text: true,
        useranswer: '',
      },
    ],
  });
  const [allreviews, setallreviews] = useState([]);

  const [reviewPostModel, setReviewPostModel] = useState({
    name: '',
    email: '',
    rating: '',
    badge: [],
    text: [],
    video: [],
  });

  const [textwrite, setTextwrite] = useState(false);

  const [selectedtext, setselectedtext] = useState('');
  const [communityData, setCommunityData] = useState(null);
  // console.log(communityData, 'communityData');
  const [openEditReviewModal, setOpenEditReviewModal] = useState(false);
  const [openThankYou, setOpenThankYou] = useState(false);

  const [directualCommunity, setDirectualCommunity] = useState({});
  const [rating, setRating] = useState(5);

  const checkRating = () => {
    if (rating < 3.5) {
      setTimeout(() => {
        window.location.href = 'http://google.com/';
      }, 8000);
    } else {
      setTimeout(() => {
        window.location.href = 'http://google.com/';
      }, 2000);
    }
  };
  console.log('reviewrrr', review);
  const submitreview = async () => {
    let tempTeam = team;
    console.log('selectedTeamMemberIndext12', selectedTeamMemberIndex);
    console.log('tempTeamt12', tempTeam);
    if (!review.name) {
      return toast(`Please enter your name`, {
        position: 'top-center',
        type: 'error',
        autoClose: 5000,
      });
    }
    if (!review.email) {
      return toast(`Please enter your email`, {
        position: 'top-center',
        type: 'error',
        autoClose: 5000,
      });
    }
    if (selectedTeamMemberIndex && selectedTeamMemberIndex > -1) {
      tempTeam[selectedTeamMemberIndex] = {
        ...tempTeam?.[selectedTeamMemberIndex],
        stats: {
          reviews: tempTeam?.[selectedTeamMemberIndex]?.stats
            ? tempTeam?.[selectedTeamMemberIndex]?.stats.reviews + 1
            : 1,
        },
      };
    }
    console.log('selectedTeamMemberIndex', tempTeam);

    try {
      review.reviewfor = {
        email: team?.[selectedTeamMemberIndex]?.email,
        name: team?.[selectedTeamMemberIndex]?.name,
      };
      let filledform = true;
      review?.question?.map((item) => {
        if (!item.useranswer && !item.video) {
          filledform = false;
        }
      });

      if (askGoogleReview && askGoogleReview == 0) {
        if (!filledform) {
          return toast(`Please answer all the question before submitting`, {
            position: 'top-center',
            type: 'error',
            autoClose: 5000,
          });
        }
      }
      console.log('reviewrrr', review);
      let thereviews;
      if (allreviews?.reviews) {
        thereviews = [
          { ...review, rating, selectcommunityBadges, date: new Date() },
          ...allreviews?.reviews,
        ];
      } else {
        thereviews = [
          { ...review, rating, selectcommunityBadges, date: new Date() },
        ];
      }

      let postData = {
        id: community_id,
        reviews: {
          reviews: [...thereviews],
        },
        team: { team: tempTeam },
      };
      console.log('Posting to the server', postData);
      var myHeaders = new Headers();
      myHeaders.append('Content-Type', 'application/json');
      var raw = JSON.stringify(postData);
      var requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: raw,
        redirect: 'follow',
      };

      fetch(
        `https://api.directual.com/good/api/v5/data/community/newCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`,
        requestOptions
      )
        .then((response) => response.json())
        .then((result) => {
          toast(`Review submitted! Thank you for taking the time to submit`, {
            position: 'top-center',
            type: 'success',
            autoClose: 5000,
          });
          setOpenThankYou(true);
          if (askGoogleReview == 1) {
            checkRating();
          }
        })
        .catch((error) => console.log('error', error));
      // const community = await axios.post( `https://api.directual.com/good/api/v5/data/community/newCommunity?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&sessionID=`)
    } catch (error) {
      console.log('error', error);
      console.log('error', error.response);
    }
  };

  const submitspecialreview = async () => {
    try {
      toast(
        `Please Hold tight don't leave yet, taking you on to a rocket ship! 🚀`,
        {
          position: 'top-center',
          type: 'success',
          autoClose: 5000,
        }
      );
    } catch (error) {
      console.log('error', error);
      console.log('error', error.response);
    }
  };
  const getcommunity = async () => {
    try {
      const res = await axios.get(
        `https://api.directual.com/good/api/v5/data/community/getCommunityById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${community_id}`
      );
      console.log('responseres', res);
      console.log(
        'communitybadges',
        JSON.parse(res.data?.payload[0]?.communitybadges)
      );
      if (res.data) {
        setCommunityData({
          id: res.data?.payload?.[0]?.id,
          name: res.data?.payload?.[0]?.name,
          logo: JSON.parse(res.data?.payload?.[0]?.settings)?.logo,
        });

        if (res.data?.payload[0]?.questions) {
          if (JSON.parse(res.data?.payload[0]?.questions)) {
            setReview({
              ...review,
              question:
                JSON.parse(res.data?.payload[0]?.questions)?.questions?.length >
                0
                  ? JSON.parse(res.data?.payload[0]?.questions)?.questions
                  : [
                      {
                        inputvideo: true,
                        question: '',
                        required: false,
                        text: true,
                        useranswer: '',
                        questiontype: 'default',
                      },
                    ],
            });
          }
        }
        if (res.data?.payload[0]?.communitybadges) {
          if (JSON.parse(res.data?.payload[0]?.communitybadges)) {
            setReview({
              ...review,
              communitybadges: res.data?.payload[0]?.communitybadges
                ? JSON.parse(res.data?.payload[0]?.communitybadges)
                    ?.communitybadges
                : [],
            });
          }
        }
        console.log('checkrere it', res.data?.payload?.[0]);
        setallreviews(JSON.parse(res.data?.payload?.[0]?.reviews));
        if (res.data?.payload[0]?.team) {
          setteam(JSON.parse(res.data?.payload[0].team).team);
        }
      } else {
        console.log('response_empty');
      }
    } catch (error) {
      console.log('errr======>', error);
    }
  };
  const [defaultquestion, setdefaultquestion] = useState({
    text: true,
    question: '',
    useranswer: '',
    required: false,
    inputvideo: true,
  });
  useEffect(() => {
    getcommunity();
    console.log(
      'selectedTeamMemberIndex',
      selectedTeamMemberIndex,
      tempselectedTeamMemberIndex
    );
    const urlParams = new URLSearchParams(window.location.search);

    let u = urlParams.get('u');
    let g = urlParams.get('g');

    if (u) {
      setSelectedTeamMemberIndex(parseInt(u, 10));
    }
    if (g) {
      setAskGoogleReview(parseInt(g, 10));
    }
  }, [openEditReviewModal]);

  function capitalizeFirstLetter(string) {
    if (!string) {
      return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const [index, setIndex] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState([]);
  const [selectedQuestionIndex, setSelectedQuestionIndex] = useState(null);
  const [selectedVideo, setSelectedVideo] = useState('');
  const [videoPlayerModal, setVideoPlayerModal] = useState(false);
  const [selectcommunityBadges, setselectcommunityBadges] = useState([]);
  const handleChangetext = (ind) => {
    if (text.find((e) => e.index !== ind)) {
      setSelectedIndex([...selectedIndex, ind]);
    }
  };
  const Badge = ({ item }) => {
    //const [selectBadge, setSelectBadge] = useState(false);
    const manageselctedbadges = () => {
      const theindex = selectcommunityBadges.findIndex(
        (item2) => item.name == item2.name
      );
      if (theindex > -1) {
        selectcommunityBadges.splice(theindex, 1);
      } else {
        selectcommunityBadges.push(item);
      }
      setselectcommunityBadges([...selectcommunityBadges]);
    };
    const theindex = selectcommunityBadges.findIndex(
      (item2) => item.name == item2.name
    );
    return (
      <p
        onClick={() => manageselctedbadges()}
        className={
          theindex < 0
            ? 'bg-gray-200 cursor-pointer px-3 text-xs py-2 rounded-3xl flex items-center gap-1'
            : 'bg-pr text-white cursor-pointer px-3 text-xs py-2 rounded-3xl flex items-center gap-1'
        }
      >
        {theindex < 0 ? (
          <AiOutlinePlus className=" w-2 h-2" />
        ) : (
          <IoCheckmarkOutline />
        )}{' '}
        {item?.name}
      </p>
    );
  };

  const openRecordVideoModal = () => {
    _dispatch({
      type: 'SHOW_SCREEN_RECORDING',
      payload: true,
    });
  };
  const handleCloseVideoModal = () => {
    setVideoPlayerModal(false);
  };

  return (
    <div
      className={` w-full flex items-start justify-start ${
        fullHeight ? 'min-h-screen' : ''
      }`}
    >
      <div className=" w-full lg:w-96  border-2 border-gray-500 rounded-md p-3 shadow-lg bg-white">
        {!hideReviewSettings && ReviewSettings()}

        {/* <EditReview /> */}
        <EditReviewModal
          openEditReviewModal={openEditReviewModal}
          setOpenEditReviewModal={setOpenEditReviewModal}
        />
        <ThankYouDialog
          open={openThankYou}
          setOpen={setOpenThankYou}
          askGoogleReview={askGoogleReview}
        />
        <div className=" flex items-center justify-center w-full relative">
          <div className=" relative">
            <img
              src={communityData?.logo || rimg}
              className=" w-16 h-16"
              alt=""
            />
            {team?.length !== 0 && selectedTeamMemberIndex !== null && (
              <>
                {team?.[selectedTeamMemberIndex]?.pic && (
                  <img
                    src={team?.[selectedTeamMemberIndex]?.pic}
                    className=" absolute -bottom-1 -right-2 z-10 h-7 w-7 rounded-full"
                    style={{ objectFit: 'cover' }}
                    alt=""
                  />
                )}
              </>
            )}
          </div>
        </div>
        <h5 className=" pt-3 pb-1 w-full text-center text-xl font-semibold">
          {communityData?.name}
        </h5>
        <p className=" text-sm pb-4 w-full text-center font-medium">
          {team?.length !== 0 && selectedTeamMemberIndex !== undefined
            ? `Could you leave ${capitalizeFirstLetter(
                team?.[selectedTeamMemberIndex]?.name?.split(' ')[0]
              )} a review?`
            : 'Could you leave us a review?'}
        </p>
        {textwrite === false ? (
          <>
            {' '}
            {askGoogleReview !== 0 ? (
              <>
                {/* Leave a Google Review Option             */}
                <div className=" w-full border-b-2">
                  <p className=" text-sm font-semibold">Name</p>
                  <input
                    type="text"
                    className=" bg-transparent outline-none w-full py-1 text-sm"
                    placeholder="Full Name"
                    onChange={(e) =>
                      setReview({ ...review, name: e.target.value })
                    }
                    value={review.name}
                  />
                </div>
                <div className=" w-full border-b-2 mt-3">
                  <p className=" text-sm font-semibold">Email</p>
                  <input
                    type="text"
                    className=" bg-transparent outline-none w-full py-1 text-sm"
                    placeholder="your@gmail.com"
                    onChange={(e) =>
                      setReview({ ...review, email: e.target.value })
                    }
                    value={review.email}
                  />
                </div>
              </>
            ) : (
              <>
                <div className=" w-full border-b-2">
                  <p className=" text-sm font-semibold">Name</p>
                  <input
                    type="text"
                    className=" bg-transparent outline-none w-full py-1 text-sm"
                    placeholder="Full Name"
                    onChange={(e) =>
                      setReview({ ...review, name: e.target.value })
                    }
                    value={review.name}
                  />
                </div>
                <div className=" w-full border-b-2 mt-3">
                  <p className=" text-sm font-semibold">Email</p>
                  <input
                    type="text"
                    className=" bg-transparent outline-none w-full py-1 text-sm"
                    placeholder="your@gmail.com"
                    onChange={(e) =>
                      setReview({ ...review, email: e.target.value })
                    }
                    value={review.email}
                  />
                </div>
                {review?.question?.length !== 0 && (
                  <div className=" mt-4 flex w-full flex-col gap-3">
                    {review?.question?.map((item, ind) => (
                      <>
                        <div>
                          <h5 className=" font-semibold text-sm">
                            {item?.questiontype != 'default' && `${ind + 1}.`}{' '}
                            {item.question}
                          </h5>
                          <div className="pt-2 flex items-center w-full">
                            <button
                              //disabled={!item.video}
                              onClick={() => {
                                setSelectedQuestionIndex(ind);
                                openRecordVideoModal();
                              }}
                              className="flex flex-col w-1/2 items-center gap-2 py-3 px-2 border rounded-md text-xs md:text-sm mr-3"
                            >
                              <div className="icon-camera-record text-lg" />
                              <span>Record a Video</span>
                            </button>
                            <button
                              onClick={() => {
                                setTextwrite(true);
                                setIndex(ind);
                                setselectedtext(item?.useranswer);
                              }}
                              className=" flex flex-col w-1/2 items-center gap-2 py-3 px-2 border  rounded-md text-sm"
                            >
                              <div className="icon-clipboard-pencil text-lg" />
                              <span>
                                {' '}
                                {item?.useranswer
                                  ? `Edit your answer ${
                                      item?.useranswer?.split(' ')?.length
                                    } words`
                                  : 'Send in text'}
                              </span>
                            </button>
                          </div>
                        </div>
                        {item?.video && isValidURL(item?.video) && (
                          <div className="rit">
                            <div
                              className="video-blk rel"
                              onClick={() => {
                                setVideoPlayerModal(true);
                                setSelectedVideo(
                                  item?.video + `?t=${item?.duration}`
                                );
                              }}
                            >
                              <div className="overlay">
                                <div className="ico icon-play_arrow" />
                              </div>
                              <video className="video" src={item?.video} />
                            </div>
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                )}
                {review?.question?.length === 0 && (
                  <div className=" w-full pb-20">
                    <div className=" pt-3 flex items-center justify-center gap-2">
                      <button
                        onClick={() => {
                          openRecordVideoModal();
                          setSelectedQuestionIndex(0);
                        }}
                        className=" flex items-center gap-2 py-2 px-2 border bg-black text-white rounded-md text-sm"
                      >
                        <HiVideoCamera /> Record a Video
                      </button>
                      <p className=" text-xs">Or</p>

                      {selectedIndex.find((e) => e === 0) !== undefined ? (
                        <button
                          onClick={() => {
                            //setText(text.filter((e) => e.index !== 0));
                            setSelectedIndex(
                              selectedIndex.filter((e) => e !== 0)
                            );
                          }}
                          className=" flex items-center gap-2 py-2 px-2 border  rounded-md text-sm"
                        >
                          <BiEditAlt /> 1 Written text <AiFillCloseCircle />
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setTextwrite(true);
                            setIndex(0);
                          }}
                          className=" flex items-center gap-2 py-2 px-2 border  rounded-md text-sm"
                        >
                          <BiEditAlt /> Send in text
                        </button>
                      )}
                    </div>
                    {/* {item?.video && isValidURL(item?.video) && (
                          <div className="rit">
                            <div
                              className="video-blk rel"
                              onClick={() => {
                                setVideoPlayerModal(true);
                                setSelectedVideo(
                                  item?.video + `?t=${item?.duration}`
                                );
                              }}
                            >
                              <div className="overlay">
                                <div className="ico icon-play_arrow" />
                              </div>
                              <video className="video" src={item?.video} />
                            </div>
                          </div>
                        )} */}
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          <>
            <p className=" text-sm font-semibold">Write Your Review here</p>
            <form
              className=" w-full"
              onSubmit={(e) => {
                e.preventDefault();
                review.question[index].useranswer = selectedtext;
                console.log('inpuuting', review.question[index].useranswer);
                setReview({ ...review, question: [...review.question] });
                setTextwrite(false);
                setselectedtext('');
              }}
            >
              <textarea
                placeholder="Ex: It was a really awesome experience!"
                className=" border py-2 px-3 w-full rounded-md mt-2 text-sm"
                name=""
                onChange={(e) => {
                  setselectedtext(e.target.value);
                  //handleChangetext(index);
                }}
                value={selectedtext}
                id=""
                required
                cols="30"
                rows="10"
              ></textarea>
              <div className=" pt-3 flex items-center justify-between gap-3">
                <button
                  onClick={() => {
                    setTextwrite(false);
                    //setText('');
                  }}
                  className=" flex items-center text-xs gap-2 py-2 px-2 border bg-black text-white rounded-md "
                >
                  Back
                </button>

                <button
                  // onClick={() => {
                  //   setTextwrite(false);
                  // }}
                  type="submit"
                  className=" flex items-center text-xs gap-2 py-2 px-2 border bg-black text-white rounded-md "
                >
                  Submit
                </button>
              </div>
            </form>
          </>
        )}
        <>
          {
            <>
              <div>
                <p className=" text-sm font-semibold pt-3">Rating {rating}</p>
                <Rating
                  name="half-rating"
                  value={rating}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                  precision={0.5}
                />
              </div>
              <p className=" text-sm font-semibold py-2">Badge</p>
              <div className=" flex items-center flex-wrap gap-x-1 gap-y-2">
                {review?.communitybadges &&
                  review?.communitybadges?.length !== 0 && (
                    <>
                      {review?.communitybadges?.map((item, ind) => (
                        <Badge key={ind} item={item} />
                      ))}
                    </>
                  )}
              </div>
              {askGoogleReview == 0 ? (
                <button
                  onClick={() => {
                    submitreview();
                    //setOpenThankYou(true)
                  }}
                  className=" mt-3 w-full bg-black py-2  flex items-center justify-center text-white rounded-sm "
                >
                  Submit
                </button>
              ) : (
                <button
                  class="h-12 mt-3 text-white w-full rounded bg-black hover:bg-gray-700 "
                  onClick={() => submitreview()}
                >
                  <i class="fa fa-google mr-2"></i>Send to Google Review
                </button>
              )}
            </>
          }
        </>
      </div>
      {/* 
      

          {review?.question
                      ? review?.question[questionIndex]?.question
                      : ''}

 */}

      {selectedQuestionIndex != null && (
        <SendRecording
          setdefaultquestion={setdefaultquestion}
          selectedQuestionIndex={selectedQuestionIndex}
          questionToAsk={
            review?.question
              ? review?.question[selectedQuestionIndex]?.question
              : ''
          }
          defaultquestion={defaultquestion}
          onVideoUrl={(url) => {
            //console.log('url selectedQuestionIndex',url,indxx)
            const updatedQuestion = review.question.map((field, i) => {
              if (i == selectedQuestionIndex) {
                return { ...field, video: url };
              }
              return field;
            });

            setReview && setReview({ ...review, question: updatedQuestion });
          }}
        />
      )}
      <AnimatedDialog open={videoPlayerModal} onClose={handleCloseVideoModal}>
        <div className="video-player-modal">
          <div className="hdr">
            <button className="cross-btn font" onClick={handleCloseVideoModal}>
              &times;
            </button>
          </div>
          <div className="video-play">
            <video
              title="Video Preview"
              poster="/images/black.png"
              src={selectedVideo}
              autoplay="true"
              loop
              className="video"
              controls
            />
          </div>
        </div>
      </AnimatedDialog>
    </div>
  );

  function ReviewSettings() {
    const feedbackUrl =
      `https://feedbackjoy.com/surveys/${community_id}/@${cleaner(
        communityData?.name
      )}` +
      (team?.[selectedTeamMemberIndex]?.name !== undefined
        ? `?u=${selectedTeamMemberIndex}`
        : '') +
      (askGoogleReview !== undefined && askGoogleReview > 0
        ? `&g=${askGoogleReview}`
        : '');
    return (
      <label className="relative text-gray-600 mt-2 mb-3.5 border rounded-md border-gray-600 px-3 focus-within:border-gray-400 flex items-center  overflow-hidden w-95">
        <div className="icon-link text-lg" />
        <a href={feedbackUrl} target="_blank">
          <span className="form-input  py-3 px-2.5 text-sm bg-white placeholder-gray-400 appearance-none w-full block focus:outline-none text-sky-500">
            {feedbackUrl}
          </span>
        </a>
      </label>
    );
  }
};

export default CreateReview;
