import { useState, useEffect, useRef } from 'react';
import AddIcon from '@material-ui/icons/Add';
import Skeleton from '@material-ui/lab/Skeleton';
import { supabase } from '../../services/supabase';
import {
  getRoom,
  getActiveRoom,
  getPeers,
  getRoomCodes,
  updateRoom,
  createRoom,
  regenerateRoom,
  disableRoom,
} from '../../utils/hms';
import { v4 as uuidv4 } from 'uuid';
import { CircularProgress } from '@material-ui/core';
import { RoomCard } from './RoomCard';

const ROOM_LIMIT = 9;

const OpenRooms = ({
  communityId,
  setNotification,
  externalRooms,
  setExternalRooms,
  externalInitialLoading,
  setExternalInitialLoading,
  communityName,
}) => {
  const [rooms, setRooms] =
    externalRooms != null ? [externalRooms, setExternalRooms] : useState([]);
  const [initialLoading, setInitialLoading] =
    externalInitialLoading != null
      ? [externalInitialLoading, setExternalInitialLoading]
      : useState(true); // just for initial load
  const [creatingRoom, setCreatingRoom] = useState(false);
  const [deletingRoom, setDeletingRoom] = useState(false);
  const [numEditing, setNumEditing] = useState(0);
  const interruptRefresh = useRef(false);

  // There's a ref here and weird encapsulation because setInterval uses the same function
  // each time
  // source: https://overreacted.io/making-setinterval-declarative-with-react-hooks/

  const canCreateRoom = () => {
    return (
      !creatingRoom &&
      rooms.reduce((acc, room) => acc + room.enabled, 0) < ROOM_LIMIT
    );
  };

  const getSupabaseVersionOfState = (rooms) => {
    return rooms.map(({ id, enabled, name, roomCodes }) => ({
      id,
      enabled,
      name,
      roomCodes,
    }));
  };

  const updateSupabaseRoomsArray = async (newSupabaseRooms) => {
    const { error } = await supabase
      .from('Community')
      .update({ rooms: newSupabaseRooms })
      .eq('id', communityId);
    if (error) {
      console.error(
        `Error creating rooms for community ${communityId} in supabase:`,
        error
      );
    }
  };

  const getRoomIds = async () => {
    const { data, error } = await supabase
      .from('Community')
      .select('rooms')
      .eq('id', communityId);
    if (error) {
      console.error('Error fetching from supabase for roomIds', error);
      return;
    }
    if (!data.length) {
      console.error(`Community with id ${communityId} not found in supabase`);
      return;
    }
    if (initialLoading) setRooms(data[0].rooms);
    return data[0].rooms;
  };

  const setTitle = async (index, newTitle) => {
    interruptRefresh.current = true;
    setRooms((rooms) => {
      const newSupabaseRooms = getSupabaseVersionOfState(rooms);
      newSupabaseRooms[index].name = newTitle;
      updateSupabaseRoomsArray(newSupabaseRooms);
      return [
        ...rooms.slice(0, index),
        { ...rooms[index], name: newTitle.replaceAll('_', ' ') },
        ...rooms.slice(index + 1, rooms.length),
      ];
    });
    await updateRoom(rooms[index].id, { name: newTitle.replaceAll(' ', '_') });
    interruptRefresh.current = false;
  };

  const getRooms = async () => {
    // console.log('getRooms started');
    const newRoomsState = [];
    const roomIds = await getRoomIds();

    for (const roomId of roomIds) {
      // abort fetch if updating while fetching
      if (creatingRoom || deletingRoom || interruptRefresh.current) {
        // console.log(
        //   'getRooms ended due to creatingRoom or deletingRoom or shouldInterrupt',
        //   creatingRoom,
        //   deletingRoom
        // );
        return;
      }
      // for loop because we don't want to break the control flow by calling this as an async callback

      const roomInfo = {
        id: roomId.id,
        enabled: roomId.enabled,
        name: roomId.name,
      };

      if (roomId.enabled) {
        // if room name is changed externally in 100ms dashboard, this dashboard will
        // not know about that
        const [activeRoomInfo, roomCodes] = await Promise.all([
          getActiveRoom(roomId.id),
          getRoomCodes(roomId.id),
        ]);

        if (activeRoomInfo.code === 404) {
          roomInfo.active = false;
        } else {
          roomInfo.active = true;
          roomInfo.started = activeRoomInfo.session.created_at;
          const peers = await getPeers(roomId.id);
          roomInfo.peers = Object.values(peers).map((elem) => elem.name);
        }

        roomInfo.roomCodes = roomCodes.map(({ code, role }) => ({
          code,
          role,
        }));
      }
      newRoomsState.push(roomInfo);
    }
    if (initialLoading) {
      const newSupabaseRooms = getSupabaseVersionOfState(newRoomsState);
      await updateSupabaseRoomsArray(newSupabaseRooms);
    }
    setRooms(newRoomsState);
    // console.log('getRooms ended naturally');
    setInitialLoading(false);
  };

  const deleteRoom = async (index) => {
    const yeahTheyActuallyGonnaDeleteIt = confirm(
      `Are you sure you'd like to delete ${rooms[index].name}?`
    );
    if (!yeahTheyActuallyGonnaDeleteIt) return;
    interruptRefresh.current = true;

    setDeletingRoom(true);
    const newSupabaseRooms = getSupabaseVersionOfState(rooms);
    newSupabaseRooms[index].enabled = false;
    await updateSupabaseRoomsArray(newSupabaseRooms);
    await disableRoom(rooms[index].id);

    setRooms((rooms) => [
      ...rooms.slice(0, index),
      ...rooms.slice(index + 1, rooms.length),
      { id: rooms[index].id, enabled: false },
    ]);
    setDeletingRoom(false);
    interruptRefresh.current = false;
  };

  const generateRoomName = () => {
    let enabledRoomsNumber = rooms.filter(({ enabled }) => enabled).length;
    while (
      rooms.find(
        ({ name }) => name === `${communityName} Room ${enabledRoomsNumber}`
      )
    ) {
      enabledRoomsNumber++;
    }
    return `${communityName} Room ${enabledRoomsNumber}`.replaceAll(' ', '_');
  };

  const addRoom = async () => {
    interruptRefresh.current = true;
    setCreatingRoom(true);
    // console.log('addRoom started');
    const newSupabaseRooms = getSupabaseVersionOfState(rooms);
    const newRooms = [...rooms];

    const firstDisabledRoomIndex = rooms.findIndex((room) => !room.enabled);
    const newRoomName = generateRoomName();
    let newRoomResponse;
    if (firstDisabledRoomIndex !== -1) {
      // console.log('regenerating room');
      const firstDisabledRoom = rooms[firstDisabledRoomIndex];
      newRoomResponse = await regenerateRoom(firstDisabledRoom.id, {
        name: newRoomName,
      });
      newRooms.splice(firstDisabledRoomIndex, 1);
      newSupabaseRooms.splice(firstDisabledRoomIndex, 1);
    } else {
      // console.log('creating room');
      newRoomResponse = await createRoom({
        name: newRoomName,
      });
    }

    const parsedName = newRoomResponse.name.replaceAll('_', ' ');
    const parsedRoomCodes = newRoomResponse.roomCodes.map(({ code, role }) => ({
      code,
      role,
    }));

    newRooms.push({
      id: newRoomResponse.id,
      name: parsedName,
      active: false,
      roomCodes: parsedRoomCodes,
      enabled: true,
    });
    newSupabaseRooms.push({
      id: newRoomResponse.id,
      enabled: true,
      name: parsedName,
      roomCodes: parsedRoomCodes,
    });

    await updateSupabaseRoomsArray(newSupabaseRooms);
    setCreatingRoom(false);
    setRooms(newRooms);
    // console.log('addRoom ended');
    interruptRefresh.current = false;
  };

  const refreshCallback = useRef();
  const refresh = () => {
    // console.log('refresh attempted');
    if (!numEditing && !creatingRoom && !deletingRoom) {
      // console.log('refresh successfully started');
      getRooms();
    }
  };

  useEffect(() => {
    refreshCallback.current = refresh;
  });

  useEffect(() => {
    if (externalRooms == null) {
      getRooms();
    } else {
      if (externalInitialLoading == null) setInitialLoading(false);
    }
    function intervalRefresh() {
      refreshCallback.current();
    }
    const refreshIntervalId = setInterval(intervalRefresh, 15000);
    return () => clearInterval(refreshIntervalId);
  }, []);

  useEffect(() => {
    // console.log('rooms =', rooms);
    // localStorage.setItem('roomsStateCacheCommunity', communityId);
    // localStorage.setItem('roomsState', JSON.stringify(rooms));
  }, [rooms]);

  return (
    <div className="w-full font-bold p-6" style={{ background: '#1E2A3C' }}>
      {/* <div className="flex gap-4">
        <h2 className="text-white text-2xl ml-3 mb-3">Open Rooms</h2>
        {loadingRooms ||
          creatingRoom ||
          (deletingRoom && <CircularProgress size="2rem" />)}
      </div> */}
      <div className="flex flex-wrap gap-3">
        {rooms?.filter((room) => room.enabled)?.length ? (
          <>
            {rooms.map(
              (room, index) =>
                room.enabled && (
                  <RoomCard
                    room={room}
                    setTitle={(newTitle) => setTitle(index, newTitle)}
                    key={room.id}
                    link={`https://meet.tour.video/meeting/${
                      room.roomCodes.find((elem) => elem.role === 'builder-101')
                        ?.code
                    }?cid=${communityId}`}
                    visitorLink={`https://meet.tour.video/meeting/${
                      room.roomCodes.find((elem) => elem.role === 'viewer')
                        ?.code
                    }?cid=${communityId}`}
                    onEdit={() => setNumEditing(numEditing + 1)}
                    onSave={() => setNumEditing(numEditing - 1)}
                    onDelete={() => deleteRoom(index)}
                    setNotification={setNotification}
                    buttonsDisabled={initialLoading}
                  />
                )
            )}
            {creatingRoom && (
              <Skeleton
                width={400}
                height={184}
                style={{
                  borderRadius: '12px',
                  background: 'rgba(255, 255, 255, 0.1)',
                }}
                variant="rect"
              />
            )}
          </>
        ) : initialLoading ? (
          Array(3)
            .fill(null)
            .map(() => (
              <Skeleton
                width={400}
                height={184}
                key={uuidv4()}
                style={{
                  borderRadius: '12px',
                  background: 'rgba(255, 255, 255, 0.1)',
                }}
                variant="rect"
              />
            ))
        ) : (
          <p className="text-white">No rooms.</p>
        )}
      </div>
      <div className="flex gap-4 mt-6 items-center">
        <button
          className={`${
            canCreateRoom() ? 'bg-blue-500' : 'bg-blue-300 disabled'
          } p-2 rounded-xl text-white font-bold`}
          onClick={() => {
            if (canCreateRoom()) {
              addRoom();
            } else {
              setNotification(
                `Maximum of ${ROOM_LIMIT} rooms allowed`,
                'error'
              );
            }
          }}
        >
          <AddIcon />
          New Room
        </button>
        {(initialLoading || creatingRoom || deletingRoom) && (
          <CircularProgress size="2rem" />
        )}
      </div>
    </div>
  );
};

export default OpenRooms;
