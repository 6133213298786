import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { mergeNestedArray } from '../../utils/eventUtils';
import { uploadFile } from '../../utils/files';

export default function UpdateUser() {
  const { community_id, userId } = useParams();
  const tagInputRef = useRef();
  const [data, setData] = useState({
    id: '',
    name: '',
    phone: '',
    email: '',
    aboutMe: '',
    rating: '',
    notes: '',
  });

  const [checkedReferral, setCheckoutReferral] = useState(false);
  const [tagValue, setTagValue] = useState('');
  const [metadata, setMetadata] = useState('');
  const [photoUrl, setPhotoUrl] = useState('');
  const [downloadUrl, setDownloadUrl] = useState('');
  const [loading, setLoading] = useState(false);
  const [loadingOnLoad, setLoadingOnLoad] = useState(false);
  const [progress, setProgress] = useState(null);
  const [tagList, setTagList] = useState([]);
  const [suggestion, setSuggestion] = useState([]);
  const [focused, setFocused] = useState(false);
  const [file, setFile] = useState(null);
  const [tags, setTags] = useState([]);
  useEffect(() => {
    setMetadata({
      ...metadata,
      photoUrl: downloadUrl,
      aboutMe: data.aboutMe,
      skills: tags,
    });
  }, [downloadUrl, data.aboutMe, tags, checkedReferral]);
  // console.log(downloadUrl);
  const handleChange = (event) => {
    setData({ ...data, [event.target.name]: event.target.value });
  };

  useEffect(() => {
    // fetchCommunity function for referral badges
    const fetchCommunity = async () => {
      const res = await axios.get(
        `https://api.directual.com/good/api/v5/data/community/getCommunityById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&communityId=${community_id}`
      );

      let badgesArray = JSON.parse(
        res.data?.payload[0]?.settings
      )?.referrals?.badges.map((item) => item.type);

      setTagList(mergeNestedArray(badgesArray));
    };

    // call the function
    fetchCommunity()
      // make sure to catch any error
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    // fetchCommunity function for user
    const fetchUser = async () => {
      const response = await axios.get(
        `https://api.directual.com/good/api/v5/data/users/getUserById?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83&userId=${userId}`
      );
      let userData = response.data.payload[0];

      setLoadingOnLoad(false);

      if (userData) {
        let horizontalJSON =
          userData?.metadata && JSON.parse(userData.metadata);
        userData?.metadata && setMetadata(JSON.parse(userData.metadata));
        setData({
          ...data,
          name: userData.name,
          phone: userData.phone,
          email: userData.email,
          aboutMe: horizontalJSON ? horizontalJSON.aboutMe : '',
        });

        setPhotoUrl(horizontalJSON?.photoUrl);
        setDownloadUrl(horizontalJSON?.photoUrl);
        setTags(horizontalJSON?.skills);
        setCheckoutReferral(horizontalJSON?.referral ? true : false);
      }
    };

    // call the function
    fetchUser()
      // make sure to catch any error
      .catch((err) => console.log(err));
  }, []);

  async function onSelectImage(e) {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setPhotoUrl(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);

      setFile(e.target.files[0]);
    }
  }

  const handleUpload = async () => {
    try {
      const url = await uploadFile(
        file,
        setProgress,
        'users',
        'profile/' + data.id
      );

      console.log('urlUpload', url);

      setPhotoUrl(url);
      setDownloadUrl(url);

      setLoading(false);
      return url;
    } catch (err) {
      console.log(err);
    }
  };

  const addTags = (value) => {
    if (tags?.some((tag) => tag === value)) {
      alert('Skill is already added');
      return;
    }
    setTags([...tags, value.replace(/\s+/g, '')]);
    setTagValue('');
  };

  const removeTags = (value) => {
    const temp = tags.filter((tag) => tag !== value);
    if (temp.length === 0) {
      setTags([]);
    }
    setTags(temp);
  };

  // console.log('suggestion', suggestion);

  function handleSuggestion(value) {
    const filter = tagList.filter((option) =>
      option.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestion(filter);
  }

  const handleChangeTagValue = (value) => {
    setTagValue(value);
    handleSuggestion(value);
  };

  useEffect(() => {
    if (file === null) {
      setFile(null);
    }
  }, [file]);

  const submit = async () => {
    if (!data.email || !data.phone || !data.name) {
      alert('Please fill all the fields');
      return;
    }
    setLoading(true);
    let url = '';
    if (file) {
      url = await handleUpload();
    }
    try {
      // to avoiding to upload same file again
      let postData = {
        email: data.email,
        name: data.name,
        phone: data.phone,
        metadata: {
          ...metadata,
          photoUrl: url,
        },
        community: community_id,
        id: userId,
      };
      const user = await axios.post(
        `https://api.directual.com/good/api/v5/data/users/newUser?appID=f1a3bb81-3aba-43fb-8369-be1c35b3aa83`,
        postData
      );
      console.log('userUpdated after review TYG: ', user);
      toast(
        '🥳congratulations on joining the review program. A staff member will be reviewing your submission to award you those rewards points :)',
        {
          position: 'top-right',
          type: 'success',
          autoClose: 5000,
        }
      );
      setLoading(false);
    } catch (err) {
      console.log('err', err);
      setLoading(false);
    }
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (tagInputRef.current && !tagInputRef.current.contains(event.target)) {
        setFocused(false);
      }
    }
    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tagInputRef]);

  return (
    <div className="survey-thanks-bux-dialog referral-blk flex">
      <div className="right flex flex-col">
        <div className="form">
          <div className="user-blk flex">
            <div className="image-blk">
              <div
                className="dp"
                style={{
                  backgroundImage: `url(${
                    photoUrl || 'https://placeimg.com/640/480/people?1'
                  } )`,
                }}
              />
              <button
                className="font change-img s12 b5 color"
                onClick={() => document.getElementById('_upload-file_').click()}
              >
                Upload Photo
              </button>
              <input
                onClick={(e) => (e.target.value = '')}
                onChange={onSelectImage}
                type="file"
                accept="image/*"
                className="hide-element "
                id="_upload-file_"
              />
            </div>
            <div className="meta flex flex-col">
              <input
                type="text"
                className="iput font"
                placeholder="Name"
                value={data.name}
                name="name"
                onChange={(e) => handleChange(e)}
              />
              <textarea
                type="text"
                className="iput area font"
                placeholder="About me"
                value={data.aboutMe}
                name="aboutMe"
                onChange={(e) => handleChange(e)}
              />
            </div>
          </div>
          <div
            className="dp"
            style={{
              backgroundImage: `url(https://placeimg.com/640/480/people?1)`,
            }}
          />
          <div className="tag-blk">
            <div className="selected-tags">
              {tags &&
                tags?.map((tag, index) => (
                  <div key={index} className="tag">
                    {tag}
                    <span
                      onClick={() => removeTags(tag)}
                      style={{ cursor: 'pointer', fontWeight: 'bold' }}
                    >
                      {' '}
                      -
                    </span>
                  </div>
                ))}
            </div>
            <div className="tag-selector" ref={tagInputRef}>
              <div className="tag-blk-title font">About me</div>
              <div className="iput flex aic">
                <input
                  type="text"
                  onFocus={(e) => {
                    setFocused(true);
                    e.target.value.length === 0 && setSuggestion(tagList);
                  }}
                  placeholder="Add a Tag"
                  className="input-text font"
                  value={tagValue}
                  onChange={(e) => handleChangeTagValue(e.target.value)}
                  onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      addTags(e.target.value);
                    }
                  }}
                />
                <div
                  className="add-btn icon-plus"
                  onClick={() => addTags(tagValue)}
                />
              </div>
              <div className={`tag-list ${focused ? 'active' : ''}`}>
                {suggestion &&
                  suggestion?.map((item, index) => (
                    <div
                      key={index}
                      onClick={() => addTags(item)}
                      className="item"
                    >
                      {item} +
                    </div>
                  ))}
              </div>
            </div>
          </div>
          <input
            type="text"
            className="iput font"
            placeholder="Name"
            name="name"
            value={data.name}
            onChange={(e) => handleChange(e)}
          />
          <input
            type="text"
            className="iput font"
            placeholder="Phone"
            name="phone"
            value={data.phone}
            onChange={(e) => handleChange(e)}
          />
          <input
            type="text"
            className="iput font"
            placeholder="Email"
            name="email"
            value={data.email}
            onChange={(e) => handleChange(e)}
          />
          <button className="cleanbtn submit-btn font" onClick={submit}>
            {loading ? 'Loading...' : 'Submit'} {progress ? progress + '%' : ''}
          </button>
        </div>
        <div className="notic font s14 black flex aic">
          *will be referral by a human
        </div>
      </div>
    </div>
  );
}
